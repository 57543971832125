import { Button } from '@mantine/core'

import { SaveIcon } from 'assets/svg'

const SaveDraftButton = ({ enableDraft, draftOnClick, isSavingDraft = true }) => {
  const buttonText = isSavingDraft ? 'Saving' : 'Save as draft'
  return (
    <Button
      leftIcon={<SaveIcon />}
      variant="default"
      disabled={!enableDraft}
      onClick={draftOnClick}
      loading={isSavingDraft}
      aria-label="saveButton"
    >
      {buttonText}
    </Button>
  )
}

export default SaveDraftButton
