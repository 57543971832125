// MSAL imports
import { InteractionType } from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react'
import AppRoutes from 'config/routes'
import { ConnectedRouter } from 'connected-react-router'

import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, useHistory } from 'react-router-dom'

import { ScrollToTop } from 'view/components'
import { ReloginModal } from 'view/components/Modal'

import browserHistory from './config/history'
import { CustomNavigationClient } from './util/NavigationClient'

function App({ pca }) {
  const [showRelogin, setShowRelogin] = useState(false)

  const request = {
    scopes: ['User.Read', 'User.Read.All', 'Directory.Read.All'],
    forceRefresh: true,
  }

  // useMsalAuthentication is a custom hook that returns an array of [login, result, error]
  const { login, error, acquireToken } = useMsalAuthentication(InteractionType.Redirect, request)

  // if there is an error, show relogin modal
  useEffect(() => {
    const triggeredLoginRecently = sessionStorage.getItem('triggeredLoginRecently')
    if (error) {
      if (['no', null].includes(triggeredLoginRecently)) {
        setShowRelogin(true)
      } else if (triggeredLoginRecently === 'yes') {
        sessionStorage.setItem('triggeredLoginRecently', 'no')
        window.location.reload(false)
      }
    }
  }, [error])

  // refresh token every 5 minutes
  useEffect(() => {
    const intervalRefreshToken = setInterval(async () => {
      await acquireToken({
        ...request,
        account: pca.getActiveAccount() || pca.getAllAccounts()[0],
      })
    }, 300000)

    return () => clearInterval(intervalRefreshToken)
  }, [])

  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory()
  const navigationClient = new CustomNavigationClient(history)
  pca.setNavigationClient(navigationClient)

  // login function to be passed to the relogin button for session timeout
  const signIn = async () => {
    localStorage.clear()
    sessionStorage.clear()
    sessionStorage.setItem('triggeredLoginRecently', 'yes')
    await login(InteractionType.Redirect, request)
  }

  return (
    <>
      <Router>
        <ReloginModal
          isOpen={showRelogin}
          handleClose={signIn}
        />
        <AuthenticatedTemplate>
          <ConnectedRouter history={browserHistory}>
            <ScrollToTop />
            <AppRoutes />
          </ConnectedRouter>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Redirect to={'/'} />
        </UnauthenticatedTemplate>
      </Router>
    </>
  )
}

export default App
