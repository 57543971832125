import { useEffect, useState } from 'react'

import {
  MANDATORY_INPUT_RULES,
  findOthersInDropdown,
  getDropdownDataHandler,
  getPlaceholderLabel,
} from 'util/helper'

import ADInputText from './ADInputText'

const ADVehicleRows = ({
  rowValues,
  isNewRow,
  editable,
  tableConfig,
  setRowValues,
  apiDropdownData,
  form,
}) => {
  const [placeholder, setPlaceholder] = useState(null)
  const [disableBrandField, setDisableBrandField] = useState(false)
  const [minAcquisitionYear, setMinAcquisitionYear] = useState(null)
  const createableId = 0

  useEffect(() => {
    const disableBrandField = !MANDATORY_INPUT_RULES.includes(rowValues['vehicleTypeName'])
      ? MANDATORY_INPUT_RULES.includes(rowValues['vehicleTypeName'])
      : MANDATORY_INPUT_RULES.includes(rowValues['vehicleTypeId'])

    setDisableBrandField(disableBrandField)
    setMinAcquisitionYear(rowValues['year'])
  }, [rowValues])

  const onChangeHandler = (key, value, canCreate, dropdownLabel = null) => {
    // creatable add name key
    if (canCreate) {
      setRowValues((prevState) => {
        if (typeof value === 'string')
          return {
            ...prevState,
            [key]: createableId,
            [key.replace('Id', 'Name')]: value,
          }
        else
          return {
            ...prevState,
            [key]: value,
            [key.replace('Id', 'Name')]: dropdownLabel,
          }
      })
    } else
      setRowValues((prevState) => {
        return { ...prevState, [key]: value }
      })

    if (key === 'year') {
      setMinAcquisitionYear(value)
      setRowValues((prevState) => {
        return { ...prevState, ['yearOfAcquisition']: value }
      })
    }

    setPlaceholder(getPlaceholderLabel(apiDropdownData, tableConfig.tableName, value, key))

    if (key === 'vehicleTypeId') {
      setRowValues((prevState) => {
        return { ...prevState, ['vehicleBrandId']: '' }
      })
    }
  }

  const renderPlaceholder = ['purchasedValue', 'value', 'totalValue']

  return (
    <>
      {tableConfig['headerKeys'].map((item) => {
        let dropdownData

        const dependantKeyValue = tableConfig['fields'][item]['dependantKey']
          ? rowValues[tableConfig['fields'][item]['dependantKey']]
          : null

        dropdownData = getDropdownDataHandler({
          source: tableConfig['fields'][item]['dropdownSource'],
          item: item,
          formValue: form.values[tableConfig['tableName']],
          apiDropdownData,
          tableConfig,
          dependantKeyValue,
        })

        const getCreatableDropdownValue =
          findOthersInDropdown(dropdownData.initial) && rowValues[item] === createableId
            ? rowValues[item.replace('Id', 'Name')]
            : rowValues[item]

        return (
          <td
            key={item}
            style={{ maxWidth: tableConfig['fields'][item].maxWidth, wordWrap: 'break-word' }}
          >
            <ADInputText
              placeholder={renderPlaceholder.includes(item) && placeholder}
              enableEdit={isNewRow || editable}
              value={getCreatableDropdownValue}
              inputKey={item}
              onChange={onChangeHandler}
              inputConfig={tableConfig['fields'][item]}
              dropdownData={dropdownData}
              disabled={item === 'vehicleBrandId' && disableBrandField}
              minAcquisitionYear={minAcquisitionYear}
            />
          </td>
        )
      })}
    </>
  )
}

export default ADVehicleRows
