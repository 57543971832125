import {
  useGetMDInvestmentQuery,
  useCreateMDInvestmentMutation,
  useUpdateMDInvestmentMutation,
} from 'store/api'

import { FMINVESTMENTTYPE_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMInvestmentTypePanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDInvestmentQuery({
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  })

  const [addInvestmentName] = useCreateMDInvestmentMutation()
  const [updateInvestmentName] = useUpdateMDInvestmentMutation()
  return (
    <FMPanelTemplate
      addButtonLabel={`Add Investment Type`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMINVESTMENTTYPE_MAP}
      apiAddHandler={addInvestmentName}
      apiUpdateHandler={updateInvestmentName}
    />
  )
}

export default FMInvestmentTypePanel
