import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { Group, Text, Anchor, Container } from '@mantine/core'

import { useStyle } from 'view/style'

const Footer = () => {
  const { classes } = useStyle()
  const { pathname } = useLocation()

  const footerItems = useMemo(() => {
    if (!pathname?.includes('investigate'))
      return [
        { name: 'Privacy Statement', link: '/privacy-statement/' },
        { name: 'Contact Us', link: 'mailto:assetdeclaration@petronas.com' },
        { name: 'FAQ', link: '/faq/' },
      ]
    else {
      return []
    }
  }, [pathname])

  return (
    <Container
      size="xl"
      px="xs"
      className={classes.footer}
    >
      <Group position="apart">
        <Text
          size="sm"
          color="textGray"
        >
          {`Copyright ©${new Date().getFullYear()} PETRONAS. All rights reserved.`}
        </Text>
        <Group>
          {footerItems.map((index) => {
            return (
              <Anchor
                key={index.name}
                size="sm"
                color="textGray"
                component={Link}
                to={{ pathname: index.link }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {index.name}
              </Anchor>
            )
          })}
        </Group>
      </Group>
    </Container>
  )
}

export default Footer
