import { useMsal } from '@azure/msal-react'
import { getMsalAccountRole } from 'config/authConfig'

import { useMemo } from 'react'

import { Paper, Stack, Tabs, Text } from '@mantine/core'

import ESAdminTab from './ESAdminTab'
import ESDelegationManagement from './ESDelegationManagement'
import ESEmployeeTab from './ESEmployeeTab'

const EmployeeSearch = () => {
  const { instance } = useMsal()
  const hasSuperAdminRole = useMemo(
    () => getMsalAccountRole(instance).includes('SUPERADMIN'),
    [instance]
  )

  return (
    <Paper mb={90}>
      <Stack>
        <Text
          size={24}
          fw={600}
        >
          Employee Search
        </Text>
        <Tabs defaultValue="employee">
          <Tabs.List>
            <Tabs.Tab
              value="employee"
              px={20}
              py={16}
            >
              <Text fw={500}>Employee</Text>
            </Tabs.Tab>
            <Tabs.Tab
              value="admin"
              px={20}
              py={16}
            >
              <Text fw={500}>Admin</Text>
            </Tabs.Tab>
            {hasSuperAdminRole ? (
              <Tabs.Tab
                value="delegation"
                px={20}
                py={16}
              >
                <Text fw={500}>Delegation</Text>
              </Tabs.Tab>
            ) : null}
          </Tabs.List>
          <Tabs.Panel
            pt={24}
            value="employee"
          >
            <ESEmployeeTab />
          </Tabs.Panel>
          <Tabs.Panel
            pt={24}
            value="admin"
          >
            <ESAdminTab />
          </Tabs.Panel>
          <Tabs.Panel
            pt={24}
            value="delegation"
          >
            <ESDelegationManagement />
          </Tabs.Panel>
        </Tabs>
      </Stack>
    </Paper>
  )
}

export default EmployeeSearch
