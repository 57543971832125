import { useMemo, useState } from 'react'

import { Select } from '@mantine/core'
import { getYearsRange } from '@mantine/dates'

import { useStyle } from 'view/style'

import { ChevronDown } from 'assets/svg'

import dayjs from 'dayjs'

const currentYear = dayjs().get('year')
const dateRange = getYearsRange({ from: 2023, to: currentYear })
  .map((item) => {
    return { value: item, label: item }
  })
  .reverse()

const CustomYearDropdown = ({ onChange }) => {
  const currentYear = dayjs().get('year')

  const [dropdownYear, setDropdownYear] = useState(currentYear)

  const { classes } = useStyle()

  useMemo(() => {
    onChange(dropdownYear)
  }, [dropdownYear])

  return (
    <Select
      sx={{ maxWidth: 100 }}
      classNames={{
        itemsWrapper: classes.dropdownWrapper,
        rightSection: classes.dropdownRightSection,
        item: classes.dropdownitem,
      }}
      aria-label="year-dropdown"
      onChange={setDropdownYear}
      rightSection={<ChevronDown />}
      value={dropdownYear}
      data={dateRange}
    />
  )
}

export default CustomYearDropdown
