import { successNotification } from 'config/notificationConfig'
import { isEmpty } from 'lodash'
import {
  useDeclareNewAssetsMutation,
  useCreateAssetDraftMutation,
  useLazyGetAssetDraftQuery,
  useLazyGetCheckSubmissionTicketQuery,
  useCreateAutoSaveFormMutation,
  useGetIdentityQuery,
  useGetDeclarationReminderQuery,
} from 'store/api'
import {
  calculateTotalAmount,
  calculateTotalRows,
  setRenderHeader,
  calcBCashAmountRows,
  calcOtherMovableRows,
  calcInvestmentAmountRows,
  calcLoanAmountRows,
  calcPropertyAmountRows,
  calcRetirementAmountRows,
  calcVehicleAmountRows,
  calcDirectorRows,
  calcOwnerRows,
  setResetDisableStatus,
  setDisableStatus,
  setStepperStep,
  setDOPreview,
} from 'store/assetDeclareSlice'

import { useEffect, useMemo, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Stack } from '@mantine/core'
import { getYearsRange } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { useCounter, useDisclosure } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'

import { CenterLoader, Review } from 'view/components'
import { LegalModal, ReviewDeclarationModal, FYSelectModal } from 'view/components/Modal'

import { formatResponse } from 'util/helper'
import { useGetDropdownData } from 'util/hooks'
import {
  BCASH_MAP,
  INVEST_MAP,
  LOAN_MAP,
  PROPERTY_MAP,
  RETIRE_MAP,
  VEHICLE_MAP,
  initialStateAssetDeclare,
  OWNER_MAP,
  DIRECTOR_MAP,
  OTHER_MOVABLE_MAP,
} from 'util/tableMapping'

import dayjs from 'dayjs'

import ADAccordian from './ADAccordian'
import ADFooter from './ADFooter'
import ADMain from './ADMain'
import ADStepper from './ADStepper'

const AssetDeclaration = ({ isDORevision = false }) => {
  const history = useHistory()
  const [financialYear, setFinancialYear] = useState(dayjs().get('year'))
  const dateRange = getYearsRange({ from: 2023, to: dayjs().get('year') }).reverse()

  const { data: identityData } = useGetIdentityQuery()
  const [fetchDraft, result] = useLazyGetAssetDraftQuery()
  const { data: assetData, isLoading } = result

  const [fetchCheckSubmission, checkSubmissionResult] = useLazyGetCheckSubmissionTicketQuery()
  const {
    data: checkSubmissionData,
    isError: checkSubmissionError,
    isSuccess: checkSubmissionSuccess,
  } = checkSubmissionResult
  const [declareAsset, { isSuccess: submitSuccess }] = useDeclareNewAssetsMutation()
  const [
    saveAssetDraft,
    { isLoading: draftIsSaving, isSuccess: saveIsSuccess, isError: saveIsError },
  ] = useCreateAssetDraftMutation()

  const [autoSave] = useCreateAutoSaveFormMutation()
  const [runAutoSave, setRunAutoSave] = useState({ trigger: false, running: false })
  const categoryDetails = useSelector((state) => state.assetDeclare)
  const [legalModalIsOpen, { close: closeLegalHandler, open: openLegalHandler }] =
    useDisclosure(false)
  const [reviewModalIsOpen, { close: closeReviewHandler, open: openReviewHandler }] =
    useDisclosure(false)
  const [FYSelectModalIsOpen, { close: closeFYSelecthandler, open: openFYSelectHandler }] =
    useDisclosure(false)
  const dispatch = useDispatch()
  const [draftClickable, setDraftClickable] = useState(isDORevision)
  const [step, { increment, decrement, set: setPage }] = useCounter(0, { min: 0, max: 2 })
  const { apiDropdownData, dropdownIsSuccess } = useGetDropdownData()
  const form = useForm({
    initialValues: { ...initialStateAssetDeclare },
  })

  const { data: bannerData } = useGetDeclarationReminderQuery()

  useBeforeunload((event) => {
    if (step > 0 && draftClickable) {
      event.preventDefault()
    }
  })

  //  if user refresh while during DO revision (old data), redirect from form page to landing page
  useMemo(() => {
    if (isDORevision && categoryDetails.stepperStep === 0) history.push('/assets')
  }, [isDORevision, categoryDetails.stepperStep])

  useEffect(() => {
    if (dropdownIsSuccess) {
      setPage(categoryDetails.stepperStep)
      if (categoryDetails.stepperStep === 1 && !isDORevision) {
        fetchDraft({ financialYear: financialYear })
      }
    }
  }, [categoryDetails.stepperStep, isDORevision, dropdownIsSuccess])

  useEffect(() => {
    //This will check if the form is empty or not
    const isFormEmpty = Object.values(form.values).every((val) => val)
    //If it revision - render form with revised data otherwise fetch data from draft api based on latest declared date
    if (step === 1) {
      fetchCheckSubmission({ year: financialYear })

      //Conditionally decide which version of data to use
      if (isDORevision && isFormEmpty) {
        const revisionData = categoryDetails?.tempPreview.formData
        form.setValues(formatResponse(revisionData))
      } else {
        //If form data exist means its already load the draft data - We skip override
        const draftData =
          isFormEmpty && result.isSuccess && !result.isLoading && !isEmpty(assetData.formData)
            ? formatResponse(assetData.formData)
            : form.values

        form.setValues(draftData)
        dispatch(calculateTotalAmount(draftData))
        dispatch(calculateTotalRows(JSON.stringify(draftData)))
      }
    }
    if (step === 0) form.reset()
  }, [assetData, isDORevision, result, step])

  useEffect(() => {
    if (categoryDetails['common']['reRender']) {
      setRunAutoSave((state) => ({ ...state, trigger: true }))
      setDraftClickable(true)
      dispatch(setRenderHeader(false))
      dispatchAmount()
    }

    //If runautosave is still running. ignore calling api. To prevent from spamming
    if (runAutoSave.trigger && !runAutoSave.running) {
      setRunAutoSave((state) => ({ ...state, running: true }))
      //If detect changes call autosave with timeout of 5sec
      setTimeout(async () => {
        await autoSave({ ...form.values, financialYear: financialYear })
        setRunAutoSave({ running: false, trigger: false })
        // setDraftClickable(false)
      }, 5000)
    }
    return async () => {
      if (runAutoSave.trigger && !runAutoSave.running) {
        setRunAutoSave((state) => ({ ...state, running: true }))
        //If detect changes call autosave with timeout of 5sec
        await autoSave({ ...form.values, financialYear: financialYear })
        setRunAutoSave({ running: false, trigger: false })
        // setDraftClickable(false)
      }
    }
  }, [categoryDetails['common']['reRender'], runAutoSave])

  useEffect(() => {
    if (saveIsError) {
      setDraftClickable(true)
    }
    if (submitSuccess) {
      history.push('/declaration-overview/')
    }
  }, [saveIsError, submitSuccess])

  const tableToFunctionMap = [
    { tableName: BCASH_MAP['tableName'], reducerFn: calcBCashAmountRows },
    { tableName: OTHER_MOVABLE_MAP['tableName'], reducerFn: calcOtherMovableRows },
    { tableName: LOAN_MAP['tableName'], reducerFn: calcLoanAmountRows },
    { tableName: PROPERTY_MAP['tableName'], reducerFn: calcPropertyAmountRows },
    { tableName: VEHICLE_MAP['tableName'], reducerFn: calcVehicleAmountRows },
    { tableName: RETIRE_MAP['tableName'], reducerFn: calcRetirementAmountRows },
    { tableName: INVEST_MAP['tableName'], reducerFn: calcInvestmentAmountRows },
    { tableName: OWNER_MAP['tableName'], reducerFn: calcOwnerRows },
    { tableName: DIRECTOR_MAP['tableName'], reducerFn: calcDirectorRows },
  ]

  const dispatchAmount = () => {
    tableToFunctionMap.forEach(({ tableName, reducerFn }) => {
      if (form.isDirty(tableName) || form.isTouched(tableName)) {
        dispatch(reducerFn(JSON.stringify(form.values[tableName])))
      }
    })
    form.resetDirty()
    form.resetTouched()
  }

  const draftHandler = async () => {
    setDraftClickable(false)
    await saveAssetDraft({ ...form.values, financialYear: financialYear })

    //If from revision - clear state and fetch latest
    if (isDORevision) {
      dispatch(setDOPreview(null))
    }

    await fetchDraft({ financialYear: financialYear })
  }

  //Handle submit declaration
  const handleSubmitDeclaration = async () => {
    await declareAsset({ ...form.values, financialYear: financialYear })
    closeLegalHandler()
    showNotification({
      ...successNotification,
      title: 'Asset Declared',
      message: `Your asset declaration for the year ${financialYear} has been submitted`,
    })
  }

  const handleReviewModalSubmit = () => {
    closeReviewHandler()
    increment()
  }
  const getStepContent = (step) => {
    let revisionValue
    //Below condition checking what version of revision number to be use
    //If its DORevision we get the revision number from store
    if (isDORevision && categoryDetails?.tempPreview.revision) {
      const { latestRevisionNum } = categoryDetails.tempPreview

      revisionValue = latestRevisionNum + 1

      // If latest is  submitted submitted - we need to add the revision number by 1
      // Cache is an autosave data
    } else if (
      assetData?.submitted &&
      ['/declaration-overview/revision', '/assets/', '/assets'].includes(window.location.pathname)
    ) {
      revisionValue = assetData?.revision + 1
      //Else just get the defualt value from API
    } else {
      revisionValue = assetData?.revision
    }

    const renderer = {
      0: <ADMain />,
      1: (
        <ADAccordian
          form={form}
          apiDropdownData={apiDropdownData}
          categoryDetails={categoryDetails}
        />
      ),
      2: (
        <Review
          assetRevisionData={revisionValue}
          isSubmitted={categoryDetails?.tempPreview.submitted}
          form={form}
          categoryDetails={categoryDetails}
          financialYear={assetData?.financialYear || financialYear}
          identityData={identityData}
          date={categoryDetails?.tempPreview.updatedAt}
        />
      ),
    }
    return renderer[step]
  }

  const checkEmptyCategory = () => {
    dispatch(setDisableStatus(JSON.stringify(form.values)))

    const fields = {
      bankCash: !!categoryDetails.bankCash.rows,
      vehicle: !!categoryDetails.vehicle.rows,
      investment: !!categoryDetails.investment.rows,
      retirement: !!categoryDetails.retirement.rows,
      otherMovable: !!categoryDetails.otherMovable.rows,
      loan: !!categoryDetails.loan.rows,
      property: !!categoryDetails.property.rows,
      owner: !!categoryDetails.owner.rows,
      director: !!categoryDetails.director.rows,
    }
    return Object.values(fields).includes(false)
  }

  const incrementHandler = async () => {
    if (step === 2) {
      openLegalHandler()
    } else if (step === 0) {
      if (dateRange.length > 1) {
        openFYSelectHandler()
      } else {
        await handleProceedYearSelect()
      }
      dispatch(setResetDisableStatus())
    } else if (step === 1 && checkEmptyCategory()) {
      setResetDisableStatus()
      openReviewHandler()
    } else {
      increment()
    }
    window.scrollTo(0, 0)
  }

  const decrementHandler = () => {
    window.scrollTo(0, 0)
    decrement()
    if (step === 1) {
      dispatch(setStepperStep(0))
      history.push('/assets')
    }
  }

  const handleProceedYearSelect = async () => {
    setPage(1)
    dispatch(setStepperStep(1))
  }

  return (
    <>
      <Stack pb={100}>
        <ADStepper
          checkSubmissionSuccess={checkSubmissionSuccess}
          submissionConfig={checkSubmissionData}
          financialYear={financialYear}
          activeStep={step}
          submittedStatus={assetData?.submitted}
          bannerData={bannerData}
        />
        {isLoading ? <CenterLoader height="50vh" /> : getStepContent(step)}
      </Stack>
      <ADFooter
        enableDraft={draftClickable}
        activeStep={step}
        isAllow={!checkSubmissionError ? checkSubmissionData?.allow : false}
        draftOnClick={draftHandler}
        draftSaving={draftIsSaving}
        draftSaveError={saveIsError}
        draftSaveSuccess={saveIsSuccess}
        onNext={incrementHandler}
        disableOnNext={categoryDetails.isScrolledBottom && dropdownIsSuccess}
        onBack={decrementHandler}
      />
      {legalModalIsOpen ? (
        <LegalModal
          isOpen={legalModalIsOpen}
          onClose={closeLegalHandler}
          onSubmit={handleSubmitDeclaration}
        />
      ) : null}
      {reviewModalIsOpen ? (
        <ReviewDeclarationModal
          isOpen={reviewModalIsOpen}
          onClose={closeReviewHandler}
          onSubmit={handleReviewModalSubmit}
          categoryDetails={categoryDetails}
        />
      ) : null}
      <FYSelectModal
        isOpen={FYSelectModalIsOpen}
        defaultValue={financialYear}
        onClose={closeFYSelecthandler}
        onChange={setFinancialYear}
        onProceed={handleProceedYearSelect}
        dateRange={dateRange}
      />
    </>
  )
}

export default AssetDeclaration
