const theme = {
  colors: {
    brand: [
      '#32b6ae',
      '#28aca4',
      '#1ea29a',
      '#149890',
      '#0a8e86',
      '#00847c',
      '#007a72',
      '#007068',
      '#00665e',
      '#005c54',
    ],
    textGray: [
      '#99aec3',
      '#8fa4b9',
      '#859aaf',
      '#7b90a5',
      '#71869b',
      '#677c91',
      '#5d7287',
      '#53687d',
      '#495e73',
      '#3f5469',
    ],
    destructive: [
      '#FBF9F9',
      '#EBDBDB',
      '#DFBEBE',
      '#D89F9F',
      '#D87E7E',
      '#DE5959',
      '#EC2E2E',
      '#D42A2A',
      '#AF3535',
      '#933B3B',
      '#7C3D3D',
      '#6A3D3D',
      '#5B3B3B',
    ],
  },
  primaryColor: 'brand',
  fontFamily: 'Inter, sans-serif',
  fontFamilyMonospace: 'Inter, sans-serif',
  headings: {
    fontFamily: 'Inter, sans-serif',
    sizes: {
      // See heading options below
      h1: { fontSize: 42 },
      h2: { fontSize: 32 },
      h3: { fontSize: 22 },
      h4: { fontSize: 18 },
    },
  },
  //Adding default props to component
  components: {
    Paper: {
      defaultProps: {
        p: 20,
      },
    },
    // Button: {
    //   styles: {
    //     root: {
    //       '&[data-disabled]': {
    //         backgroundColor: '#AFDDDC',
    //         color: '#FFFFFF',
    //       },
    //     },
    //   },
    // },
    // Text: {
    //   defaultProps: {
    //     fw: 500,
    //     size: 14,
    //   },
    // },
  },
}

export { theme }
