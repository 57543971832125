import { useEffect, useState } from 'react'

import { Popover, Input, Stack, Divider, Group, Button } from '@mantine/core'
import { Calendar } from '@mantine/dates'
import { useDisclosure } from '@mantine/hooks'

import dayjs from 'dayjs'

const CustomDatePicker = ({
  handleSubmit,
  initialValue = null,
  disabled = false,
  minDate = new Date(dayjs().add(1, 'day').format('YYYY-MM-DD')),
  maxDate = null,
  width = 352,
}) => {
  const [opened, handlers] = useDisclosure(false)
  const [inputValue, setInputValue] = useState(initialValue)

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  const disableSubmit = inputValue === null

  const formatDate = (date) => dayjs(date).format('DD MMM YYYY')

  const cancelHandler = () => {
    setInputValue(initialValue)
    handlers.close()
  }

  const submitHandler = () => {
    handleSubmit(inputValue)
    handlers.close()
  }
  return (
    <Popover
      opened={opened}
      position="bottom-start"
      withinPortal
      shadow="md"
      disabled={disabled}
    >
      <Popover.Target>
        <Input
          placeholder="Select date"
          value={`${initialValue ? formatDate(initialValue) : ''}`}
          aria-label="datePickerInput"
          readOnly
          disabled={disabled}
          onClickCapture={() => handlers.open()}
          w={width}
        />
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Calendar
            value={inputValue}
            onChange={setInputValue}
            allowLevelChange={false}
            initialMonth={inputValue || new Date()}
            minDate={minDate}
            maxDate={maxDate}
            size="xs"
            styles={() => ({
              day: {
                '&[data-in-range]': { backgroundColor: '#DFF1F1' },
                '&[data-selected]': { backgroundColor: '#007a72' },
              },
            })}
          />
          <Divider />
          <Group position="right">
            <Button
              variant="default"
              onClick={cancelHandler}
              aria-label="datePickerCancel"
            >
              Cancel
            </Button>
            <Button
              onClick={submitHandler}
              disabled={disableSubmit}
              styles={{
                root: {
                  '&[data-disabled]': {
                    backgroundColor: '#AFDDDC',
                    color: '#FFFFFF',
                  },
                },
              }}
              aria-label="datePickerSubmit"
            >
              Select Date
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default CustomDatePicker
