import { Fragment } from 'react'

import { Stack, Divider, Anchor, Text } from '@mantine/core'

import { TextWithInfo, ADTable, ADFinanceRows } from 'view/components'

import { LOAN_MAP } from 'util/tableMapping'
import { ASSET_DECLARATION_CONTACT_NOTE } from 'util/textMapping'

export const financialCommitmentForms = [
  {
    text: 'Loans & Financings (Personal, Hire Purchase & Property)',
    tooltip:
      'Existing loans & financings employee has with financial institutions or non-financial institutions as of date of declaration.',
    type: 'loan',
    tableMapping: LOAN_MAP,
  },
]

const FinancialCommitmentPanel = ({ form, apiDropdownData }) => {
  const link = 'assetdeclaration@petronas.com'
  const index = ASSET_DECLARATION_CONTACT_NOTE.indexOf(link)

  const updatedLoansAndFinancingNote = (
    <Stack>
      <Text
        size={14}
        italic
      >
        {ASSET_DECLARATION_CONTACT_NOTE.substring(0, index)}
        <Anchor
          weight={500}
          href={'mailto:assetdeclaration@petronas.com'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link}
        </Anchor>
        {ASSET_DECLARATION_CONTACT_NOTE.substring(index + link.length)}
      </Text>
      <Text
        size={14}
        italic
      >
        {`Loans and financings held in foreign countries are to be declared under "Bank/non-bank outside of Malaysia" in the Name of Institution dropdown list.`}
      </Text>
    </Stack>
  )

  return (
    <Stack>
      <Divider />
      {financialCommitmentForms.map(({ text, tooltip, type, tableMapping }, index) => {
        return (
          <Fragment key={type}>
            <TextWithInfo
              text={`${index + 1}. ${text}`}
              tooltip={tooltip}
            />
            {updatedLoansAndFinancingNote}
            <ADTable
              form={form}
              tableConfig={tableMapping}
              tableData={form.values[tableMapping['tableName']]}
              apiDropdownData={apiDropdownData}
              addLabel={`Add ${type}`}
            >
              <ADFinanceRows />
            </ADTable>
          </Fragment>
        )
      })}
    </Stack>
  )
}

export default FinancialCommitmentPanel
