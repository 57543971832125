import { findOthersInDropdown, getDropdownDataHandler } from 'util/helper'

import ADInputText from './ADInputText'

const ADFinancingRow = ({
  rowValues,
  isNewRow,
  editable,
  tableConfig,
  setRowValues,
  apiDropdownData,
}) => {
  const createableId = 0
  const onChangeHandler = (key, value) => {
    if (key === 'financialInstitutionId') {
      setRowValues((prevState) => {
        const secondValue = apiDropdownData[tableConfig['tableName']][key].find(
          (dropdown) => dropdown.value === value
        )?.type

        if (secondValue)
          return {
            ...prevState,
            [key.replace('Id', 'Type')]: secondValue,
            [key]: value,
          }
        else
          return {
            ...prevState,
            [key.replace('Id', 'Type')]: '',
            [key]: '',
          }
      })
    }
    // if (canCreate) {
    //   setRowValues((prevState) => {
    //     if (typeof value === 'string')
    //       return {
    //         ...prevState,
    //         [key]: createableId,
    //         [key.replace('Id', 'Name')]: value,
    //       }
    //     else if (key === 'financialInstitutionType') {
    //       const secondValue = apiDropdownData[tableConfig['tableName']][key].find(
    //         (dropdown) => dropdown.value === value
    //       ).type

    //       return {
    //         ...prevState,
    //         [key.replace('Id', 'Type')]: secondValue,
    //         [key]: value,
    //         // [key.replace('Id', 'Name')]: dropdownLabel,
    //       }
    //     } else
    //       return {
    //         ...prevState,
    //         [key]: value,
    //         [key.replace('Id', 'Name')]: dropdownLabel,
    //       }
    //   })
    // } else if (key === 'financialInstitutionId') {
    //   setRowValues((prevState) => {
    //     const secondValue = apiDropdownData[tableConfig['tableName']][key].find(
    //       (dropdown) => dropdown.value === value
    //     )?.type

    //     if (secondValue)
    //       return {
    //         ...prevState,
    //         [key.replace('Id', 'Type')]: secondValue,
    //         [key]: value,
    //       }
    //     else
    //       return {
    //         ...prevState,
    //         [key.replace('Id', 'Type')]: '',
    //         [key]: '',
    //       }
    //   })
    // }
    // //  if financialInstitutionType is changed, set financialInstitutionId to null
    // else if (key === 'financialInstitutionType')
    //   setRowValues((prevState) => {
    //     if (prevState[key.replace('Type', 'Id')] === createableId)
    //       return {
    //         ...prevState,
    //         [key]: value,
    //       }
    //     else
    //       return {
    //         ...prevState,
    //         [key]: value,
    //         [key.replace('Type', 'Id')]: '',
    //       }
    //   })
    else
      setRowValues((prevState) => {
        return { ...prevState, [key]: value }
      })
  }

  return (
    <>
      {tableConfig['headerKeys'].map((item) => {
        const dependantKeyValue = tableConfig['fields'][item]['dependantKey']
          ? rowValues[tableConfig['fields'][item]['dependantKey']]
          : null

        const dropdownData = getDropdownDataHandler({
          source: tableConfig['fields'][item]['dropdownSource'],
          item: item,
          apiDropdownData,
          tableConfig,
          dependantKeyValue,
        })
        const createable =
          findOthersInDropdown(dropdownData.initial) && rowValues[item] === createableId
            ? rowValues[item.replace('Id', 'Name')]
            : rowValues[item]
        return (
          <td
            key={item}
            style={{ maxWidth: tableConfig['fields'][item].maxWidth, wordWrap: 'break-word' }}
          >
            <ADInputText
              enableEdit={isNewRow || editable}
              value={createable}
              inputKey={item}
              onChange={onChangeHandler}
              inputConfig={tableConfig['fields'][item]}
              dropdownData={dropdownData}
            />
          </td>
        )
      })}
    </>
  )
}

export default ADFinancingRow
