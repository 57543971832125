import { useGetFinancialYearConfigQuery, useUpdateFinancialYearConfigMutation } from 'store/api'

import { useState } from 'react'

import { Group, Stack, Text, ActionIcon, Switch } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { useStyle } from 'view/style'

import { EditIcon } from 'assets/svg'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { CustomBadge, TableContainerWithScroll } from '../components'
import { DeclarationCycleEditModal } from '../components/Modal'

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const CycleStatus = ({ financialYear, openingDate, closingDate }) => {
  const currentYear = dayjs().get('year')
  const equalsCurrentYear = financialYear === currentYear
  if (dayjs(dayjs().format('YYYY-MM-DD')).isBefore(openingDate))
    return (
      <CustomBadge
        label="Yet to be open"
        badgeWidth={130}
      />
    )
  else if (
    dayjs(dayjs().format('YYYY-MM-DD')).isSameOrAfter(openingDate) &&
    dayjs(dayjs().format('YYYY-MM-DD')).isSameOrBefore(closingDate)
  )
    return (
      <CustomBadge
        label="Submission Ongoing"
        fontColor="#005DB9"
        bgColor="#E2F1FC"
        badgeWidth={160}
      />
    )
  else if (equalsCurrentYear && dayjs(dayjs().format('YYYY-MM-DD')).isSameOrAfter(closingDate))
    return (
      <CustomBadge
        label="Submission Closed"
        badgeWidth={160}
      />
    )
  else return <CustomBadge label="Inactive" />
}

const CycleEdit = ({ row, index }) => {
  const [modalIsOpen, { close: closeModal, open: openModal }] = useDisclosure(false)
  const [updateFinancialConfig] = useUpdateFinancialYearConfigMutation()
  return (
    <>
      <ActionIcon
        onClick={openModal}
        title={`Edit Declaration Cycle ${row.year}`}
        aria-label={`cycleEdit${index}`}
        disabled={dayjs(dayjs().format('YYYY-MM-DD')).isAfter(row.formSubmissionClosingDate)}
      >
        <EditIcon />
      </ActionIcon>
      <DeclarationCycleEditModal
        handleClose={closeModal}
        handleSubmit={updateFinancialConfig}
        isOpen={modalIsOpen}
        id={row.id}
        financialYear={row.year}
        initialDates={[row.formSubmissionOpeningDate, row.formSubmissionClosingDate]}
      />
    </>
  )
}

const DeclarationCycleTable = ({ data, showAll }) => {
  const scrollMaxHeight = showAll && 310
  const { classes } = useStyle()
  const tableHeader = [
    { name: 'No', align: 'left' },
    { name: 'Declaration Year', align: 'left' },
    { name: 'Cycle Period', align: 'left' },
    { name: 'Status', align: 'left' },
    { name: '', align: 'left' },
  ]
  return (
    <TableContainerWithScroll
      scrollMaxHeight={scrollMaxHeight}
      tableHeader={tableHeader}
      numberOfRows={data?.length}
      showFooterShadow={showAll}
    >
      <tbody className={classes.tableBody}>
        {data
          ? data.map((row, index) => {
              if (showAll || row.year === Number(dayjs().get('year')))
                return (
                  <tr key={row.id}>
                    <td>
                      <Text>{index + 1 < 10 ? `0${index + 1}` : index + 1}</Text>
                    </td>
                    <td>
                      <Text>{row.year}</Text>
                    </td>
                    <td>
                      <Stack spacing={0}>
                        <Text>{`${dayjs(row.formSubmissionOpeningDate).format(
                          'DD MMM YYYY'
                        )} - ${dayjs(row.formSubmissionClosingDate).format('DD MMM YYYY')}`}</Text>
                        <Text
                          color="#677C91"
                          fw={400}
                        >
                          {dayjs(row.formSubmissionClosingDate).diff(
                            row.formSubmissionOpeningDate,
                            'day'
                          ) + 1}
                          {' days'}
                        </Text>
                      </Stack>
                    </td>
                    <td>
                      <CycleStatus
                        openingDate={row.formSubmissionOpeningDate}
                        closingDate={row.formSubmissionClosingDate}
                        financialYear={row.year}
                      />
                    </td>
                    <td>
                      <CycleEdit
                        row={row}
                        index={index}
                      />
                    </td>
                  </tr>
                )
            })
          : null}
      </tbody>
    </TableContainerWithScroll>
  )
}

const DeclarationCycle = () => {
  const { data } = useGetFinancialYearConfigQuery()
  const [showAll, toggleShowAll] = useState(false)
  return (
    <>
      <Group
        position="apart"
        pb={24}
      >
        <Stack spacing={4}>
          <Text
            size={24}
            fw={600}
          >
            Declaration Cycle
          </Text>
          <Text>Set your declaration cycle submission date</Text>
        </Stack>
        <Switch
          label="Show all"
          labelPosition="left"
          onChange={(event) => toggleShowAll(event.currentTarget.checked)}
          value={showAll}
          aria-label="toggleShowAllYear"
        />
      </Group>
      <DeclarationCycleTable
        data={data}
        showAll={showAll}
      />
    </>
  )
}

export default DeclarationCycle
