//Splliting the component for easy testing
const DOCustomTick = ({ x, y, payload }) => {
  // Unfortunately the Recharts XAxis tick doesn't have the whiteSpace property for customizing

  const text = payload.value.split(' ')

  //This will render the text with new line
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fill="#666"
      >
        <tspan
          textAnchor="middle"
          x="0"
        >
          {text[0]}
        </tspan>
        <tspan
          textAnchor="middle"
          x="0"
          dy="20"
        >
          {text[1]}
        </tspan>
      </text>
    </g>
  )
}

export default DOCustomTick
