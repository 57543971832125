import { ActionIcon, Group, Menu } from '@mantine/core'

import { HambergerDotIcon } from 'assets/svg'

const MenuButton = ({ children, ariaLabel }) => {
  return (
    <Group position="right">
      <Menu
        withArrow
        position="left-start"
        transition="pop"
        transitionDuration={150}
        withinPortal
      >
        <Menu.Target>
          <ActionIcon aria-label={ariaLabel}>
            <HambergerDotIcon />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>{children}</Menu.Dropdown>
      </Menu>
    </Group>
  )
}

export default MenuButton
