import { useCallback, useState } from 'react'

function usePostRequest() {
  const [state, setState] = useState({
    response: null,
    isLoading: false,
    status: null,
    trigger: false,
  })

  const postData = useCallback(async (url, data) => {
    setState((prevState) => ({ ...prevState, isLoading: true, trigger: true }))
    try {
      const res = await fetch(process.env.REACT_APP_API_ENDPOINT + url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('apiIdToken')}`,
        },
        body: JSON.stringify(data),
      })

      try {
        const json = await res.json()
        setState((prevState) => ({ ...prevState, response: json, status: res?.status }))
      } catch (error) {
        setState((prevState) => ({ ...prevState, status: res?.status }))
      }
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }))
    }
  }, [])

  const resetTrigger = useCallback(() => {
    setState((prevState) => ({ ...prevState, trigger: false }))
  }, [])

  return { ...state, postData, resetTrigger }
}

export default usePostRequest
