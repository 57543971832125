import { useGetAdminActivityQuery } from 'store/api'

import { useState } from 'react'

import {
  Text,
  Box,
  Group,
  TextInput,
  Table,
  Center,
  Loader,
  Stack,
  Pagination,
} from '@mantine/core'
import { useDebouncedValue, useInputState } from '@mantine/hooks'

import { DynamicEmptyData, AvatarIcon } from 'view/components'
import { useStyle } from 'view/style'

import { NoUserToDisplayIcon, UserNotFound, SearchIcon } from 'assets/svg'

import { paginationSlice } from 'util/helper'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

const ESAdminTab = () => {
  const [searchedUsers, setSearchedUsers] = useInputState('')
  const { data: adminLogData, isFetching } = useGetAdminActivityQuery()
  const [userList] = useDebouncedValue(adminLogData, 0, { leading: true })
  const { classes } = useStyle()
  const [activePage, setPage] = useState(1)

  const validateDatas = (userList) => {
    if (userList?.length === 0) {
      return {
        data: false,
        title: 'User not found',
        message: 'Refine your search keywords',
        icon: <UserNotFound />,
      }
    }
    if (userList?.length > 0) {
      return {
        data: true,
        title: '',
        message: '',
        icon: null,
      }
    }

    return {
      data: false,
      title: 'No user to display',
      message: 'Use search bar to find user',
      icon: <NoUserToDisplayIcon />,
    }
  }

  const filterByNameNEmail = (datas) =>
    datas
      ? datas.filter((item) => {
          return (
            item?.name.toLowerCase().includes(searchedUsers) ||
            item?.email.toLowerCase().includes(searchedUsers)
          )
        })
      : []

  const secondLabelPagination =
    paginationSlice(filterByNameNEmail(userList), activePage).length * activePage
  const firstLabelPagination =
    secondLabelPagination - Math.floor((secondLabelPagination - 1) / activePage)

  return (
    <>
      <Group pb={24}>
        <TextInput
          placeholder="Search name or email (min 3 characters)"
          rightSection={<SearchIcon />}
          value={searchedUsers}
          onChange={setSearchedUsers}
          aria-label="search"
          w={'100%'}
        />
      </Group>
      {isFetching ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <DynamicEmptyData
          hasData={validateDatas(filterByNameNEmail(userList))['data']}
          icon={validateDatas(filterByNameNEmail(userList))['icon']}
          iconTitle={validateDatas(filterByNameNEmail(userList))['title']}
          message={validateDatas(filterByNameNEmail(userList))['message']}
        >
          <Box className={classes.tableContainer}>
            <Table
              horizontalSpacing="sm"
              verticalSpacing="sm"
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>Date</th>
                  <th style={{ width: 'unset' }}>Name</th>
                  <th style={{ width: 'unset' }}>Activity</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                {userList !== undefined
                  ? paginationSlice(filterByNameNEmail(userList), activePage)?.map((item) => {
                      return (
                        <tr key={`${item?.name + item?.date}`}>
                          <td>
                            <Stack
                              align="flex-start"
                              spacing={0}
                            >
                              <Text>{dayjs(item?.date).format('DD/MM/YYYY')}</Text>
                              <Text color={'#677C91'}>{dayjs(item?.date).format('LT')}</Text>
                            </Stack>
                          </td>
                          <td>
                            <Group>
                              <AvatarIcon profileData={{ fullName: item?.name }} />
                              <Stack
                                align="flex-start"
                                spacing={0}
                              >
                                <Text>{item?.name}</Text>
                                <Text color={'#677C91'}>{item?.email}</Text>
                              </Stack>
                            </Group>
                          </td>
                          <td>
                            <Text>{item?.activity}</Text>
                          </td>
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </Table>
            <Group
              position="apart"
              className={classes.paginationFooter}
            >
              <div>{`Showing ${firstLabelPagination} to ${secondLabelPagination} of ${
                filterByNameNEmail(userList).length
              } results`}</div>
              <Pagination
                page={activePage}
                onChange={setPage}
                total={Math.ceil(filterByNameNEmail(userList).length / 10)}
                styles={{
                  item: {
                    borderColor: 'transparent',
                    color: '#007a72',
                    backgroundColor: '#ffffff',
                    '&[data-active]': {
                      borderColor: 'transparent',
                      color: '#ffffff',
                      backgroundColor: '#AFDDEB',
                    },
                  },
                }}
              />
            </Group>
            <Group className={classes.tableFooter} />
          </Box>
        </DynamicEmptyData>
      )}
    </>
  )
}

export default ESAdminTab
