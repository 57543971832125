import { Text, Accordion, Stack, Anchor } from '@mantine/core'

import { useStyle } from 'view/style'

const TSAccordian = () => {
  const classes = useStyle()
  const TRUST_SAFETY_ACCORDIAN_TEXT = [
    {
      title: 'Who is able to access my myAsset data?',
      content: (
        <Stack>
          <Text>
            {`The access of users' data is strictly limited to one Super Admin i.e. .Senior Vice President (SVP) Group HRM. Access is restricted and only granted for the purpose of investigations, litigations, or other legally required disclosures. Other than for the said purpose, your data cannot be freely accessed by the Company.`}
          </Text>
        </Stack>
      ),
    },
    {
      title: 'Where is my information kept?',
      content:
        'The data is stored in a database that is hosted within PETRONAS secure Cloud system.',
    },
    {
      title:
        'How is the information kept secure to deal with the applicable standards of security including encryption?',
      content:
        'The data is fully encrypted and is stored in a secure cloud system, and is only accessible to PETRONAS employees. The data is written as encrypted text whereby any PETRONAS employee other than the owner of the asset declaration will not be able to read the data.',
    },
    {
      title: 'How is this platform protected?',
      content:
        'The platform is hosted in a secure private network and it is protected by cloud as per industry security standards. The data is fully encrypted. Only PETRONAS employee authenticated via Azure AD can access the system. The platform is continuously kept unto date with the latest security updates, the platform has alerts to detect any security threats in near real time and tools deployed that continuously scan for vulnerability automatically.',
    },
    {
      title: 'How am I being supported?',
      content: (
        <Stack spacing={0}>
          <Text>
            {'For issues regarding Asset Declaration, please contact '}
            <Anchor
              href="mailto:assetdeclaration@petronas.com"
              target="_blank"
            >
              assetdeclaration@petronas.com
            </Anchor>
          </Text>
          <Text>
            {'For technical support (e.g. login issues,etc), please contact '}
            <Anchor
              href="mailto:ict.servicedesk@petronas.com"
              target="_blank"
            >
              ict.servicedesk@petronas.com
            </Anchor>
          </Text>
        </Stack>
      ),
    },
    {
      title: 'What happens to my data after I leave the company?',
      content:
        'All records are retained for seven (7) years from the submission date. After which, they will be automatically and permanently deleted from the system.',
    },
  ]

  return (
    <Accordion
      variant="separated"
      chevronSize={40}
      multiple
    >
      {TRUST_SAFETY_ACCORDIAN_TEXT.map(({ title, content }) => {
        return (
          <Accordion.Item
            key={title}
            value={title}
            sx={{ border: '1px solid #CED4DA', backgroundColor: 'white !important' }}
            className={classes.accordianItem}
          >
            <Accordion.Control
              py={22}
              px={24}
            >
              <Text
                fw={600}
                fz={16}
              >
                {title}
              </Text>
            </Accordion.Control>
            <Accordion.Panel
              px={24}
              pb={4}
            >
              {content}
            </Accordion.Panel>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}

export default TSAccordian
