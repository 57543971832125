import { useState, useEffect } from 'react'

import { Table, Group, Box, Pagination, TextInput, Grid, Col, Stack, Skeleton } from '@mantine/core'
import { useId } from '@mantine/hooks'

import { DynamicEmptyData, PlusButton } from 'view/components'
import { useStyle } from 'view/style'

import { SearchIcon } from 'assets/svg'

import {
  filterDataWithEmptyString,
  getPaginationFirstNumber,
  getPaginationSecondNumber,
  paginationSlice,
} from 'util/helper'

import FMTableRows from './FMTableRows'

const TableSearchAdd = ({
  search,
  handleSearchChange,
  newRowActive,
  addRow,
  addButtonLabel,
  tableConfig,
}) => {
  return (
    <Grid>
      <Col
        span={'auto'}
        py={0}
      >
        <TextInput
          placeholder="Search by any field"
          rightSection={<SearchIcon />}
          value={search}
          onChange={handleSearchChange}
          aria-label="search"
        />
      </Col>
      <Col
        span={'content'}
        py={0}
      >
        <PlusButton
          disable={newRowActive}
          onClick={addRow}
          customText={addButtonLabel}
          ariaLabel={'addRow' + tableConfig['tableName']}
        />
      </Col>
    </Grid>
  )
}

const FMTable = ({ data = [], onEdit, onAdd, apiState, tableConfig, addButtonLabel }) => {
  const uuid = useId()
  const { classes } = useStyle()
  const [tableData, setTableData] = useState([])
  const [comparisonData, setComparisonData] = useState([])
  const [formData, setFormData] = useState({})
  const [newRowActive, setIsNewRowActive] = useState(false)
  const [search, setSearch] = useState('')
  const [activePage, setPage] = useState(1)

  const addRow = () => {
    setIsNewRowActive(true)
  }

  useEffect(() => {
    if (apiState?.isSuccess) {
      setTableData(data)
      setComparisonData(data.map((item) => item[tableConfig['queryKey']]?.toLowerCase()))
      setFormData({
        values: {
          [tableConfig.tableName]: [...data],
        },
      })
    }
  }, [apiState])

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget
    setSearch(value)
    if (value) setPage(1)
    setTableData(
      filterDataWithEmptyString(data, {
        reversed: false,
        search: value,
        searchArea: [[tableConfig['queryKey']]],
      })
    )
  }

  const secondLabelPagination = getPaginationSecondNumber(activePage, tableData)
  const firstLabelPagination = getPaginationFirstNumber(activePage)
  return (
    <Stack
      spacing={20}
      mt={10}
    >
      <TableSearchAdd
        search={search}
        handleSearchChange={handleSearchChange}
        newRowActive={newRowActive}
        addRow={addRow}
        addButtonLabel={addButtonLabel}
        tableConfig={tableConfig}
      />
      <Skeleton visible={apiState?.isFetching || apiState?.isLoading}>
        <DynamicEmptyData hasData={tableData?.length || newRowActive}>
          <Box className={classes.tableContainer}>
            <Table
              className={classes.tableSize}
              horizontalSpacing="sm"
              verticalSpacing="sm"
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>No</th>
                  {tableConfig['headerKeys'].map((index) => {
                    const { align, headerName } = tableConfig['fields'][index]
                    return (
                      <th
                        key={index}
                        style={{ textAlign: align || 'left' }}
                      >
                        {headerName}
                      </th>
                    )
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                {/* Add function, will add new row on top */}
                {newRowActive ? (
                  <FMTableRows
                    onAdd={onAdd}
                    onEdit={onEdit}
                    form={formData}
                    rowItems={tableConfig['defaultValue']}
                    rowIndex={tableData?.length}
                    key={tableData?.length}
                    tableConfig={tableConfig}
                    comparisonList={comparisonData}
                    isNewRow={newRowActive}
                    setIsNewRow={setIsNewRowActive}
                  />
                ) : null}
                {/* Table Contents */}
                {paginationSlice(tableData, activePage)?.map((rowItems, rowIndex) => (
                  <FMTableRows
                    onAdd={onAdd}
                    onEdit={onEdit}
                    form={formData}
                    rowItems={rowItems}
                    rowIndex={(activePage - 1) * 10 + rowIndex + 1}
                    key={`${uuid + rowIndex}`}
                    tableConfig={tableConfig}
                    comparisonList={comparisonData}
                  />
                ))}
              </tbody>
            </Table>
            <Group
              position="apart"
              className={classes.paginationFooter}
            >
              <div>{`Showing ${firstLabelPagination} to ${secondLabelPagination} of ${tableData.length} results`}</div>
              <Pagination
                page={activePage}
                onChange={setPage}
                total={Math.ceil(tableData.length / 10)}
                styles={{
                  item: {
                    borderColor: 'transparent',
                    color: '#007a72',
                    backgroundColor: '#ffffff',
                    '&[data-active]': {
                      borderColor: 'transparent',
                      color: '#ffffff',
                      backgroundColor: '#AFDDEB',
                    },
                  },
                }}
              />
            </Group>
            <Group
              position="right"
              className={classes.tableFooter}
            />
          </Box>
        </DynamicEmptyData>
      </Skeleton>
    </Stack>
  )
}

export default FMTable
