import { createStyles } from '@mantine/core'

const useStyle = createStyles((theme) => ({
  //Layout
  header: {
    backgroundColor: '#00A19C',
    borderBottom: '1px solid #6AC2BE',
  },

  headerInner: {
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  footer: {
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    padding: '20px 0',
    borderTop: `1px solid #D3DEE8`,
  },

  //Accordian
  accordianItem: {
    // borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    backgroundColor: 'white',
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  //Table

  tableSize: {
    'th:first-of-type': { width: 50 },
    'td:last-child': { width: 100 },
    [theme.fn.largerThan('sm')]: {
      'th:nth-last-of-type(2)': { width: 200 },
    },
  },

  tableFooter: {
    height: 52,
    borderTop: '1px solid',
    borderWidth: '0.8px',
    borderColor: '#CED4DA',
    backgroundColor: '#f4f6fa',
    width: '100%',
    padding: 10,
    borderRadius: '0 0 5px 5px',
  },

  tableHeader: {
    backgroundColor: '#f4f6fa',
    position: 'sticky',
    top: 0,
    transition: 'box-shadow 150ms ease',
    zIndex: 20,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: '0.8px solid #CED4DA',
    },
  },
  scrolledDown: {
    boxShadow: '0 4px 6px 0px rgba(0, 0, 0, 0.15)',
  },
  scrolledUp: {
    boxShadow: '0 -4px 6px 0px rgba(0, 0, 0, 0.15)',
  },
  selectedRow: {
    backgroundColor: 'rgba(223, 241, 241, 0.5)',
  },
  tableContainer: {
    border: 'solid',
    borderWidth: '0.8px',
    borderColor: '#CED4DA',
    borderRadius: '5px',
  },

  tableBody: {
    zIndex: 10,
  },

  //Custom Dropdown component
  dropdownWrapper: {
    padding: '5px 0',
  },

  // applies styles to right section aka icon
  dropdownRightSection: { pointerEvents: 'none' },

  dropdownitem: {
    borderRadius: 0,
    // applies styles to selected item
    '&[data-selected]': {
      '&, &:hover': {
        backgroundColor: '#DFF1F1',
        borderLeft: 'solid',
        borderColor: '#00847C',
        color: '#000000',
      },
    },

    // applies styles to hovered item (with mouse or keyboard)
    '&[data-hovered]': {
      backgroundColor: '#DFF1F1',
      color: '#000000',
    },
  },

  stepperContainer: {
    backgroundColor: 'white',
    border: '1px solid #007874',
    height: 70,
    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'center',
      height: 160,
    },
  },
  stepperBorderActive: {
    position: 'relative',
    height: ' 100%',
    [theme.fn.largerThan('sm')]: {
      '&::before': {
        content: '""',
        height: '49px',
        width: '49px',
        position: 'absolute',
        top: '14%',
        right: '-6%',
        borderTop: '1px solid #007874',
        borderLeft: '1px solid #007874',
        transform: 'rotate(135deg)',
        backgroundColor: 'inherit',
        zIndex: '1',
        justifyContent: 'center',
      },
      '&::after': {
        content: '""',
        height: '67px',
        width: '49px',
        position: 'absolute',
        right: '-16%',
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
      },
    },
  },

  timelineBody: {
    display: 'flex',
    gap: '2.5rem',
  },

  timelineTitle: {
    fontSize: 20,
    fontWeight: 700,
  },

  cellAlignRight: { textAlign: 'right !important' },
  cellAlignLeft: { textAlign: 'left' },

  reviewTableCard: {
    border: '1px solid #D3DEE8',
    background: 'rgba(237, 241, 248, 0.25)',
  },

  statusSubmit: {
    backgroundColor: 'rgba(229, 244, 231, 1)',
    color: 'rgba(0, 119, 36, 1)',
    borderRadius: '8px',
    fontWeight: 500,
    alignItems: 'center',
    alignText: 'center',
    padding: '3px 7px',
    width: 'fit-content',
  },

  statusDraft: {
    backgroundColor: 'rgba(224, 224, 224, 1)',
    color: 'rgba(57, 57, 57, 1)',
    borderRadius: '8px',
    fontWeight: 500,
    alignItems: 'center',
    alignText: 'center',
    padding: '3px 7px',
    width: 'fit-content',
  },
  alignTextRight: { textAlign: 'right' },

  // Form Management
  fmTabList: { borderRight: 'unset', marginRight: '10px' },

  fmTab: {
    fontSize: 16,
    borderLeft: '3px solid transparent',
    borderRight: 'unset',
    '&[data-active]': {
      backgroundColor: '#DFF1F1',
      borderLeft: '3px solid #007a72',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  },

  paginationFooter: {
    height: 52,
    borderTop: '1px solid',
    borderWidth: '0.8px',
    borderColor: '#CED4DA',
    backgroundColor: '#ffffff',
    width: '100%',
    padding: 10,
  },

  stickyColumn: {
    position: 'sticky',
    right: 0,
  },
}))

export { useStyle }
