import Big from 'big.js'
import { filter } from 'lodash'

import { useEffect, useMemo } from 'react'

import { Text, Input, NumberInput, Switch, MultiSelect } from '@mantine/core'
import { useDebouncedState, useDidUpdate } from '@mantine/hooks'

import { YearPicker, OtherDropdown } from 'view/components'
import { useStyle } from 'view/style'

import {
  checkDecimalLength,
  filterOthersInDropdown,
  findOthersInDropdown,
  MANDATORY_INPUT_RULES,
  multiSelectValueExtractor,
} from 'util/helper'
import { useGetBrandsList } from 'util/hooks'

import dayjs from 'dayjs'

const CheckEditable = ({ inputComponent, textComponent, enableEdit }) => {
  return enableEdit ? inputComponent : textComponent
}

const getDropdownLabelFromValue = (dropdownSelectedValue, canCreate, dropdownData) => {
  if (typeof dropdownSelectedValue === 'string' && canCreate) return dropdownSelectedValue
  return filter(dropdownData, (item) => item.value === dropdownSelectedValue)[0]?.label
}

const formatNumberInput = (value, placeholder) => {
  if (!Number.isNaN(parseFloat(value))) {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return placeholder ? '' : '0'
}

const checkDropdownDisabled = (disabled, inputConfig) => disabled || inputConfig['disable']

// This Component will dynamically return type of input for the table
const ADInputText = ({
  value,
  inputConfig = {
    headerName: '',
    inputType: '',
    mandatory: false,
  },
  placeholder = undefined,
  enableEdit,
  onChange,
  disabled,
  inputKey = '',
  dropdownData = { initial: [], filtered: [] },
  handleDisable,
  enableDecimal = false,
  disableErr = false,
  minAcquisitionYear = null,
}) => {
  const brandList = useGetBrandsList()

  const { classes } = useStyle()
  const [isFieldInvalid, setFieldInvalid] = useDebouncedState(false, 200)
  const canCreate = useMemo(
    () => findOthersInDropdown(dropdownData.initial),
    [dropdownData.initial]
  )

  useEffect(() => {
    if (inputConfig.mandatory) {
      setFieldInvalid(MANDATORY_INPUT_RULES.includes(value))
    } else {
      setFieldInvalid(false)
    }
  }, [value, inputConfig])

  const RenderText = ({ children }) => {
    return (
      <Text
        size="sm"
        weight={400}
        ta={inputConfig['align']}
      >
        {children}
      </Text>
    )
  }

  const valueMapping = useMemo(() => {
    if (inputConfig.inputType !== 'multiselect') {
      return getDropdownLabelFromValue(value, canCreate, dropdownData.initial)
    } else {
      return multiSelectValueExtractor(value)
    }
  }, [inputConfig.inputType, value, canCreate, dropdownData.initial])

  //handle Input changes - additional logic will be added here.
  //Ex validation
  const handleInputChange = (e) => {
    if (inputConfig['inputType'] === 'dropdown')
      onChange(
        inputKey,
        e,
        canCreate,
        getDropdownLabelFromValue(e, canCreate, dropdownData.initial)
      )
    else onChange(inputKey, e, canCreate)
    setFieldInvalid(MANDATORY_INPUT_RULES.includes(e))
  }

  const checkMinValue = (value) => {
    const hasMinimumValue = inputConfig['minimumValue'] !== ''
    const isValueLessThanMinimum = value < inputConfig['minimumValue']

    return hasMinimumValue && isValueLessThanMinimum
  }

  //Handle disable field on edit click
  useDidUpdate(() => {
    const isValueMappingBond = ['Bond'].includes(valueMapping)
    const isInputTypeNotText = inputConfig['inputType'] !== 'text'

    if (isValueMappingBond && isInputTypeNotText) {
      handleDisable(true)
    }
  }, [enableEdit])

  //Conditional renderer variable
  const SWITCH_CLASSES = value ? classes.statusSubmit : classes.statusDraft
  const SWITCH_STATUS_TEXT = value ? 'Active' : 'InActive'
  const NUMBER_VALUE = typeof value === 'number' ? new Big(value).toFixed() : value
  const CURRENCY_VALUE =
    value >= 0 ? value?.toLocaleString(undefined, { minimumFractionDigits: 2 }) : value
  const IS_NUMBER_INVALID = disabled || disableErr ? false : isFieldInvalid

  const input = {
    dropdown: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <OtherDropdown
            data={filterOthersInDropdown(dropdownData.filtered)}
            placeholder={valueMapping}
            onChange={handleInputChange}
            value={value}
            invalid={isFieldInvalid}
            inputKey={inputKey}
            canCreate={canCreate}
            disabled={checkDropdownDisabled(disabled, inputConfig)}
            dropdownSource={inputConfig['dropdownSource']}
          />
        }
        textComponent={<RenderText>{valueMapping}</RenderText>}
      />
    ),
    text: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <Input
            size="sm"
            weight={400}
            maxLength={256}
            onChange={(e) => handleInputChange(e.target.value.trim())}
            invalid={isFieldInvalid}
            defaultValue={value}
            onFocus={(e) => e.target.select()}
            aria-label={`textInput${inputKey}`}
          />
        }
        textComponent={<RenderText>{value}</RenderText>}
      />
    ),
    number: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <NumberInput
            size="sm"
            weight={400}
            min={0}
            max={Number.MAX_SAFE_INTEGER}
            hideControls
            disabled={disabled}
            formatter={(value) => value || 0}
            styles={{ input: { textAlign: inputConfig['align'] } }}
            precision={enableDecimal ? checkDecimalLength(value) : 0}
            parser={(value) => {
              if (enableDecimal) {
                let strNum = String(value)
                let regex = /^(0|[1-9]\d*)?(\.\d{1,15})?$/
                let strNumWithoutDot = strNum.replace('.', '')
                if (
                  !regex.test(strNum) ||
                  strNumWithoutDot.length > 15 ||
                  checkDecimalLength(value) >= 15
                ) {
                  strNum = strNum.substring(0, strNum.length - (strNumWithoutDot.length - 15))
                }
                return strNum
              } else {
                return value
              }
            }}
            onChange={(e) => handleInputChange(e)}
            invalid={IS_NUMBER_INVALID}
            value={value}
            onFocus={(e) => e.target.select()}
            aria-label="numberInput"
          />
        }
        textComponent={<RenderText>{NUMBER_VALUE}</RenderText>}
      />
    ),
    percentage: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <NumberInput
            size="sm"
            maxLength={6}
            max={100}
            min={0}
            weight={400}
            hideControls
            styles={{ input: { textAlign: inputConfig['align'] } }}
            precision={2}
            onChange={(e) => handleInputChange(e)}
            invalid={isFieldInvalid}
            value={value}
            onFocus={(e) => e.target.select()}
          />
        }
        textComponent={<RenderText>{value}</RenderText>}
      />
    ),
    year: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <NumberInput
            size="sm"
            weight={400}
            max={dayjs().year()}
            min={minAcquisitionYear || 1900}
            hideControls
            styles={{ input: { textAlign: inputConfig['align'] } }}
            onChange={(e) => handleInputChange(e)}
            invalid={isFieldInvalid}
            value={value}
            onFocus={(e) => e.target.select()}
            aria-label="numberYearInput"
          />
        }
        textComponent={<RenderText>{value}</RenderText>}
      />
    ),
    currency: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <NumberInput
            size="sm"
            weight={400}
            hideControls
            min={0}
            max={Number.MAX_SAFE_INTEGER}
            placeholder={placeholder || undefined}
            styles={{ input: { textAlign: inputConfig['align'] } }}
            precision={2}
            parser={(value) => {
              let num = value
              num = num.replace(/\D/g, '')
              num = num.replace(/(\d)(\d{2})$/, '$1.$2')
              return num
            }}
            formatter={(value) => formatNumberInput(value, placeholder)}
            onChange={(e) => handleInputChange(e)}
            invalid={isFieldInvalid || checkMinValue(value)}
            value={value}
            onFocus={(e) => e.target.select()}
            aria-label={`currencyInput${inputKey}`}
          />
        }
        textComponent={<RenderText>{CURRENCY_VALUE}</RenderText>}
      />
    ),
    date: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <YearPicker
            align={inputConfig['align']}
            initialValue={value}
            handleChange={handleInputChange}
          />
        }
        textComponent={<RenderText>{value}</RenderText>}
      />
    ),
    switch: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <Switch
            checked={value}
            onChange={(e) => handleInputChange(e.currentTarget.checked)}
          />
        }
        textComponent={<Text className={SWITCH_CLASSES}>{SWITCH_STATUS_TEXT}</Text>}
      />
    ),
    multiselect: (
      <CheckEditable
        enableEdit={enableEdit}
        inputComponent={
          <MultiSelect
            onChange={(e) => handleInputChange(e)}
            data={brandList}
            value={valueMapping}
            clearable
            searchable
          />
        }
        textComponent={
          <MultiSelect
            styles={{
              input: {
                border: '0px !important',
                paddingLeft: 0,
              },
            }}
            readOnly
            data={brandList}
            value={valueMapping}
          />
        }
      />
    ),
  }
  return input[inputConfig['inputType']]
}

export default ADInputText
