import { useState } from 'react'

import { Popover, SimpleGrid, Button, ActionIcon, Group, Text, Input } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { CalendarIcon, ChevronLeft, ChevronRight } from 'assets/svg'

import { generateArrayOfYears, paginationSlice } from 'util/helper'

const YearPicker = ({ handleChange, initialValue, align }) => {
  const [opened, handlers] = useDisclosure(false)
  const [inputValue, setInputValue] = useState(initialValue)
  const [page, setPage] = useState(1)

  //Handle year click
  const onYearClick = async (val) => {
    setInputValue(val)
    await handleChange(val)
    handlers.close()
  }

  //Render the year list.
  const yearList = paginationSlice(generateArrayOfYears(), page, 6)
    .reverse()
    .map((item) => {
      return (
        <Button
          key={item}
          compact
          variant={inputValue === item ? 'filled' : 'default'}
          onClick={() => onYearClick(item.toString())}
          aria-label={`yearValue${item}`}
        >
          {item}
        </Button>
      )
    })

  return (
    <Popover
      opened={opened}
      position="bottom"
      withArrow
      withinPortal
      shadow="md"
      width={250}
    >
      <Popover.Target>
        <div onFocusCapture={() => handlers.open()}>
          <Input
            styles={{ input: { textAlign: align } }}
            value={inputValue}
            aria-label="yearInput"
            icon={<CalendarIcon />}
            readOnly
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Group
          pb={20}
          position="apart"
        >
          <ActionIcon
            aria-label="yearInputLeft"
            onClick={() => setPage((state) => state + 1)}
          >
            <ChevronLeft />
          </ActionIcon>
          <Text>{inputValue}</Text>
          <ActionIcon
            aria-label="yearInputRight"
            disabled={page === 1}
            onClick={() => setPage((state) => state - 1)}
          >
            <ChevronRight />
          </ActionIcon>
        </Group>
        <SimpleGrid cols={3}>{yearList}</SimpleGrid>
      </Popover.Dropdown>
    </Popover>
  )
}

export default YearPicker
