import { Text, List, ThemeIcon, Anchor } from '@mantine/core'

import { CustomAlertBanner } from 'view/components'

const ExchangeRateBanner = () => {
  const EXCHANGE_RATE_TEXT = [
    'All assets declared must be in Malaysian Ringgit (MYR)',
    'The declarer is responsible for converting any assets held in other currencies into MYR using',
    'The conversion must be calculated using the exchange rate on the day the assets are being declared in myAsset',
  ]

  return (
    <CustomAlertBanner
      withInfoIcon={true}
      content={
        <List
          pl={14}
          size={14}
          icon={
            <ThemeIcon
              radius="xl"
              size={5}
              color="#00488F"
            ></ThemeIcon>
          }
        >
          {EXCHANGE_RATE_TEXT.map((text, index) => (
            <List.Item key={text}>
              <Text
                size={14}
                color="#00488F"
              >
                {text}
                {index === 1 ? (
                  <Anchor
                    href="https://mypetronas.com/exchange-rate"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline
                  >
                    <Text
                      size={14}
                      weight={700}
                      color="#00488F"
                    >
                      {' myPETRONAS Exchange Rate.'}{' '}
                    </Text>
                  </Anchor>
                ) : null}
              </Text>
            </List.Item>
          ))}
        </List>
      }
    ></CustomAlertBanner>
  )
}

export default ExchangeRateBanner
