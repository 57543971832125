import {
  setDOPreview,
  calculateTotalAmount,
  calculateTotalRows,
  setStepperStep,
} from 'store/assetDeclareSlice'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ActionIcon, Group, Tooltip } from '@mantine/core'

import { EyeIcon, EditIcon } from 'assets/svg'

import { formatResponse } from 'util/helper'

const DOIcon = ({ data, latestRevisionNum = null }) => {
  const { submitted } = data
  const dispatch = useDispatch()

  const history = useHistory()

  const clickDispatch = () => {
    dispatch(calculateTotalAmount(formatResponse(data.formData)))
    dispatch(calculateTotalRows(JSON.stringify(formatResponse(data.formData))))
    dispatch(setDOPreview({ ...data, latestRevisionNum }))
    dispatch(setStepperStep(1))
    sessionStorage.setItem('assetform', JSON.stringify(data))
  }

  const renderConfig = [
    {
      label: submitted ? 'View' : 'Preview',
      icon: <EyeIcon />,
      handleClick: () => {
        clickDispatch()
        history.push('/declaration-overview/review')
      },
    },
    {
      label: submitted ? 'Revise' : 'Edit Draft',
      icon: <EditIcon />,
      handleClick: () => {
        clickDispatch()
        history.push(submitted ? '/declaration-overview/revision' : '/assets')
      },
    },
  ]
  return (
    <Group
      w={80}
      position="apart"
    >
      {renderConfig.map(({ label, icon, handleClick }, key) => {
        return (
          <Tooltip
            key={label}
            withinPortal
            withArrow
            label={label}
          >
            <ActionIcon
              aria-label={`DOIcon${key}`}
              onClick={handleClick}
            >
              {icon}
            </ActionIcon>
          </Tooltip>
        )
      })}
    </Group>
  )
}

export default DOIcon
