import { Avatar } from '@mantine/core'

const AvatarIcon = ({ profileData, photoData }) => {
  return (
    <>
      {photoData ? (
        <Avatar
          size="md"
          radius="xl"
          src={photoData}
        />
      ) : (
        <Avatar
          size="md"
          radius="xl"
        >
          {profileData?.fullName
            .match(/(\b\S)?/g)
            .join('')
            .match(/(^\S|\S$)?/g)
            .join('')
            .toUpperCase()}
        </Avatar>
      )}
    </>
  )
}

export default AvatarIcon
