import { Paper, Stack, Title, Text } from '@mantine/core'

import { TRUST_SAFETY, TRUST_SAFETY_SECURITY_COMMITMENT } from 'util/textMapping'

import TSAccordian from './TSAccordian'

const TrustAndSafety = () => {
  return (
    <Paper
      px={{ md: '10rem', sm: '3rem', xs: '3rem' }}
      pb={40}
      mb="10rem"
    >
      <Stack spacing={40}>
        <Stack align="center">
          <Title>Trust & Safety</Title>
          <Text align="center">{TRUST_SAFETY}</Text>
        </Stack>
        <Stack>
          <Text
            weight={700}
            size="xl"
          >
            myAsset Security Commitment
          </Text>
          <Text>{TRUST_SAFETY_SECURITY_COMMITMENT}</Text>
        </Stack>
        <TSAccordian />
      </Stack>
    </Paper>
  )
}

export default TrustAndSafety
