import { useSelector } from 'react-redux'

import { Text } from '@mantine/core'

import { CustomAlertBanner } from 'view/components'

import { DRAFT_INFO_TEXT, NEW_REVISION_TEXT } from 'util/textMapping'

const StepperBanner = ({
  activeStep,
  checkSubmissionSuccess,
  submittedStatus,
  submissionConfig = { allow: false, year: null, formSubmissionClosingDate: null },
}) => {
  const { allow, year, formSubmissionClosingDate } = submissionConfig
  const { revision, submitted: isRevisionSubmitted } = useSelector(
    (state) => state.assetDeclare?.tempPreview
  )

  const submissionMessage = `Submission for year ${year} is closed on ${formSubmissionClosingDate}. You are allowed to make revisions to your submission as draft after the submission period. For more information, read the FAQ or if you require assistance, Contact Support.`

  if (activeStep === 0)
    return (
      <CustomAlertBanner
        withCloseButton
        content={
          <Text>
            To enable the
            <Text
              mx="0.4rem"
              span
              fs="italic"
              weight={700}
            >
              Start Declaration
            </Text>
            button below, please read through the
            <Text
              span
              mx="0.4rem"
              fs="italic"
              weight={700}
            >
              What you need to know
            </Text>
            section first.
          </Text>
        }
      />
    )

  return (
    <>
      {isRevisionSubmitted || (activeStep === 2 && !submittedStatus) ? (
        <CustomAlertBanner
          title={isRevisionSubmitted ? `New Revision ${revision}` : 'Review Your Declaration'}
          content={isRevisionSubmitted ? NEW_REVISION_TEXT : DRAFT_INFO_TEXT}
        />
      ) : null}

      {!allow && checkSubmissionSuccess ? (
        <CustomAlertBanner
          title={'Submission window closed.'}
          content={submissionMessage}
          showAdditional={true}
        />
      ) : null}
    </>
  )
}
export default StepperBanner
