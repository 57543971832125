import { useMemo, useState } from 'react'

import { findOthersInDropdown, getDropdownDataHandler } from 'util/helper'

import ADInputText from './ADInputText'

const ADInvestmentRows = ({
  rowValues,
  isNewRow,
  editable,
  tableConfig,
  setRowValues,
  apiDropdownData,
  setMandatory = () => {},
}) => {
  const [disableField, setDisableField] = useState(false)
  const [enableDecimal, setEnableDecimal] = useState(false)
  const [disableErr, setDisableErr] = useState(false)

  const createableId = 0

  // Add items below for non-mandatory no. of units
  const itemsToCheck = ['Bond', 'Investment Capital', 'Commodities']

  // Check if it has non-mandatory items
  useMemo(() => {
    const investments = apiDropdownData?.investments?.investmentTypeId
    const rowInvestmentTypeId = rowValues?.investmentTypeId

    const findById = investments?.find((val) => val.value === rowInvestmentTypeId)
    const hasItems = itemsToCheck?.some((item) => findById?.label?.includes(item))

    setEnableDecimal(findById?.label === 'Cryptocurrencies')
    setDisableField(!['Commodities'].includes(findById?.label) && hasItems)
    setDisableErr(findById?.label === 'Commodities')
    setMandatory(!hasItems)
  }, [rowValues, editable])

  const onChangeHandler = (key, value, canCreate, dropdownLabel = null) => {
    const isNonMandatory = itemsToCheck.some((item) => dropdownLabel?.includes(item))

    if (key === tableConfig['headerKeys'][1]) {
      setDisableField(isNonMandatory)
      setMandatory(!isNonMandatory)
      setEnableDecimal(dropdownLabel === 'Cryptocurrencies')
    }
    if (canCreate) {
      setRowValues((prevState) => {
        if (typeof value === 'string')
          return {
            ...prevState,
            [key]: createableId,
            [key.replace('Id', 'Name')]: value,
            ['unit']: enableDecimal ? 0 : prevState['unit'],
          }
        else
          return {
            ...prevState,
            [key]: value,
            [key.replace('Id', 'Name')]: dropdownLabel,
            ['unit']: enableDecimal ? 0 : prevState['unit'],
          }
      })
    }
    //If the type of investment is equal to items in itemsToCheck array,
    // set the state to true which will disable Unit field
    else if (key === tableConfig['headerKeys'][1] && isNonMandatory) {
      setRowValues((prevState) => {
        return {
          ...prevState,
          [key]: value,
          ['unit']: 0,
        }
      })
    } else {
      setRowValues((prevState) => {
        return { ...prevState, [key]: value }
      })
    }
  }

  return (
    <>
      {tableConfig['headerKeys'].map((item) => {
        let inputConfig = tableConfig['fields'][item]

        if (!disableField && item === 'unit') {
          inputConfig = { ...inputConfig, mandatory: true }
        }

        const dropdownData = getDropdownDataHandler({
          source: tableConfig['fields'][item]['dropdownSource'],
          item: item,
          apiDropdownData,
          tableConfig,
        })

        const createable =
          findOthersInDropdown(dropdownData.initial) && rowValues[item] === createableId
            ? rowValues[item.replace('Id', 'Name')]
            : rowValues[item]

        return (
          <td
            key={item}
            style={{ maxWidth: tableConfig['fields'][item].maxWidth, wordWrap: 'break-word' }}
          >
            <ADInputText
              handleDisable={(val) => {
                setDisableField(val)
              }}
              enableEdit={isNewRow || editable}
              enableDecimal={item.includes('unit') ? enableDecimal : false}
              value={createable}
              disableErr={disableErr}
              inputKey={item}
              disabled={item.includes('unit') ? disableField : null}
              onChange={onChangeHandler}
              inputConfig={inputConfig}
              dropdownData={dropdownData}
            />
          </td>
        )
      })}
    </>
  )
}

export default ADInvestmentRows
