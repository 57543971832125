import { Button } from '@mantine/core'

import { PlusIcon } from 'assets/svg'

const PlusButton = ({ onClick, disable = false, customText = '', ariaLabel, loading = false }) => {
  const buttonText = customText || 'Add Row'

  return (
    <Button
      variant="default"
      leftIcon={<PlusIcon />}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={disable}
      loading={loading}
      sx={{ width: 'fit-content' }}
    >
      {buttonText}
    </Button>
  )
}

export default PlusButton
