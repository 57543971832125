import { isEmpty } from 'lodash'

import { useMemo, useState } from 'react'

import { Text } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'

import { ADRows } from 'view/components'
import { SubmitModal, EditModal } from 'view/components/Modal'
import { useStyle } from 'view/style'

import { MANDATORY_INPUT_RULES, mapIdToMultiSelectData } from 'util/helper'
import { useGetBrandsList } from 'util/hooks'

import FMRowIcons from './FMRowIcons'

const checkRowValuesHasType = (obj) => {
  let value = false
  Object.entries(obj).forEach((item) => {
    if (item[0] === 'types') value = true
  })
  return value
}

const checkTypesArrayIsEmpty = (obj) => {
  let value = false
  Object.entries(obj).forEach((item) => {
    // types is only present in vehicle brand mapping, 17th July 2023
    if (item[0] === 'types' && isEmpty(item[1])) value = true
  })
  return value
}

const FMTableRows = ({
  onEdit,
  onAdd,
  form,
  rowIndex,
  rowItems,
  tableConfig,
  comparisonList,
  isNewRow = false,
  setIsNewRow,
}) => {
  const { classes } = useStyle()
  const brandList = useGetBrandsList()

  const initialRowValues = rowItems
  const [editable, setEditable] = useState(false)
  const [rowValues, setRowValues] = useState(initialRowValues)

  const hasTypeInRowValues = useMemo(() => checkRowValuesHasType(rowItems), [rowItems])

  const containsEmptyField = Object.values(rowValues).some((x) => {
    const query = rowValues[tableConfig['queryKey']]?.toLowerCase()

    const compareWithDatalist = comparisonList
      .filter((e) => e !== rowValues.name.toLowerCase())
      .includes(query)

    const emptyOrInList = MANDATORY_INPUT_RULES.includes(x) || compareWithDatalist

    let isEqual

    // Check if the row has a type field
    if (hasTypeInRowValues) {
      // Check if the type field is an array of objects
      const checkArrType = rowValues.types.every((el) => typeof el === 'object')
        ? rowValues.types.map((e) => e.id)
        : rowValues.types

      // Check if the types in the row and the original row are equal
      const typesEqual =
        JSON.stringify(checkArrType) === JSON.stringify(rowItems.types.map((e) => e.id))

      isEqual =
        typesEqual && rowItems.name === rowValues.name && rowItems.active === rowValues.active
    } else {
      // Check if all fields in the row are equal to the original row
      isEqual =
        Object.entries(rowValues).sort().toString() === Object.entries(rowItems).sort().toString()
    }

    // If it's a new row, return true if the field is empty or in the comparison list
    // If it's an existing row, return true if the field is empty or in the comparison list
    // or if all fields in the row are equal to the original row
    return isNewRow ? emptyOrInList : isEqual || emptyOrInList
  })

  useDidUpdate(() => {
    setRowValues(initialRowValues)
    setEditable(false)
    return () => {
      setRowValues(initialRowValues)
      setEditable(false)
    }
  }, [rowItems])

  const editHandler = () => {
    setEditable(true)
  }
  const undoHandler = () => {
    setRowValues(initialRowValues)
    setEditable(false)
  }

  const tickHandler = () => {
    if (isNewRow) {
      SubmitModal({
        title: '',
        item: rowValues[tableConfig['queryKey']],
        onSubmit: () =>
          onAdd({
            body: {
              name: rowValues[tableConfig['queryKey']],
              type: rowValues?.type,
              active: rowValues?.active,
              types: rowValues?.types?.map((val) => ({ id: val })) || [],
            },
          }),
      })
      setIsNewRow(false)
    } else if (editable) {
      //Set the value state with a field of key, current value and new value
      const statusName = {
        true: 'Active',
        false: 'InActive',
      }

      //Handle Row values based on the headerKeys
      const newValueHandler = (item) => {
        if (item === 'types') {
          return mapIdToMultiSelectData(rowValues[item], brandList)
        }
        if (item === 'active') {
          return statusName[rowValues[item]]
        } else {
          return rowValues[item]
        }
      }
      const valueState = tableConfig['headerKeys'].map((item) => ({
        key: item,
        current: item === 'active' ? statusName[rowItems[item]] : rowItems[item],
        newValue: newValueHandler(item),
      }))

      EditModal({
        title: tableConfig['modalTitle'],
        value: valueState,
        onEditSubmit: () => {
          const edit = {
            id: rowValues.id,
            name: rowValues[tableConfig['queryKey']],
            type: rowValues?.type,
            active: rowValues?.active,
            types:
              rowValues?.types?.map((val) => {
                return val?.id ? { id: val.id } : { id: val }
              }) || null,
          }
          onEdit({
            body: { ...edit, name: rowValues[tableConfig['queryKey']] },
            type: rowValues?.type,
          })
          setEditable(false)
        },
      })
    }
  }

  const newRowIndex = 0

  const xActionHandler = () => {
    editable ? undoHandler() : setIsNewRow(false)
  }

  return (
    <tr
      className={Object.values(initialRowValues).includes('') ? classes.selectedRow : null}
      key={rowIndex}
    >
      <td>
        <Text
          size="sm"
          weight={500}
        >
          {String(isNewRow ? newRowIndex : rowIndex).padStart('2', '0')}
        </Text>
      </td>
      <ADRows
        initialRowValues={initialRowValues}
        rowValues={rowValues}
        isNewRow={isNewRow}
        editable={editable}
        tableConfig={tableConfig}
        setRowValues={setRowValues}
        form={form}
      />
      <td>
        <FMRowIcons
          disableTickButton={
            hasTypeInRowValues
              ? containsEmptyField || checkTypesArrayIsEmpty(rowValues)
              : containsEmptyField
          }
          xAction={xActionHandler}
          tickAction={tickHandler}
          editable={editable}
          isNewRow={isNewRow}
          editAction={editHandler}
          index={rowIndex}
        />
      </td>
    </tr>
  )
}

export default FMTableRows
