import { Fragment } from 'react'

import { Stack, Divider } from '@mantine/core'

import { ADTable, TextWithInfo, ADRows } from 'view/components'

import { PROPERTY_MAP } from 'util/tableMapping'

export const immoveableAssetForms = [
  {
    text: 'Asset - Property',
    tooltip: 'All types of property owned as of date of declaration.',
    type: 'property',
    tableMapping: PROPERTY_MAP,
  },
]
const ImmovableAssetPanel = ({ form, apiDropdownData }) => {
  return (
    <Stack>
      <Divider />
      {immoveableAssetForms.map(({ text, tooltip, type, tableMapping }, index) => {
        return (
          <Fragment key={type}>
            <TextWithInfo
              text={`${index + 1}. ${text}`}
              tooltip={tooltip}
            />
            <ADTable
              form={form}
              tableConfig={tableMapping}
              tableData={form.values[tableMapping['tableName']]}
              apiDropdownData={apiDropdownData}
              addLabel={`Add ${type}`}
            >
              <ADRows />
            </ADTable>
          </Fragment>
        )
      })}
    </Stack>
  )
}

export default ImmovableAssetPanel
