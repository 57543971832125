import { useMemo } from 'react'

import { Footer, Group, Button, Container, Text, Flex } from '@mantine/core'

import { SaveDraftButton } from 'view/components'

import { ChevronLeft, ChevronRight, RoundTickIcon, AlertDiamondIcon } from 'assets/svg'

const SaveDraft = ({
  enableDraft,
  draftOnClick,
  draftSaving,
  draftSaveError,
  draftSaveSuccess,
}) => {
  const getDraftSaveMessage = () => {
    if (draftSaveError)
      return { message: 'Save failed. Please try again.', icon: <AlertDiamondIcon /> }
    else if (enableDraft) return { message: '', icon: null }
    else if (draftSaveSuccess) return { message: 'All changes saved', icon: <RoundTickIcon /> }
    else return { message: '', icon: null }
  }

  const draftSaveMessage = useMemo(
    () => getDraftSaveMessage(),
    [draftSaveError, draftSaveSuccess, enableDraft]
  )

  return (
    <Group>
      <SaveDraftButton
        enableDraft={enableDraft}
        draftOnClick={draftOnClick}
        isSavingDraft={draftSaving}
      />
      <Flex gap={10}>
        <Text
          size="sm"
          weight={400}
          color={'#8A9DB2'}
        >
          {draftSaveMessage?.message}
        </Text>
        {draftSaveMessage?.icon}
      </Flex>
    </Group>
  )
}

const ADFooter = ({
  enableDraft,
  draftOnClick,
  draftSaving,
  draftSaveError,
  draftSaveSuccess,
  onNext,
  disableOnNext,
  onBack,
  activeStep,
  isAllow = false,
}) => {
  const handleDisableNext = () => {
    if (activeStep === 0) {
      return !disableOnNext
    } else if (activeStep === 2 && !isAllow) {
      return true
    } else {
      return false
    }
  }

  const stepButtonMap = {
    0: 'Start Declaration',
    1: 'Review Declaration',
    2: 'Submit Declaration',
    3: 'Submit Declaration',
  }
  const isMain = activeStep > 0
  return (
    <Footer height={80}>
      <Container
        size="xl"
        px="xs"
        sx={{ height: '100%' }}
      >
        <Group
          align="center"
          position={isMain ? 'apart' : 'right'}
          sx={{ height: 'inherit' }}
        >
          {isMain ? (
            <SaveDraft
              enableDraft={enableDraft}
              draftOnClick={draftOnClick}
              draftSaving={draftSaving}
              draftSaveError={draftSaveError}
              draftSaveSuccess={draftSaveSuccess}
            />
          ) : null}

          <Group>
            {isMain ? (
              <Button
                disabled={activeStep <= 0}
                variant="default"
                onClick={onBack}
                leftIcon={<ChevronLeft />}
                aria-label="back-button"
              >
                Back
              </Button>
            ) : null}

            <Button
              onClick={onNext}
              rightIcon={<ChevronRight />}
              disabled={handleDisableNext()}
              aria-label="next-button"
            >
              {stepButtonMap[activeStep]}
            </Button>
          </Group>
        </Group>
      </Container>
    </Footer>
  )
}

export default ADFooter
