import { Stack, Paper, Text, Group, Skeleton } from '@mantine/core'

const ALSubmissionStats = ({ data, isLoading }) => {
  return (
    <Paper
      radius={4}
      p={24}
      withBorder
    >
      <Stack>
        <Text
          size={16}
          fw={600}
        >
          Submitted Declaration
        </Text>
        <Skeleton visible={isLoading}>
          <Text
            size={32}
            fw={600}
          >
            {data?.totalPercentage?.toFixed(2)}% of total submission
          </Text>
          <Group>
            <Text
              size={14}
              fw={500}
              color="#0B882F"
            >
              {data?.newSubmissionCount} new submissions
            </Text>
            <Text
              size={14}
              fw={400}
              color="#475467"
            >
              Last 24 hours
            </Text>
          </Group>
        </Skeleton>
      </Stack>
    </Paper>
  )
}

export default ALSubmissionStats
