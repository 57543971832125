import { useCallback } from 'react'

import { Table, Group, Text, ScrollArea, Progress, Flex, Anchor, Box } from '@mantine/core'

import { useStyle } from 'view/style'

const BreakdownTable = ({ tableData, tableHeader, onRowClick, isCompanyBreakdown }) => {
  const { classes } = useStyle()

  const handleClick = useCallback(
    (orgName) => {
      //Only apply onclick on businesses label
      if (!isCompanyBreakdown) onRowClick(orgName)
    },
    [isCompanyBreakdown, onRowClick]
  )

  return (
    <Box className={classes.tableContainer}>
      <ScrollArea
        type="always"
        sx={{ height: tableData?.length > 5 && 310 }}
      >
        <Table
          highlightOnHover
          horizontalSpacing="lg"
          verticalSpacing="md"
        >
          <thead className={classes.tableHeader}>
            <tr>
              {tableHeader.map(({ name, align }) => (
                <th
                  key={name}
                  style={{ textAlign: align }}
                >
                  {name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={classes.tableBody}>
            {tableData?.map((item, index) => {
              //This will decide which field we will use
              let orgName = isCompanyBreakdown ? item.companyName : item.businessUnit
              let completionPercentage = (
                (item.submissionCount / item.totalEmployee) *
                100
              ).toFixed(0)

              return (
                <tr key={orgName}>
                  <td>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                  <td>
                    {!isCompanyBreakdown ? (
                      <Anchor
                        component="button"
                        type="button"
                        onClick={() => handleClick(orgName)}
                      >
                        {orgName}
                      </Anchor>
                    ) : (
                      orgName
                    )}
                  </td>
                  <td>
                    <Flex
                      gap="sm"
                      justify="flex-end"
                      align="center"
                      direction="row"
                    >
                      <Progress
                        sx={{ flex: 1, backgroundColor: '#DFF1F1' }}
                        color="#00A19C"
                        value={completionPercentage}
                      />
                      <Text>{`${completionPercentage > 0 ? completionPercentage : 0}%`}</Text>
                    </Flex>
                  </td>
                  <td
                    className={classes.alignTextRight}
                  >{`${item.submissionCount}/${item.totalEmployee}`}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ScrollArea>
      <Group className={classes.tableFooter} />
    </Box>
  )
}

export default BreakdownTable
