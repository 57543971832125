import { ActionIcon, Group, Text, Tooltip } from '@mantine/core'

import { InfoIcon } from 'assets/svg'

const TextWithInfo = ({ text, tooltip }) => {
  return (
    <Group>
      <Text
        size={16}
        weight={500}
      >
        {text}
      </Text>
      {tooltip ? (
        <Tooltip
          withArrow
          multiline
          width={300}
          label={tooltip}
          position="right"
        >
          <ActionIcon variant="transparent">
            <InfoIcon />
          </ActionIcon>
        </Tooltip>
      ) : null}
    </Group>
  )
}

export default TextWithInfo
