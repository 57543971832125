import { useGetIdentityQuery } from 'store/api'
import { useGetMyPhotoQuery } from 'store/msGraphApi'

import { useEffect, useCallback, useMemo, memo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Text, Menu, UnstyledButton, Group, Badge } from '@mantine/core'

import { AvatarIcon } from 'view/components'

import {
  LogoutIcon,
  NavigationIconAccessKey,
  NavigationIconDashboard,
  NavigationIconUserProfile,
} from 'assets/svg'

import { ADMIN_LINK, USER_LINK, SUPER_ADMIN_EMAILS } from 'util/helper'

const DropdownMenu = memo(
  ({
    isAuthenticated,
    msalAccountRoles,
    msalAccountEmail,
    setAdminButtons,
    handleAuth,
    breakpoint,
    adminButtons,
  }) => {
    const location = useLocation()

    const { data: photoData, refetch: refetchPhoto } = useGetMyPhotoQuery()
    const { data: profileData, refetch: refetchProfile } = useGetIdentityQuery()

    const AdminDashbordButton = useCallback(() => {
      if (location.pathname.includes('/admin')) {
        return (
          <Menu.Item
            aria-label="home-button"
            component={Link}
            to={'/assets'}
            key={'/assets'}
            p={20}
            icon={<NavigationIconUserProfile />}
          >
            Switch to User Mode
          </Menu.Item>
        )
      }

      if (
        msalAccountRoles.includes('ADMIN') ||
        (msalAccountRoles.includes('SUPERADMIN') && !location.pathname.includes('/admin'))
      ) {
        return (
          <Menu.Item
            aria-label="admin-button"
            component={Link}
            to={'/admin/dashboard'}
            key={'/admin/dashboard'}
            p={20}
            icon={<NavigationIconDashboard />}
            onClick={() => setAdminButtons(true)}
          >
            Switch to Admin Mode
          </Menu.Item>
        )
      }
    }, [location.pathname, msalAccountRoles, setAdminButtons])

    useEffect(() => {
      if (isAuthenticated) {
        refetchPhoto()
        refetchProfile()
      }
    }, [isAuthenticated])

    const linkRenderer = useMemo(() => (adminButtons ? ADMIN_LINK : USER_LINK), [adminButtons])

    return (
      <>
        {/* Navigation Menu */}
        <Menu
          shadow="md"
          width={350}
          position="bottom-end"
          withArrow
          withinPortal
          styles={{
            dropdown: { padding: ' 0 !important', border: 'none !important' },
            arrow: { backgroundColor: 'rgba(89, 41, 123, 1)', border: 'none' },
            itemIcon: { marginRight: 16 },
          }}
        >
          <Menu.Target>
            <UnstyledButton aria-label="navbar-menu">
              <AvatarIcon
                profileData={profileData}
                photoData={photoData}
              />
            </UnstyledButton>
          </Menu.Target>

          {/* If user is authenticated render dropdown with logout */}
          <Menu.Dropdown>
            <Menu.Label
              p={20}
              sx={{
                borderRadius: '3px 3px 0 0',
                color: 'rgba(255, 255, 255, 1)',
                backgroundColor: 'rgba(89, 41, 123, 1)',
              }}
            >
              <Text
                size={16}
                weight={500}
              >
                {profileData?.fullName}
              </Text>
              <Text size="xs">{profileData?.email}</Text>
              <Group
                spacing={5}
                mt="1rem"
              >
                {profileData?.humanReadableRoleNames?.map((item) => {
                  return (
                    <Badge
                      sx={{ color: 'white' }}
                      variant="filled"
                      key={item}
                      size="sm"
                    >
                      {item}
                    </Badge>
                  )
                })}
              </Group>
            </Menu.Label>
            <AdminDashbordButton />
            {SUPER_ADMIN_EMAILS.includes(msalAccountEmail?.toLowerCase().replace('.my', '')) && (
              <Menu.Item
                component="a"
                href="https://myaccess.microsoft.com/"
                target="_blank"
                aria-label="request-access-button"
                p={20}
                icon={<NavigationIconAccessKey />}
              >
                Request Super Admin Access
              </Menu.Item>
            )}
            {!breakpoint
              ? linkRenderer.map(({ href, title, icon }) => {
                  return (
                    <Menu.Item
                      key={title}
                      icon={icon}
                      p={20}
                      component={Link}
                      to={href}
                    >
                      {title}
                    </Menu.Item>
                  )
                })
              : null}
            <Menu.Divider m={0} />
            <Menu.Item
              sx={{ color: '#DA1E28 ' }}
              p={20}
              my={5}
              onClick={handleAuth}
              color="red"
              aria-label="logout-button"
              icon={<LogoutIcon />}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </>
    )
  }
)

export default DropdownMenu
