import { values } from 'lodash'
import { setRenderHeader } from 'store/assetDeclareSlice'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Text, Tooltip } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'

import { useStyle } from 'view/style'

import { MANDATORY_INPUT_RULES, EXCLUDE_FIELD } from 'util/helper'

import ADRenderIcon from './ADRenderIcon'

const ADRow = ({
  form,
  rowIndex,
  rowItems,
  tableConfig,
  apiDropdownData,
  children,
  isNewRow = false,
  setIsNewRow = () => {},
  tableBeingEdited = false,
  setTableBeingEdited = () => {},
  excludedDropdownKeys = [],
}) => {
  const { classes } = useStyle()
  const dispatch = useDispatch()
  const initialRowValues = rowItems
  const [editable, setEditable] = useState(false)
  const [rowValues, setRowValues] = useState(initialRowValues)

  const [mandatory, setMandatory] = useState(false)

  useDidUpdate(() => {
    if (JSON.stringify(rowItems) !== JSON.stringify(rowValues)) {
      setRowValues(rowItems)
    }
  }, [rowItems])

  // fn to check total value >= 15000 for other movable assets
  const checkMinAmount = (rowValues) => {
    let lesserThanMinVal = false
    if (tableConfig['tableName'] === 'otherMovableAssets')
      Object.keys(rowValues).forEach((key) => {
        if (
          key === 'totalValue' &&
          rowValues[key] < tableConfig['fields']['totalValue']['minimumValue']
        )
          lesserThanMinVal = true
      })
    return lesserThanMinVal
  }
  const containsEmptyFields = mandatory
    ? values(rowValues).some((x) => {
        return MANDATORY_INPUT_RULES.includes(x)
      })
    : values(
        Object.fromEntries(
          Object.entries(rowValues).filter(
            ([key]) =>
              !EXCLUDE_FIELD.includes(key) &&
              !(excludedDropdownKeys.includes(key) && rowValues[key] === 0)
          )
        )
      ).some((x) => {
        return MANDATORY_INPUT_RULES.includes(x)
      })

  const editHandler = () => {
    setEditable(true)
    setTableBeingEdited(true)
  }
  const undoHandler = () => {
    setRowValues(initialRowValues)
    setEditable(false)
    setTableBeingEdited(false)
  }

  const deleteRowHandler = () => {
    if (isNewRow) {
      setIsNewRow(false)
    } else {
      form?.removeListItem(`${tableConfig['tableName']}`, rowIndex)
      dispatch(setRenderHeader(true))
      form?.setDirty({ [tableConfig['tableName']]: true })
    }
  }

  const tickHandler = () => {
    if (isNewRow) {
      form.insertListItem(`${tableConfig['tableName']}`, rowValues)
      setIsNewRow(false)
    } else if (editable) {
      form?.setFieldValue(`${tableConfig['tableName']}.${rowIndex}`, rowValues)
      setEditable(false)
      setTableBeingEdited(false)
    }
    dispatch(setRenderHeader(true))
    form?.setDirty({ [tableConfig['tableName']]: true })
  }

  return (
    <tr
      className={isNewRow || editable ? classes.selectedRow : null}
      key={rowIndex}
    >
      <td>
        <Text
          size="sm"
          weight={500}
        >
          {String(rowIndex + 1).padStart('2', '0')}
        </Text>
      </td>
      {React.cloneElement(children, {
        initialRowValues: initialRowValues,
        rowValues: rowValues,
        isNewRow: isNewRow,
        editable: editable,
        tableConfig: tableConfig,
        setMandatory: setMandatory,
        setRowValues: setRowValues,
        apiDropdownData: apiDropdownData,
        form: form,
      })}
      <td
        className={classes.stickyColumn}
        style={{ backgroundColor: isNewRow || editable ? '#eff8f8 ' : '#ffffff' }}
      >
        <Tooltip
          disabled={!tableBeingEdited || editable}
          label="Disabled when editing other rows"
        >
          <ADRenderIcon
            disabledMoreIcon={tableBeingEdited}
            disableTickButton={containsEmptyFields || checkMinAmount(rowValues)}
            xAction={editable ? undoHandler : deleteRowHandler}
            tickAction={tickHandler}
            editable={editable}
            isNewRow={isNewRow}
            tableName={tableConfig['tableName']}
            editAction={editHandler}
            deleteAction={deleteRowHandler}
            index={rowIndex}
          />
        </Tooltip>
      </td>
    </tr>
  )
}

export default ADRow
