import { Suspense } from 'react'

import { Paper, Tabs, Text } from '@mantine/core'

import { CenterLoader } from 'view/components'

import DeclarationCycle from './DeclarationCycle'
import ExtendGroup from './ExtendGroup'
import FormManagement from './FormManagement'

const ManageForm = () => {
  const formArray = [
    { label: 'Form Management', value: 'formManagement' },
    { label: 'Declaration Cycle', value: 'declarationCycle' },
    { label: 'Extend Group', value: 'extendGroup' },
  ]
  return (
    <Paper mb={150}>
      <Tabs defaultValue="formManagement">
        <Tabs.List>
          {formArray.map((form) => {
            return (
              <Tabs.Tab
                key={form.value}
                value={form.value}
                px={20}
                py={16}
              >
                <Text fw={500}>{form.label}</Text>
              </Tabs.Tab>
            )
          })}
        </Tabs.List>
        <Tabs.Panel
          value="formManagement"
          pt={24}
        >
          {/* Will call loader function if FormManagement have not finished loading */}
          <Suspense fallback={<CenterLoader />}>
            <FormManagement />
          </Suspense>
        </Tabs.Panel>
        <Tabs.Panel
          value="declarationCycle"
          pt={24}
        >
          <DeclarationCycle />
        </Tabs.Panel>
        <Tabs.Panel
          value="extendGroup"
          pt={24}
        >
          <ExtendGroup />
        </Tabs.Panel>
      </Tabs>
    </Paper>
  )
}

export default ManageForm
