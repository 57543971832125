import { memo } from 'react'

import { AppShell, Container } from '@mantine/core'

import { FloatingHelpButton } from '..'
import Footer from './Footer'
import NavBar from './NavBar'

const PageLayout = memo(({ children }) => {
  return (
    <AppShell
      sx={{
        background: 'linear-gradient( to bottom,#00A19C 11em,#E6E8F0 11em 80%)',
      }}
      header={<NavBar />}
    >
      <Container
        size="xl"
        px="xs"
        pt="md"
        sx={{
          height: '100%',
          position: 'relative',
          margin: '0 auto',
        }}
      >
        {children}
        <Footer />
      </Container>
      <FloatingHelpButton />
    </AppShell>
  )
})

export default PageLayout
