import {
  useGetMDLoanInstitutionQuery,
  useCreateMDLoanInstitutionMutation,
  useUpdateMDLoanInstitutionMutation,
} from 'store/api'

import { FMLOANINSTITUTION_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMLoanInstitutionPanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDLoanInstitutionQuery({
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  })

  const [addLoanInstitution] = useCreateMDLoanInstitutionMutation()
  const [updateLoanInstitution] = useUpdateMDLoanInstitutionMutation()

  return (
    <FMPanelTemplate
      addButtonLabel={`Add Loan Institution`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMLOANINSTITUTION_MAP}
      apiAddHandler={addLoanInstitution}
      apiUpdateHandler={updateLoanInstitution}
    />
  )
}

export default FMLoanInstitutionPanel
