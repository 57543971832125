import { useLazyDownloadEmployeeDeclarationQuery } from 'store/api'
import { calculateTotalAmount, calculateTotalRows, setDOPreview } from 'store/assetDeclareSlice'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Group, Paper, Stack, Switch, Text, Tooltip } from '@mantine/core'
import { useSessionStorage } from '@mantine/hooks'

import { Review } from 'view/components'
import { useStyle } from 'view/style'

import { DownloadIcon } from 'assets/svg'

import { formatResponse } from 'util/helper'

import dayjs from 'dayjs'

import { EmployeeeDetails } from './ESEmployeeDeclaration'

export const DeclarationViewFirstHeading = ({
  showAmount,
  setShowAmount,
  submitted,
  id,
  revision,
  financialYear,
  name,
  email,
}) => {
  const [trigger, { isFetching }] = useLazyDownloadEmployeeDeclarationQuery()
  return (
    <Group position="apart">
      <EmployeeeDetails
        name={name}
        email={email}
      />
      <Group>
        <Switch
          label={'Show Amount'}
          checked={showAmount}
          onChange={(event) => setShowAmount(event.currentTarget.checked)}
          aria-label="switch"
        />
        {/* Download button */}
        {/* {submitted ? (
          <Button
            variant="default"
            leftIcon={<DownloadIcon />}
            onClick={() =>
              trigger({
                id: id,
                revision: revision,
                financialYear: financialYear,
                fullName: name,
              })
            }
            aria-label="download"
            loading={isFetching}
          >
            Download
          </Button>
        ) : (
          <Tooltip
            withinPortal
            withArrow
            label={'Download disabled for draft'}
          >
            <Button
              variant="default"
              leftIcon={<DownloadIcon />}
              onClick={(e) => e.preventDefault()}
              sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
              data-disabled
              aria-label="disabledDownload"
            >
              Download
            </Button>
          </Tooltip>
        )} */}
      </Group>
    </Group>
  )
}

const DeclarationViewSecondHeading = ({ financialYear, updatedAt, submitted }) => {
  const { classes } = useStyle()
  return (
    <Group
      position="apart"
      py={16}
      px={20}
    >
      <Text
        size={14}
        fw={400}
      >
        Asset Declaration {financialYear}
      </Text>
      <Group position="apart">
        <Text
          size={14}
          fw={400}
        >
          {dayjs(updatedAt).format('DD MMM YYYY')}
        </Text>
        <Text
          size={12}
          className={submitted ? classes.statusSubmit : classes.statusDraft}
        >
          {submitted ? 'Submitted' : 'Draft'}
        </Text>
      </Group>
    </Group>
  )
}

const ESEmployeeDeclarationView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showAmount, setShowAmount] = useState(false)
  const categoryDetails = useSelector((state) => state.assetDeclare)
  const { formData, revision, financialYear, submitted, id, updatedAt, staffId, department } =
    categoryDetails.tempPreview
  const [value] = useSessionStorage({
    key: 'employeeDeclarationEmail',
    defaultValue: { name: '', email: '' },
  })
  const identityData = {
    fullName: value.name,
    oid: staffId,
    email: value.email,
    department: department,
  }
  const formattedData = formatResponse(formData)

  useEffect(() => {
    // redirect to earlier page if user refreshes
    if (history.action === 'POP') {
      const sessionSaveAsset = sessionStorage.getItem('assetform')
      if (sessionSaveAsset) {
        const parsedAsset = JSON.parse(sessionSaveAsset)
        dispatch(calculateTotalAmount(formatResponse(parsedAsset.formData)))
        dispatch(calculateTotalRows(JSON.stringify(formatResponse(parsedAsset.formData))))
        dispatch(setDOPreview(parsedAsset))
      } else history.push('/admin/employee_declarations')
    }
  }, [])

  return (
    <Paper mb={150}>
      <Stack spacing={24}>
        <DeclarationViewFirstHeading
          showAmount={showAmount}
          setShowAmount={setShowAmount}
          submitted={submitted}
          id={id}
          revision={revision}
          financialYear={financialYear}
          name={value.name}
          email={value.email}
        />
        <DeclarationViewSecondHeading
          financialYear={financialYear}
          updatedAt={updatedAt}
          submitted={submitted}
        />
        <Paper withBorder>
          <Review
            form={{ values: formattedData }}
            categoryDetails={categoryDetails}
            assetRevisionData={revision}
            isSubmitted={submitted}
            financialYear={financialYear}
            showAmount={showAmount}
            identityData={identityData}
            date={updatedAt}
          />
        </Paper>
      </Stack>
    </Paper>
  )
}

export default ESEmployeeDeclarationView
