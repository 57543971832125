import {
  useGetAssetsRevisionQuery,
  useGetChartRevisionDataQuery,
  useGetDeclarationReminderQuery,
} from 'store/api'

import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Paper, Group, Text, Title, Stack, Grid, Center, Button } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'

import { CenterLoader, CustomYearDropdown, DynamicEmptyData } from 'view/components'
import DeclarationReminderBanner from 'view/components/DeclarationReminderBanner'

import { useFormatChartData } from 'util/hooks'

import DOBarChart from './DOBarChart'
import DOPieChart from './DOPieChart'
import DOTable from './DOTable'

const DeclarationOverview = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

  const { data: tableData, isLoading } = useGetAssetsRevisionQuery()
  const { data: chartData, refetch } = useGetChartRevisionDataQuery({ year: currentYear })
  const { data: bannerData } = useGetDeclarationReminderQuery()

  useDidUpdate(() => {
    refetch()
  }, [currentYear])

  const { t } = useTranslation(['common'])
  const { pieData, barData } = useFormatChartData(chartData || null)

  //Sort table data based on financial year
  const filteredTableData = useMemo(() => {
    return tableData?.filter((e) => e.financialYear === currentYear)
  }, [currentYear, tableData])

  if (isLoading) return <CenterLoader />
  return (
    <Paper
      p={25}
      mb={90}
    >
      {bannerData?.showBanner ? (
        <DeclarationReminderBanner
          bannerData={bannerData}
          withCloseButton={false}
          withChevronRight={true}
          chevronRightText="Declare"
        />
      ) : null}
      {tableData?.length ? (
        <>
          <Title order={3}>Declaration Overview</Title>
          <Group
            position="apart"
            mb={40}
          >
            <Text
              weight={400}
            >{`Your current assets summary on ${currentYear} declaration year.`}</Text>
            <Group>
              <Text weight={400}>Declaration year :</Text>
              <CustomYearDropdown onChange={setCurrentYear} />
            </Group>
          </Group>

          <Stack spacing={40}>
            <DynamicEmptyData
              message={'No data for particular date'}
              hasData={chartData}
            >
              <Grid>
                <Grid.Col
                  lg={!barData ? 12 : 8}
                  sm={12}
                >
                  <DOPieChart pieData={pieData} />
                </Grid.Col>
                {barData ? (
                  <Grid.Col
                    lg={4}
                    sm={12}
                  >
                    <DOBarChart barData={barData} />
                  </Grid.Col>
                ) : null}
              </Grid>
            </DynamicEmptyData>
            <Title order={3}>Declaration History</Title>
            <DOTable tableData={filteredTableData || []} />
          </Stack>
        </>
      ) : (
        <Center sx={{ height: '70vh' }}>
          <Stack align="center">
            <DynamicEmptyData
              message={t('Data will be shown here once you have submitted your declaration.')}
            />
            <Button
              component={Link}
              to="/assets"
            >
              {t('Start Declaration')}
            </Button>
          </Stack>
        </Center>
      )}
    </Paper>
  )
}

export default DeclarationOverview
