import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

// This will return the entire path by using the useLocation hook
// and it will re-render every time the url is changed because of the useEffect.

const useGetCurrentPath = () => {
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState()

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])
  return currentPath || null
}

export default useGetCurrentPath
