import {
  useGetMDPropertyTypeQuery,
  useCreateMDPropertyTypeMutation,
  useUpdateMDPropertyTypeMutation,
} from 'store/api'

import { FMPROPERTYTYPE_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMPropertyTypePanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDPropertyTypeQuery({
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  })

  const [addPropertyType] = useCreateMDPropertyTypeMutation()
  const [updatePropertyType] = useUpdateMDPropertyTypeMutation()

  return (
    <FMPanelTemplate
      addButtonLabel={`Add Property Type`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMPROPERTYTYPE_MAP}
      apiAddHandler={addPropertyType}
      apiUpdateHandler={updatePropertyType}
    />
  )
}

export default FMPropertyTypePanel
