import { useMemo } from 'react'

import { Table, Group, Box, Text, ScrollArea, Center } from '@mantine/core'

import { useStyle } from 'view/style'

import dayjs from 'dayjs'

import DOIcon from './DOIcon'

const DOTable = ({ tableData }) => {
  const { classes, cx } = useStyle()
  const latestRevisionNum = tableData.filter((val) => val.submitted === true)[0]?.revision || null

  const tableHeader = [
    { name: 'No', align: 'right' },
    { name: 'Revision', align: 'right' },
    { name: 'Total Items Declared', align: 'right' },
    { name: 'Total Value (MYR)', align: 'right' },
    { name: 'Last Updated', align: 'right' },
    { name: 'Status', align: 'left' },
  ]

  const renderRows = useMemo(() => {
    return tableData.map((row, key) => {
      const { totalAmount, totalNumberOfAssets } = row.formData

      return (
        <tr key={row.revision}>
          <td className={classes.alignTextRight}>{key + 1 < 10 ? `0${key + 1}` : key + 1}</td>
          <td className={classes.alignTextRight}>R.{row.revision}</td>
          <td className={classes.alignTextRight}>{totalNumberOfAssets}</td>
          <td className={classes.alignTextRight}>
            {totalAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </td>
          <td className={classes.alignTextRight}>{dayjs(row.updatedAt).format('DD MMM YYYY')}</td>
          <td>
            <Text className={row.submitted ? classes.statusSubmit : classes.statusDraft}>
              {row.submitted ? 'Submitted' : 'Draft'}
            </Text>
          </td>
          <td
            className={classes.stickyColumn}
            style={{ backgroundColor: '#ffffff' }}
          >
            <DOIcon
              data={row}
              latestRevisionNum={latestRevisionNum}
            />
          </td>
        </tr>
      )
    })
  }, [tableData])

  return (
    <Box className={classes.tableContainer}>
      <ScrollArea
        type="always"
        sx={{ height: tableData?.length > 5 && 310, borderRadius: 'inherit' }}
      >
        <Table
          className={classes.tableSize}
          horizontalSpacing="sm"
          verticalSpacing="sm"
        >
          <thead className={cx(classes.tableHeader)}>
            <tr>
              {tableHeader.map(({ name, align }) => {
                return (
                  <th
                    key={name}
                    style={{ textAlign: align }}
                  >
                    {name}
                  </th>
                )
              })}
              <th
                className={classes.stickyColumn}
                style={{ backgroundColor: '#f4f6fa' }}
              ></th>
            </tr>
          </thead>
          <tbody className={classes.tableBody}>
            {tableData?.length ? (
              renderRows
            ) : (
              <td colSpan={7}>
                <Center py={20}>
                  <Text>No data for particular date</Text>
                </Center>
              </td>
            )}
          </tbody>
        </Table>
      </ScrollArea>

      <Group className={classes.tableFooter} />
    </Box>
  )
}

export default DOTable
