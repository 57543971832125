import {
  BarChart,
  Tooltip,
  Legend,
  Bar,
  YAxis,
  XAxis,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'

import { useState } from 'react'

import { Text, Box } from '@mantine/core'

import DOCustomTick from './DOCustomTick'
import DOCustomTooltip from './DOCustomTooltip'

const LegendFormatter = ({ title }) => {
  return <span style={{ color: 'rgb(102, 102, 102)', marginLeft: 10 }}>{title}</span>
}

const DOBarChart = ({ barData }) => {
  const [isHover, setIsHover] = useState()

  return (
    <Box sx={{ border: '1px solid #D3DEE8', borderRadius: 4, padding: 20, height: '100%' }}>
      <Text
        size={20}
        weight={700}
      >
        Other Source of Income
      </Text>
      <ResponsiveContainer height={460}>
        <BarChart
          data={barData}
          margin={{
            top: 20,
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            tickLine={false}
            interval={0}
            dataKey="name"
            height={60}
            tick={<DOCustomTick />}
          />

          <YAxis
            tickCount={3}
            tickLine={false}
            axisLine={false}
            allowDecimals={false}
          />
          <Tooltip
            content={
              <DOCustomTooltip
                type={'bar'}
                isHover={isHover}
              />
            }
          />
          <Legend
            verticalAlign="bottom"
            iconSize={8}
            payload={[{ value: `PETRONAS' Contractor`, type: 'circle', color: '#9466FF' }]}
            formatter={(value) => LegendFormatter({ title: value })}
          />
          <Bar
            dataKey="business"
            stackId="business"
            fill="#00A19C"
            barSize={40}
            onMouseOver={() => setIsHover('business')}
          />
          <Bar
            dataKey="petAssociated"
            stackId="business"
            onMouseOver={() => setIsHover('petAssociated')}
            fill="#9466FF"
            barSize={40}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default DOBarChart
