import { createSlice } from '@reduxjs/toolkit'

import { reduceArrayOfObject, formatResponse } from 'util/helper'
import {
  BCASH_MAP,
  LOAN_MAP,
  VEHICLE_MAP,
  PROPERTY_MAP,
  OWNER_MAP,
  DIRECTOR_MAP,
  INVEST_MAP,
  RETIRE_MAP,
  OTHER_MOVABLE_MAP,
} from 'util/tableMapping'

const initialState = {
  isScrolledBottom: false,
  common: { reRender: false },
  bankCash: { amount: 0, isDisabled: false, rows: 0 },
  vehicle: { amount: 0, isDisabled: false, rows: 0 },
  investment: { amount: 0, isDisabled: false, rows: 0 },
  retirement: { amount: 0, isDisabled: false, rows: 0 },
  otherMovable: { amount: 0, isDisabled: false, rows: 0 },
  property: { amount: 0, isDisabled: false, rows: 0 },
  loan: { amount: 0, isDisabled: false, rows: 0 },
  owner: { amount: 0, isDisabled: false, rows: 0 },
  director: { amount: 0, isDisabled: false, rows: 0 },
  tempPreview: {},
  stepperStep: 0,
  isUnauthorized: false,
}

const STATE_MAPPING = [
  { stateName: 'bankCash', tableName: BCASH_MAP['tableName'] },
  { stateName: 'loan', tableName: LOAN_MAP['tableName'] },
  { stateName: 'vehicle', tableName: VEHICLE_MAP['tableName'] },
  { stateName: 'property', tableName: PROPERTY_MAP['tableName'] },
  { stateName: 'retirement', tableName: RETIRE_MAP['tableName'] },
  { stateName: 'investment', tableName: INVEST_MAP['tableName'] },
  { stateName: 'owner', tableName: OWNER_MAP['tableName'] },
  { stateName: 'director', tableName: DIRECTOR_MAP['tableName'] },
  { stateName: 'otherMovable', tableName: OTHER_MOVABLE_MAP['tableName'] },
]

export const assetDeclareSlice = createSlice({
  name: 'assetDeclare',
  initialState,
  reducers: {
    setRenderHeader: (state, action) => {
      state.common.reRender = action.payload
    },
    calculateTotalAmount: (state, action) => {
      const bankCashAmount = action.payload[BCASH_MAP['tableName']].reduce(
        (a, b) => a + (b[BCASH_MAP['totalAmountField']] || 0),
        0
      )
      const otherMovableAmount = action.payload[OTHER_MOVABLE_MAP['tableName']].reduce(
        (a, b) => a + (b[OTHER_MOVABLE_MAP['totalAmountField']] || 0),
        0
      )
      const vehicleAmount = action.payload[VEHICLE_MAP['tableName']].reduce(
        (a, b) => a + (b[VEHICLE_MAP['totalAmountField']] || 0),
        0
      )
      const retirementAmount = action.payload[RETIRE_MAP['tableName']].reduce(
        (a, b) => a + (b[RETIRE_MAP['totalAmountField']] || 0),
        0
      )
      const investmentAmount = action.payload[INVEST_MAP['tableName']].reduce(
        (a, b) => a + (b[INVEST_MAP['totalAmountField']] || 0),
        0
      )
      const loanAmount = action.payload[LOAN_MAP['tableName']].reduce(
        (a, b) => a + (b[LOAN_MAP['totalAmountField']] || 0),
        0
      )
      const propertyAmount = action.payload[PROPERTY_MAP['tableName']].reduce(
        (a, b) => a + (b[PROPERTY_MAP['totalAmountField']] || 0),
        0
      )
      const companyDirectorAmount = action.payload[DIRECTOR_MAP['tableName']].reduce(
        (a, b) => a + (b[DIRECTOR_MAP['totalAmountField']] || 0),
        0
      )
      state.bankCash.amount = bankCashAmount
      state.otherMovable.amount = otherMovableAmount
      state.loan.amount = loanAmount
      state.vehicle.amount = vehicleAmount
      state.property.amount = propertyAmount
      state.investment.amount = investmentAmount
      state.retirement.amount = retirementAmount
      state.director.amount = companyDirectorAmount
    },
    calcBCashAmountRows: (state, action) => {
      state.bankCash.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        BCASH_MAP['totalAmountField']
      )
      state.bankCash.rows = JSON.parse(action.payload).length
    },
    calcOtherMovableRows: (state, action) => {
      state.otherMovable.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        OTHER_MOVABLE_MAP['totalAmountField']
      )
      state.otherMovable.rows = JSON.parse(action.payload).length
    },
    calcLoanAmountRows: (state, action) => {
      state.loan.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        LOAN_MAP['totalAmountField']
      )
      state.loan.rows = JSON.parse(action.payload).length
    },
    calcVehicleAmountRows: (state, action) => {
      state.vehicle.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        VEHICLE_MAP['totalAmountField']
      )
      state.vehicle.rows = JSON.parse(action.payload).length
    },
    calcPropertyAmountRows: (state, action) => {
      state.property.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        PROPERTY_MAP['totalAmountField']
      )
      state.property.rows = JSON.parse(action.payload).length
    },
    calcInvestmentAmountRows: (state, action) => {
      state.investment.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        INVEST_MAP['totalAmountField']
      )
      state.investment.rows = JSON.parse(action.payload).length
    },
    calcRetirementAmountRows: (state, action) => {
      state.retirement.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        RETIRE_MAP['totalAmountField']
      )
      state.retirement.rows = JSON.parse(action.payload).length
    },
    calcOwnerRows: (state, action) => {
      state.owner.rows = JSON.parse(action.payload).length
    },
    calcDirectorRows: (state, action) => {
      state.director.amount = reduceArrayOfObject(
        JSON.parse(action.payload),
        DIRECTOR_MAP['totalAmountField']
      )
      state.director.rows = JSON.parse(action.payload).length
    },
    calculateTotalRows: (state, action) => {
      //This method will calculate number of rows
      const parsePayload = JSON.parse(action.payload)

      STATE_MAPPING.forEach(({ stateName, tableName }) => {
        state[stateName].rows = parsePayload[tableName].length
      })
    },

    setDisableStatus: (state, action) => {
      //This method will check if we should disable render for specific category
      const parsePayload = JSON.parse(action.payload)

      STATE_MAPPING.forEach(({ stateName, tableName }) => {
        state[stateName].isDisabled = !parsePayload[tableName].length
      })
    },

    setResetDisableStatus: (state) => {
      //This method will reset the state
      STATE_MAPPING.forEach(({ stateName }) => {
        state[stateName] = { amount: 0, isDisabled: false, rows: 0 }
      })
    },

    setDOPreview: (state, action) => {
      if (action.payload) {
        const formatted = formatResponse(action.payload.formData)

        const {
          bankCashes,
          companiesAsDirector,
          companiesAsOwner,
          investments,
          loans,
          otherMovableAssets,
          properties,
          retirementFunds,
          vehicles,
        } = formatted

        //Set temp state for preview
        state.tempPreview = { ...action.payload }
        //Check if item exist before render
        state.bankCash.isDisabled = !bankCashes.length
        state.loan.isDisabled = !loans.length
        state.vehicle.isDisabled = !vehicles.length
        state.property.isDisabled = !properties.length
        state.retirement.isDisabled = !retirementFunds.length
        state.investment.isDisabled = !investments.length
        state.owner.isDisabled = !companiesAsOwner.length
        state.director.isDisabled = !companiesAsDirector.length
        state.otherMovable.isDisabled = !otherMovableAssets.length
      } else {
        state.tempPreview = {}
      }
    },

    setScrolledBottom: (state, action) => {
      state.isScrolledBottom = action.payload
    },
    setStepperStep: (state, action) => {
      state.stepperStep = action.payload
    },
    setIsUnauthorized: (state, action) => {
      state.isUnauthorized = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  calcBCashAmountRows,
  calcInvestmentAmountRows,
  calcOtherMovableRows,
  calcLoanAmountRows,
  calcPropertyAmountRows,
  calcRetirementAmountRows,
  calcVehicleAmountRows,
  calculateTotalAmount,
  calculateTotalRows,
  setRenderHeader,
  setDisableStatus,
  setDOPreview,
  calcOwnerRows,
  calcDirectorRows,
  setScrolledBottom,
  setStepperStep,
  setResetDisableStatus,
  setIsUnauthorized,
} = assetDeclareSlice.actions

export default assetDeclareSlice.reducer
