import { useState } from 'react'

import { Accordion, Box } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { AccordianHeader, ChevronRightButton } from 'view/components'
import {
  LearnFinancialModal,
  LearnImmoveableModal,
  LearnMoveableModal,
  LearnOtherIncomeModal,
} from 'view/components/Modal'

import { LearnMoreIcon } from 'assets/svg'

import { useStyle } from '../../style'
import {
  FinancialCommitmentPanel,
  ImmovableAssetPanel,
  MovableAssetPanel,
  OtherSourcePanel,
} from './AccordianItem/Panels'

const ADAccordian = ({ form, apiDropdownData, categoryDetails }) => {
  const [accordianState, setAccordianState] = useState([
    'Movable Asset',
    'Immovable Asset',
    'Financial Commitment',
    'Other Source of Income',
  ])
  const [
    learnMoveableModalIsOpen,
    { close: closeLearnMoveableModal, open: openLearnMoveableModal },
  ] = useDisclosure(false)
  const [
    learnImmoveableModalIsOpen,
    { close: closeLearnImmoveableModal, open: openLearnImmoveableModal },
  ] = useDisclosure(false)
  const [
    learnFinancialModalIsOpen,
    { close: closeLearnFinancialModal, open: openLearnFinancialModal },
  ] = useDisclosure(false)
  const [
    learnOtherIncomeModalIsOpen,
    { close: closeLearnOtherIncomeModal, open: openLearnOtherIncomeModal },
  ] = useDisclosure(false)
  const { classes } = useStyle()
  const {
    bankCash,
    loan,
    vehicle,
    property,
    owner,
    director,
    investment,
    retirement,
    otherMovable,
  } = categoryDetails
  const panelType = [
    {
      title: 'Movable Asset',
      component: (
        <MovableAssetPanel
          form={form}
          apiDropdownData={apiDropdownData}
        />
      ),
      value: bankCash.rows + vehicle.rows + investment.rows + retirement.rows + otherMovable.rows,

      totalAmount:
        bankCash.amount +
        vehicle.amount +
        investment.amount +
        retirement.amount +
        otherMovable.amount,
      showAmount: true,
      openLearnModal: openLearnMoveableModal,
    },
    {
      title: 'Immovable Asset',
      component: (
        <ImmovableAssetPanel
          form={form}
          apiDropdownData={apiDropdownData}
        />
      ),
      value: property.rows,
      totalAmount: property.amount,
      showAmount: true,
      openLearnModal: openLearnImmoveableModal,
    },
    {
      title: 'Financial Commitment',
      component: (
        <FinancialCommitmentPanel
          form={form}
          apiDropdownData={apiDropdownData}
        />
      ),
      value: loan.rows,
      totalAmount: loan.amount,
      showAmount: true,
      openLearnModal: openLearnFinancialModal,
    },
    {
      title: 'Other Source of Income',
      component: (
        <OtherSourcePanel
          form={form}
          apiDropdownData={apiDropdownData}
        />
      ),
      value: owner.rows + director.rows,
      totalAmount: director.amount,
      showAmount: true,
      openLearnModal: openLearnOtherIncomeModal,
    },
  ]
  return (
    <>
      <Accordion
        variant="separated"
        chevronPosition="left"
        chevronSize={40}
        multiple
        value={accordianState}
        onChange={setAccordianState}
      >
        {panelType.map((index) => {
          const { title, value, totalAmount, component, showAmount, openLearnModal } = index
          return (
            <Accordion.Item
              key={title}
              value={title}
              className={classes.accordianItem}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                <Accordion.Control>
                  <AccordianHeader
                    title={title}
                    count={value}
                    totalAmount={totalAmount}
                    showAmount={showAmount}
                  />
                </Accordion.Control>
                <LearnMoreIcon />
                <ChevronRightButton
                  text="Learn More"
                  size={100}
                  onClick={openLearnModal}
                  ml={5}
                />
              </Box>

              <Accordion.Panel>{component}</Accordion.Panel>
            </Accordion.Item>
          )
        })}
      </Accordion>
      {learnMoveableModalIsOpen ? (
        <LearnMoveableModal
          isOpen={learnMoveableModalIsOpen}
          handleClose={closeLearnMoveableModal}
        />
      ) : null}
      {learnImmoveableModalIsOpen ? (
        <LearnImmoveableModal
          isOpen={learnImmoveableModalIsOpen}
          handleClose={closeLearnImmoveableModal}
        />
      ) : null}
      {learnFinancialModalIsOpen ? (
        <LearnFinancialModal
          isOpen={learnFinancialModalIsOpen}
          handleClose={closeLearnFinancialModal}
        />
      ) : null}
      {learnOtherIncomeModalIsOpen ? (
        <LearnOtherIncomeModal
          isOpen={learnOtherIncomeModalIsOpen}
          handleClose={closeLearnOtherIncomeModal}
        />
      ) : null}
    </>
  )
}

export default ADAccordian
