import { useState, useEffect, useMemo } from 'react'

import { Popover, Input, Stack, Divider, Group, Button } from '@mantine/core'
import { RangeCalendar } from '@mantine/dates'
import { useDisclosure } from '@mantine/hooks'

import dayjs from 'dayjs'

const CustomDateRangePicker = ({
  handleSubmit,
  initialValue = [null, null],
  disabled = null,
  minDate = new Date(dayjs().add(1, 'day').format('YYYY-MM-DD')),
  maxDate = null,
  width = undefined,
  label = null,
  maxDays = 0,
  placeholder = 'Select date',
}) => {
  const [opened, handlers] = useDisclosure(false)
  const [inputValue, setInputValue] = useState(initialValue)

  useEffect(() => {
    if (!maxDays) cancelHandler()
  }, [initialValue])

  const isDateRangeExceed = useMemo(
    () =>
      maxDays > 0 ? dayjs(inputValue[1]).diff(dayjs(inputValue[0]), 'day') + 1 > maxDays : false,
    [inputValue]
  )

  const formatDate = (date) => dayjs(date).format('DD MMM YYYY')

  // Calculate the minimum and maximum selectable dates based on the selected start date and maxDays
  const dayRange = useMemo(() => {
    if (maxDays > 0 && inputValue[0] !== null) {
      return {
        min: new Date(dayjs(inputValue[0]).format('YYYY-MM-DD')),
        max: new Date(dayjs(inputValue[0]).add(6, 'days').format('YYYY-MM-DD')),
      }
    } else {
      return { min: minDate, max: maxDate }
    }
  }, [inputValue])

  const handleRangeChange = (value) => {
    setInputValue(value)
  }

  const cancelHandler = () => {
    setInputValue(initialValue)
    handlers.close()
  }

  const submitHandler = () => {
    handleSubmit(inputValue)
    handlers.close()
  }
  return (
    <Popover
      opened={opened}
      position="bottom-start"
      withinPortal
      shadow="md"
      disabled={disabled}
    >
      <Popover.Target>
        <Input.Wrapper label={label}>
          <Input
            placeholder={placeholder}
            value={`${initialValue[0] ? formatDate(initialValue[0]) + ' - ' : ''}${
              initialValue[1] ? formatDate(initialValue[1]) : ''
            }`}
            readOnly
            disabled={disabled}
            onClickCapture={() => handlers.open()}
            aria-label="dateRangePickerInput"
            w={width}
          />
        </Input.Wrapper>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <RangeCalendar
            allowSingleDateInRange={maxDays}
            value={inputValue}
            onChange={handleRangeChange}
            allowLevelChange={false}
            amountOfMonths={2}
            initialMonth={initialValue[0] || new Date()}
            minDate={dayRange['min']}
            maxDate={dayRange['max']}
            size="xs"
            styles={() => ({
              day: {
                '&[data-in-range]': { backgroundColor: '#DFF1F1' },
                '&[data-selected]': { backgroundColor: '#007a72' },
              },
            })}
          />
          <Divider />
          <Group position={'right'}>
            {maxDays && (
              <Button
                mr={8}
                disabled={inputValue[1] === null}
                variant="default"
                onClick={() => {
                  setInputValue([null, null])
                  handleSubmit([null, null])
                }}
              >
                Clear
              </Button>
            )}

            <Button
              mr={8}
              variant="default"
              onClick={cancelHandler}
              aria-label="dateRangePickerCancel"
            >
              Cancel
            </Button>
            <Button
              onClick={submitHandler}
              disabled={inputValue.includes(null) || isDateRangeExceed}
              styles={{
                root: {
                  '&[data-disabled]': {
                    backgroundColor: '#AFDDDC',
                    color: '#FFFFFF',
                  },
                },
              }}
              aria-label="dateRangePickerSubmit"
            >
              Select Date
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default CustomDateRangePicker
