import { PublicClientApplication } from '@azure/msal-browser'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/`,
    validateAuthority: true,
    redirectUri: getRedirectURI(),
    postLogoutRedirectUri: getRedirectURI(),
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
}

export const msalInstance = new PublicClientApplication(msalConfig)

export function getMsalAccountRole(instance) {
  return instance.getActiveAccount()?.idTokenClaims?.roles?.length > 0
    ? instance.getActiveAccount().idTokenClaims.roles
    : []
}

export function getMsalAccountEmail(instance) {
  return instance.getActiveAccount()?.idTokenClaims?.email
}

export function getRedirectURI() {
  const currentURL = window.location.href
  let redirectURI

  if (currentURL.includes('localhost')) {
    redirectURI = process.env.REACT_APP_REDIRECT_URI_LOCAL
  } else if (currentURL.includes('dev')) {
    redirectURI = 'https://dev.myasset.petronas.com'
  }else if (currentURL.includes('stagmy')) {
    redirectURI = 'https://stagmy.myasset.petronas.com'
  } else if (currentURL.includes('stag')) {
    redirectURI = 'https://stag.myasset.petronas.com'
  } else {
    redirectURI = 'https://myasset.petronas.com'
  }
  return redirectURI
}
