// REUSED TEXT OR > 5 WORDS
export const LEGAL_TEXT = `This submission is made pursuant to the requirement under PETRONAS Code of Conduct and Business Ethics (CoBE) Country Supplement: Malaysia, Part II: Duties of Good Faith Fidelity, Diligence and Integrity“`

export const LEGEAL_CHECKBOX_TEXT = [
  `I hereby confirm that the information provided by me herein are true and accurate. `,
]

export const EMPTY_DECLARATION_HEADER_TEXT = `There are sections which you have not filled. Please ensure that all sections are filled OR if you do not have anything to declare for them, tick their respective boxes below.`

export const ASSET_DECLARATION_CONTACT_NOTE = `Note: If your Malaysia-based bank/non-bank is not in the list, please contact assetdeclaration@petronas.com to request for it to be added into the list.`

export const ASSET_DECLARATION_TEXT = [
  'The following vehicles are to be declared under ',
  "'Other Movable Assets' ",
  'if valued at MYR 15,000 and above each.',
]

export const MOVABLE_MODAL_TO_DECLARE = [
  'Car (including but not limited to SUV, MPV and van)',
  'Lorry/Pickup',
  'Motorcycle',
  'Boat/Houseboat',
  'Bus',
]

export const MOVABLE_MODAL_NOT_TO_DECLARE = [
  'Bicycle / Electric bicycle',
  'Skates (skateboards or roller skates)',
  'Scooter',
  'Canoes',
  'Segway',
  'Pedal boats',
  'Jet ski',
  'Hoverboard/ Water hoverboard',
]

export const MOVABLE_MODAL_INVESTMENT = [
  {
    investmentType: 'Stocks',
    remarks:
      'Stocks represent ownership in a company and are also known as shares or equity. They typically come with ownership rights, such as voting rights, such as voting rights and potential dividends.',
  },
  {
    investmentType: 'Bonds',
    remarks: 'Bonds are debt securities issued by governments or corporations to raise capital.',
  },
  {
    investmentType: 'Mutual Fund',
    remarks:
      'Mutual funds are pooled investments that invest in diversified portfolio of stocks, bonds, unit trust funds, or other securities, managed by a professional fund manager.',
  },
  {
    investmentType: 'Real Estate Investment Trust (REITs)',
    remarks:
      'REITs are securities that invest in income-producing real estate properties and distribute the income generated to shareholders in the form of dividends.',
  },
  {
    investmentType: 'Rights and Warrants',
    remarks:
      'Rights and warrants are securities that grant the holder the option to buy or sell a security at a specific price within a certain timeframe.',
  },
  {
    investmentType: 'Commodities',
    remarks:
      'Investment in assets such as gold, silver, oil or agricultural products. For precious metal, unit to be defined in gram.',
  },
  {
    investmentType: 'Cryptocurrencies',
    remarks:
      'Investment in digital or virtual currency secured by cryptography system e.g., Bitcoin, Ethereum, XRP and Tether.',
  },
  {
    investmentType: 'Exchange-Traded Funds (ETFs)',
    remarks:
      'Exchange-Traded Funds (ETFs) are investment funds that are traded on stock exchanges like individual stocks.',
  },
  {
    investmentType: 'Investment Capital',
    remarks:
      'Investment capital refers to the financial resources or funds resides in trading/digital investment account that individuals allocate for the purpose of making investments. E.g., Rakuten, RHB Trade, Wise, etc.',
  },
]

export const OTHER_MOVABLE_MODAL_TO_DECLARE = [
  'Jewelleries',
  'Watches',
  'Painting',
  'Sports equipment',
  'Foreign currencies',
  'Handbags',
]

export const OTHER_MOVABLE_MODAL_NOT_TO_DECLARE = ['Furniture', 'Pets']

export const MOVABLE_MODAL_OTHERS =
  'Staff has a book collection that consists of 4 books with a total value of MYR25,000. However only one of the books is valued at MYR15,000 while the rest are less than MYR5,000. \n\n Staff is only required to declare the book that is valued MYR15,000.'

export const MOVABLE_MODAL_NOTE = [
  'Your declaration will be based on purchase value for asset that you had purchased either solely and/or jointly with others. \n\n If you received an asset as a gift, inheritance, or through winnings, you could estimate its value based on the market value of similar assets available in the market.',
  '<strong>"Acquisition year"</strong> refers to the year in which an asset was obtained or acquired by an employee. It is the year in which the asset was originally purchased, received as a gift, winnings, inherited, or otherwise came into the possession of the employee.',
  'If your Malaysia-based bank is not listed in the myAsset platform, kindly contact <strong>assetdeclaration@petronas.com</strong> to request for it to be added by the system administrator.',
]

export const IMMOVABLE_MODAL_TO_DECLARE = [
  'Residential',
  'Commercial',
  'Agricultural',
  'Industrial',
]

export const IMMOVABLE_MODAL_NOT_TO_DECLARE = ['Solar Panels', 'Machinery', 'Instruments']

export const IMMOVABLE_MODAL_NOTE = [
  'Your declaration will be based on purchase value for asset that you had purchased either solely and/or jointly with others. \n\n If you received an asset as a gift, inheritance, or through winnings, you could estimate its value based on the market value of similar assets available in the market.',
  '<strong>"Acquisition year"</strong> refers to the year in which an asset was obtained or acquired by an employee. It is the year in which the asset was originally purchased, received as a gift, winnings, inherited, or otherwise came into the possession of the employee.',
  'Please declare the value of any land used for farming or agricultural activities under the immovable assets category.',
  'Any income gained from immovable asset (e.g. rental, lease, agricultural activities, royalty, machinery or copyright) should be declared under the <strong>Cash Savings</strong> category, classified as movable asset.',
  'Employees must declare their immovable assets, including those purchased through financing facilities. The property is declared as an immovable asset, while the corresponding loan or financial liability to be declared under the Financial Commitment section.',
]

export const FINANCIAL_MODAL_NOT_TO_DECLARE = [
  'Alimony payment to family/ex-family members.',
  'Buy Now, Pay Later (BNPL) commitment.',
  'Credit card commitment.',
  'Kootu funds.',
]

export const FINANCIAL_MODAL_NOTE =
  'Personal Loan encompasses various type of loan/ financing, including but not limited to Personal Loan, Wedding loan, Vacation Loan, Home Renovation, Education Loan, Furniture and Appliance Loans.'

export const OTHER_SOURCE_OF_INCOME_OWNERSHIP = [
  {
    contentTitle: 'Name of Business',
    children: 'Registered name as per Companies Commission of Malaysia',
  },
  {
    contentTitle: "PETRONAS' Contractor",
    children:
      'Refers to a company or organization that is contracted by PETRONAS, to provide products or services in support of its operations.',
  },
  {
    contentTitle: 'Business registration number',
    children: 'Registration number as per Companies Commission of Malaysia.',
  },
  {
    contentTitle: 'Total shares owned',
    children: 'Refers to the number of shares in a company that an employee owns in MYR',
  },
  {
    contentTitle: 'Percentage of shares owned',
    children: "Refers to the proportion of Business' total ownership",
  },
  {
    contentTitle: 'Acquisition Year',
    children: 'Refers to the year in which an employee acquired ownership of business',
  },
]

export const OTHER_SOURCE_OF_INCOME_DIRECTORSHIP = [
  {
    contentTitle: 'Name of Company',
    children: 'Registered name as per Companies Commission of Malaysia',
  },
  {
    contentTitle: "PETRONAS' Contractor",
    children:
      'Refers to a company or organization that is contracted by PETRONAS, to provide products or services in support of its operations.',
  },
  {
    contentTitle: 'Business registration number',
    children: 'Registration number as per Companies Commission of Malaysia.',
  },
  {
    contentTitle: 'Annual Salary (MYR) (if any)',
    children:
      'The salary and/or compensation received by an employee in MYR (Malaysian Ringgit) per annum in exchange for serving as a director on the board of a company. This compensation may include a base salary, retainer fee, meeting fees, bonuses, and other forms of compensation.',
  },
  {
    contentTitle: 'Year of appointment',
    children: 'Year appointed as director',
  },
]

export const OTHER_SOURCE_OF_INCOME_NOTE = [
  'Employee to ensure prior approval under Declaration of Activity (IR02) has been obtained before submitting declaration under Other Source of Income.',
]

export const EMPTY_DECLARATION_TEXT = {
  bankCash: `I don't have any cash savings in bank/non-bank to declare.`,
  vehicle: `I don’t have any vehicles to declare.`,
  investment: `I don't have any investment in shares, bonds & other forms of securities to declare.`,
  retirement: `I don't have any savings/investments in retirement funds to declare.`,
  otherMovable: `I don’t have any other movable asset to declare.`,
  property: `I don't have any property to declare.`,
  loan: `I don't have any loans & financings (personal, hire purchase & property) to declare.`,
  owner: `I don't have any business ownership to declare.`,
  director: `I don't have any directorship to declare.`,
}

export const DRAFT_INFO_TEXT = `Your declaration is currently in draft mode. Please review it before submitting.`

export const NEW_REVISION_TEXT = `For record keeping purposes, we are required to keep all previously submitted asset declaration separate. Any changes whether correction or editing new information will generate a new and latest version of your asset declaration. You are allowed to submit multiple latest version to keep your information updated.`

export const DOWNLOAD_NOTE =
  'By downloading the declaration, I assume all responsibility and risk of having my confidential information stored in my device or in physical (printed) format.'

export const TRUST_SAFETY = `myAsset employs industry-standard security framework to ensure your data is collected and stored safely. Rest assured that your data is collected strictly in compliance with PETRONAS Code of Conduct and Business Ethics and Personal Data Protection Act 2010.`

export const TRUST_SAFETY_SECURITY_COMMITMENT =
  'myAsset is committed to the security of users’ and their data. The portal which undergoes periodic penetration testing, is designed to be General Data Protection Regulation (GDPR) compliant, and users’ data is always encrypted.'
