import {
  useGetMDVehicleTypeQuery,
  useCreateMDVehicleTypeMutation,
  useUpdateMDVehicleTypeMutation,
} from 'store/api'

import { FMVEHICLETYPE_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMVehicleTypePanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDVehicleTypeQuery({
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  })

  const [addVehicleTypeName] = useCreateMDVehicleTypeMutation()
  const [updateVehicleTypeName] = useUpdateMDVehicleTypeMutation()
  return (
    <FMPanelTemplate
      addButtonLabel={`Add Vehicle Type`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMVEHICLETYPE_MAP}
      apiAddHandler={addVehicleTypeName}
      apiUpdateHandler={updateVehicleTypeName}
    />
  )
}

export default FMVehicleTypePanel
