import { useState } from 'react'

import { findOthersInDropdown, getDropdownDataHandler, getPlaceholderLabel } from 'util/helper'

import ADInputText from './ADInputText'

const ADRows = ({
  rowValues,
  isNewRow,
  editable,
  tableConfig,
  setRowValues,
  apiDropdownData,
  form,
}) => {
  const [placeholder, setPlaceholder] = useState(null)
  const createableId = 0
  const onChangeHandler = (key, value, canCreate, dropdownLabel = null) => {
    // creatable add name key
    if (canCreate) {
      setRowValues((prevState) => {
        if (typeof value === 'string')
          return {
            ...prevState,
            [key]: createableId,
            [key.replace('Id', 'Name')]: value,
          }
        else
          return {
            ...prevState,
            [key]: value,
            [key.replace('Id', 'Name')]: dropdownLabel,
          }
      })
    } else
      setRowValues((prevState) => {
        return { ...prevState, [key]: value }
      })

    setPlaceholder(getPlaceholderLabel(apiDropdownData, tableConfig.tableName, value, key))
  }

  const renderPlaceholder = ['purchasedValue', 'value', 'totalValue']

  return (
    <>
      {tableConfig['headerKeys']?.map((item) => {
        const dropdownData = getDropdownDataHandler({
          source: tableConfig['fields'][item]['dropdownSource'],
          item: item,
          formValue: form.values[tableConfig['tableName']],
          apiDropdownData,
          tableConfig,
        })
        const getCreatableDropdownValue =
          findOthersInDropdown(dropdownData.initial) && rowValues[item] === createableId
            ? rowValues[item.replace('Id', 'Name')]
            : rowValues[item]
        return (
          <td
            key={item}
            style={{ maxWidth: tableConfig['fields'][item].maxWidth, wordWrap: 'break-word' }}
          >
            <ADInputText
              placeholder={renderPlaceholder.includes(item) && placeholder}
              enableEdit={isNewRow || editable}
              value={getCreatableDropdownValue}
              inputKey={item}
              onChange={onChangeHandler}
              inputConfig={tableConfig['fields'][item]}
              dropdownData={dropdownData}
            />
          </td>
        )
      })}
    </>
  )
}

export default ADRows
