import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import history from '../config/history'
import { leafApi } from './api'
import assetDeclareReducer from './assetDeclareSlice'
import { msGraphApi } from './msGraphApi'

export default function rootReducer() {
  return combineReducers({
    router: connectRouter(history),
    assetDeclare: assetDeclareReducer,
    [leafApi.reducerPath]: leafApi.reducer,
    [msGraphApi.reducerPath]: msGraphApi.reducer,
  })
}
