import { useState } from 'react'

import { Button, Group, Stack, Text, Checkbox } from '@mantine/core'

import { EMPTY_DECLARATION_HEADER_TEXT, EMPTY_DECLARATION_TEXT } from 'util/textMapping'

import CustomModalContainer from './CustomModalContainer'

const CheckBoxLabel = ({ category, isShown, setCheckedHandler, isChecked }) => {
  return isShown ? (
    <Checkbox
      aria-label={`${category}-checkbox`}
      size="sm"
      label={EMPTY_DECLARATION_TEXT[category]}
      styles={{
        input: { cursor: 'pointer' },
        label: { paddingLeft: 0, cursor: 'pointer' },
        labelWrapper: { marginLeft: 10 },
      }}
      onChange={(event) => {
        setCheckedHandler(category, event.currentTarget.checked)
      }}
      checked={isChecked[category]}
    />
  ) : null
}

export default function ReviewDeclarationModal({ isOpen, onClose, onSubmit, categoryDetails }) {
  const {
    bankCash,
    vehicle,
    investment,
    retirement,
    otherMovable,
    loan,
    property,
    owner,
    director,
  } = categoryDetails
  const [isChecked, setChecked] = useState({
    bankCash: !!bankCash.rows,
    vehicle: !!vehicle.rows,
    investment: !!investment.rows,
    retirement: !!retirement.rows,
    otherMovable: !!otherMovable.rows,
    loan: !!loan.rows,
    property: !!property.rows,
    owner: !!owner.rows,
    director: !!director.rows,
  })

  const setCheckedHandler = (category, value) => {
    setChecked((prevState) => {
      return { ...prevState, [category]: value }
    })
  }

  return (
    <CustomModalContainer
      title="Review Declaration"
      opened={isOpen}
      onClose={onClose}
      size="35rem"
    >
      <Stack>
        <Text size={14}>{EMPTY_DECLARATION_HEADER_TEXT}</Text>
        <CheckBoxLabel
          category="bankCash"
          isShown={!bankCash.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="vehicle"
          isShown={!vehicle.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="investment"
          isShown={!investment.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="retirement"
          isShown={!retirement.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="otherMovable"
          isShown={!otherMovable.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="property"
          isShown={!property.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="loan"
          isShown={!loan.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="owner"
          isShown={!owner.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
        <CheckBoxLabel
          category="director"
          isShown={!director.rows}
          setCheckedHandler={setCheckedHandler}
          isChecked={isChecked}
        />
      </Stack>

      <Group
        py={10}
        position="right"
      >
        <Button
          variant="default"
          onClick={onClose}
          aria-label="review-cancel"
        >
          Back
        </Button>
        <Button
          onClick={onSubmit}
          aria-label="review-submit"
          disabled={Object.values(isChecked).includes(false)}
        >
          Agree
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
