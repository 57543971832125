import { useHistory } from 'react-router-dom'

import { Button, Group } from '@mantine/core'

import { storeCurrentPath } from 'util/localRedirectUrlStorage'

import CustomModalContainer from './CustomModalContainer'

export default function ReloginModal({ isOpen, handleClose }) {
  const history = useHistory()
  const reloginHandler = async () => {
    storeCurrentPath()
    history.push('/')
    await handleClose()
  }
  return (
    <CustomModalContainer
      title="Your session has timed out"
      opened={isOpen}
      onClose={reloginHandler}
      size={800}
      modalPadding={20}
      hideCloseButton={true}
    >
      <Group
        pt={20}
        position="center"
      >
        <Button
          variant="default"
          onClick={reloginHandler}
          aria-label="closeLearnFinancial"
        >
          Relogin
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
