import { Stack, UnstyledButton, Checkbox } from '@mantine/core'

const CustomCheckbox = ({ year, value, onChange }) => {
  return (
    <UnstyledButton
      onClick={() => onChange(year)}
      sx={{
        border: `1px solid ${value ? '#00847C' : '#D3DEE8'}`,
        borderRadius: '4px',
        backgroundColor: `${value ? '#DFF1F1' : null}`,
      }}
    >
      <Stack
        p={20}
        spacing={5}
      >
        <Checkbox
          checked={value}
          label={year}
          radius="xl"
          onChange={() => {}}
          styles={{ input: { cursor: 'pointer' } }}
        />
      </Stack>
    </UnstyledButton>
  )
}

export default CustomCheckbox
