import Big from 'big.js'
import { isNil } from 'lodash'

import { keys } from '@mantine/utils'

import {
  NavigationIconDashboard,
  NavigationIconDeclarationOverview,
  NavigationIconEmployeeSearch,
  NavigationIconForm,
} from 'assets/svg'

import {
  initialStateAssetDeclare,
  BCASH_MAP,
  LOAN_MAP,
  VEHICLE_MAP,
  OTHER_MOVABLE_MAP,
  PROPERTY_MAP,
  INVEST_MAP,
  RETIRE_MAP,
} from 'util/tableMapping'

export const DELEGATE_LIST = {
  myasset: [
    {
      label: 'hakim.ismail@petronas.com.my - (General Manager Industrial Relations)',
      value: 'hakim.ismail@petronas.com',
    },
    {
      label: 'akmalniza_ahmad@petronas.com.my - (Head of Human Capital Expertise)',
      value: 'akmalniza_ahmad@petronas.com',
    },
  ],
  localhost: [
    {
      label: 'leaf.user12@pethlab.com - (General Manager Industrial Relations)',
      value: 'leaf.user12@pethlab.com',
    },
    {
      label: 'leaf.user6@pethlab.com - (Head of Human Capital Expertise)',
      value: 'leaf.user6@pethlab.com',
    },
  ],
  stag: [
    {
      label: 'leaf.user32@stag.petronas.com - (General Manager Industrial Relations)',
      value: 'leaf.user32@stag.petronas.com',
    },
    {
      label: 'leaf.user3@stag.petronas.com - (Head of Human Capital Expertise)',
      value: 'leaf.user3@stag.petronas.com',
    },
  ],
  dev: [
    {
      label: 'leaf.user12@pethlab.com - (General Manager Industrial Relations)',
      value: 'leaf.user12@pethlab.com',
    },
    {
      label: 'leaf.user6@pethlab.com - (Head of Human Capital Expertise)',
      value: 'leaf.user6@pethlab.com',
    },
  ],
}

export const FORM_MANAGEMENT_TABS = [
  {
    title: 'Movable Asset',
    tabs: [
      { value: 'institutionName', name: 'Institution Name' },
      { value: 'vehicleBrand', name: 'Vehicle Brand' },
      { value: 'vehicleType', name: 'Vehicle Type' },
      { value: 'investmentType', name: 'Investment Type' },
      { value: 'retirementFund', name: 'Retirement Fund' },
      { value: 'otherMoveable', name: 'Other Movable Asset' },
    ],
  },
  {
    title: 'Immovable Asset',
    tabs: [
      { value: 'propertyType', name: 'Property Type' },
      { value: 'propertyLocation', name: 'Property Location' },
    ],
  },
  {
    title: 'Financial Commitment',
    tabs: [
      { value: 'loanInstitutionName', name: 'Loan Institution Name' },
      { value: 'loanType', name: 'Loan Type' },
    ],
  },
  { title: 'Common', tabs: [{ value: 'assetAcquisitionName', name: 'Asset Acquisition Type' }] },
]

export const ADMIN_LINK = [
  {
    href: '/admin/dashboard',
    title: 'Dashboard',
    itemKey: 'dashboard',
    icon: <NavigationIconDashboard />,
  },
  {
    href: '/admin/manage_form',
    title: 'Manage Form',
    itemKey: 'manage_form',
    icon: <NavigationIconForm />,
  },
  {
    href: '/admin/employee_search',
    title: 'Employee Search',
    itemKey: 'employee_search',
    icon: <NavigationIconEmployeeSearch />,
  },
]

export const USER_LINK = [
  {
    href: '/declaration-overview',
    title: 'Declaration Overview',
    itemKey: 'declaration-overview',
    icon: <NavigationIconDeclarationOverview />,
  },
]

export const SUPER_ADMIN_EMAILS = [
  'leaf.user5@pethlab.com',
  'leaf.user3@pethlab.com',
  'leaf.user11@pethlab.com',
  'leaf.user12@pethlab.com',
  'nawaf@petronas.com',
  'leaf.user3@stag.petronas.com',
  'leaf.user12@stag.petronas.com',
  'leaf.user32@stag.petronas.com',
  'ruslanhalim.islahud@petronas.com',
]

export const EXCLUDE_FIELD = [
  'unit',
  'monthlyRepayment',
  'salary',
  //Exclude below value as we didnt implement others yet
  'investmentTypeName',
  'propLocName',
  'otherMovableAssetTypeName',
  'retirementFundTypeName',
  'loanTypeName',
  'totalAmount',
  'totalNumberOfAssets',
]

export const MANDATORY_INPUT_RULES = ['', undefined, 0]

export const generateArrayOfYears = () => {
  var max = new Date().getFullYear()
  var min = max - 200
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export function filterData(data, search) {
  const query = search.toLowerCase().trim()
  return data.filter((item) => keys(data[0]).some((key) => item[key].toLowerCase().includes(query)))
}

// filterData with empty string validation
export function filterDataWithEmptyString(data, payload) {
  if (data) {
    const { search, searchArea } = payload
    const cleanData = data.map((obj) => obj)
    const mutatedData = data.map((obj) => obj)
    const query = search.toLowerCase().trim()
    const dataToReturn =
      query === ''
        ? cleanData
        : mutatedData.filter((obj) => obj[searchArea].toLowerCase().includes(query))
    return dataToReturn
  }
  return []
}

export function paginationSlice(data, activePage, limitVal = 10) {
  const limit = limitVal
  const startIndex = activePage * limit - limit
  const endIndex = startIndex + limit
  return data?.slice(startIndex, endIndex)
}

export function getPaginationFirstNumber(activePage) {
  if (activePage === 1) return 1
  else return (activePage - 1) * 10 + 1
}

export function getPaginationSecondNumber(activePage, tableData) {
  const totalPage = Math.ceil(tableData?.length / 10)
  if (tableData?.length < 10) return tableData?.length
  else if (activePage === 1) return 10
  else if (activePage === totalPage) return tableData?.length
  else return (activePage + 1) * 10 - 10
}

export function formatDropdown(response) {
  const DROPDOWN_API_DATA = response?.data?.content

  if (response.isError || !DROPDOWN_API_DATA) {
    return [{ value: '', label: 'Error: No data from api' }]
  } else {
    return DROPDOWN_API_DATA?.map((d) => {
      const { id, name, type, active, brands } = d

      if (type) {
        return { value: id, label: name, type, active }
      }
      if (brands) {
        return { value: id, label: name, active, brands }
      }
      return { value: id, label: name, active }
    })
  }
}

export function formatDropdownBody({
  bankType,
  investmentType,
  vehicleType,
  vehicleBrand,
  propertyLocation,
  propertyType,
  otherMovableType,
  acquisitionType,
  loanInstitution,
  loanType,
  retirementType,
}) {
  return {
    [BCASH_MAP['tableName']]: {
      [BCASH_MAP['dropdownApiKey']['bankType']]: formatDropdown(bankType),
    },
    [LOAN_MAP['tableName']]: {
      [LOAN_MAP['dropdownApiKey']['loanInstitution']]: formatDropdown(loanInstitution),
      [LOAN_MAP['dropdownApiKey']['loanType']]: formatDropdown(loanType),
    },
    [VEHICLE_MAP['tableName']]: {
      [VEHICLE_MAP['dropdownApiKey']['vehicleType']]: formatDropdown(vehicleType),
      [VEHICLE_MAP['dropdownApiKey']['vehicleBrand']]: formatDropdown(vehicleBrand),
      [VEHICLE_MAP['dropdownApiKey']['acquisitionType']]: formatDropdown(acquisitionType),
    },
    [OTHER_MOVABLE_MAP['tableName']]: {
      [OTHER_MOVABLE_MAP['dropdownApiKey']['acquisitionType']]: formatDropdown(acquisitionType),
      [OTHER_MOVABLE_MAP['dropdownApiKey']['otherMovableType']]: formatDropdown(otherMovableType),
    },
    [PROPERTY_MAP['tableName']]: {
      [PROPERTY_MAP['dropdownApiKey']['propertyType']]: formatDropdown(propertyType),
      [PROPERTY_MAP['dropdownApiKey']['propertyLocation']]: formatDropdown(propertyLocation),
      [PROPERTY_MAP['dropdownApiKey']['acquisitionType']]: formatDropdown(acquisitionType),
    },
    [INVEST_MAP['tableName']]: {
      [INVEST_MAP['dropdownApiKey']['investmentType']]: formatDropdown(investmentType),
    },
    [RETIRE_MAP['tableName']]: {
      [RETIRE_MAP['dropdownApiKey']['retirementType']]: formatDropdown(retirementType),
    },
  }
}

export function reduceArrayOfObject(array, reduceKey) {
  return array.reduce((a, b) => a + (b[reduceKey] || 0), 0)
}

export function getDropdownDataHandler({
  source,
  item,
  apiDropdownData,
  tableConfig,
  formValue,
  dependantKeyValue,
}) {
  let dropdownData = []
  let filteredValue = []

  switch (source) {
    case 'api':
      dropdownData = apiDropdownData[tableConfig['tableName']][item]
      break
    case 'local':
      dropdownData = tableConfig['fields'][item]['dropdownData']
      break
    default:
      dropdownData = []
  }

  // //Check if there is dependant value, currently only used for financial type
  // if (dependantKeyValue && typeof dependantKeyValue === 'string') {
  //   const institutionIdDropdown = [...apiDropdownData[tableConfig['tableName']][item]]
  //   dropdownData = institutionIdDropdown.filter(
  //     (data) => data.type === dependantKeyValue.replace('-f', ' F')
  //   )
  // }

  //Condition for vehicle brands dropdown
  if (item === 'vehicleBrandId') {
    const filterVehicleType = [...apiDropdownData[tableConfig['tableName']][item]]?.filter(
      (val) => val.value === dependantKeyValue
    )

    const getBrands = filterVehicleType[0]?.brands?.map(({ id, name, active }) => ({
      value: id,
      label: name,
      active,
    }))

    dropdownData = getBrands

    //Push others manually if dependantKey === 0. Which means its refer to other type
    if (dependantKeyValue === 0) {
      const combineBrandWithOther = { value: 0, label: 'Other', active: true }
      dropdownData = [combineBrandWithOther]
    }
  }

  //Condition if there is strict column define
  if (tableConfig.strictsColumn?.includes(item)) {
    const existVal = formValue.map((value) => value[item])
    filteredValue = dropdownData.filter((e) => !existVal.includes(e.value))
  } else {
    filteredValue = dropdownData
  }

  /**
   * * This code checks if the headerKeys array in the tableConfig object includes 'acquisitionTypeId'. If it does, it creates an order array and an orderDict object. The order array is used to define the order of the labels in the dropdownData object, and the orderDict object is used to map each label to its corresponding index in the order array. The filteredValue variable is then set to a sorted version of dropdownData based on the order defined by the orderDict object. Finally, if the tableName property of tableConfig is equal to 'vehicles', then any labels with a value of 'Shared Asset' are filtered out from filteredValue.
   */

  if (tableConfig['headerKeys'].includes('acquisitionTypeId')) {
    const order = ['Purchase', 'Inheritance', 'Shared Asset', 'Gift', 'Winnings']

    const orderDict = order.reduce((acc, item, index) => {
      acc[item] = index
      return acc
    }, {})

    filteredValue = dropdownData?.sort((a, b) => orderDict[a.label] - orderDict[b.label])
    if (['vehicles', 'otherMovableAssets'].includes(tableConfig.tableName)) {
      filteredValue = filteredValue?.filter((e) => e.label !== 'Shared Asset')
    }
  }

  return {
    initial: dropdownData,
    filtered: filteredValue,
  }
}

export function getPlaceholderLabel(apiDropdownData, tableName, value, key) {
  if (key.includes('acquisitionTypeId')) {
    const acquisitionTypeLabel = apiDropdownData[tableName]['acquisitionTypeId'].find(
      (item) => item.value === value
    ).label

    const placeholderLabels = {
      Purchase: 'Purchased Price',
      'Shared Asset': 'Market Value / Purchased Price',
    }

    return placeholderLabels[acquisitionTypeLabel] || 'Market Value'
  }
}

export function formatResponse(data) {
  if (!data) return []
  const { otherSourceOfIncome, movableAssets, immovableAsset, financialCommitments } = data
  const formattedObj = {
    ...initialStateAssetDeclare,
    ...otherSourceOfIncome,
    ...movableAssets,
    ...immovableAsset,
    ...financialCommitments,
  }
  return formattedObj
}

export function sendEmail(type) {
  const emails = { main: 'assetdeclaration', ict: 'ict.servicedesk' }

  const windowRef = window.open(`mailto:${emails[type]}@petronas.com`, '_blank')
  windowRef.focus()

  setTimeout(function () {
    if (!windowRef.document.hasFocus()) {
      windowRef.close()
    }
  }, 500)
}

export function stringifyDraftPayload(data) {
  const formData = JSON.stringify({
    movableAssets: {
      bankCashes: data.bankCashes,
      vehicles: data.vehicles,
      investments: data.investments,
      retirementFunds: data.retirementFunds,
      otherMovableAssets: data.otherMovableAssets,
    },
    immovableAsset: { properties: data.properties },
    financialCommitments: { loans: data.loans },
    otherSourceOfIncome: {
      companiesAsOwner: data.companiesAsOwner,
      companiesAsDirector: data.companiesAsDirector,
    },
  })

  return { formData }
}

export const filterOthersInDropdown = (array = []) => {
  if (Array.isArray(array))
    return array.filter((item) => {
      // filter out others and inactive status. If inactive is not defined - we keep it in the list
      return (
        !item.label.toLowerCase().includes('other') && (item.active === true || isNil(item.active))
      )
    })
  else return []
}

export const findOthersInDropdown = (array = []) => {
  if (Array.isArray(array))
    return !!array.find((item) => item.label.toLowerCase().includes('other') && item.active)
  else return false
}

export const multiSelectValueExtractor = (arr) => {
  if (arr?.length && typeof arr[0] === 'object') {
    return arr.map((val) => val.id)
  } else {
    return arr
  }
}

export const multiSelectDataFormatter = (payload) => {
  return payload?.map((val) => {
    const { id, name, active } = val
    return { value: id, label: name, active }
  })
}

export const mapIdToMultiSelectData = (id, data) => {
  return data?.filter((val) => id.includes(val.value))
}

// This function checks the length of the decimal part of a number.
// It takes a number as an argument and returns the length of its decimal part.
// If the length of the decimal part is more than 18, it returns 18.
// If the argument is not a number or if it doesn't have a decimal part, the function returns 0.

export const checkDecimalLength = (numVal) => {
  if (numVal && !isNaN(numVal)) {
    const value = new Big(numVal)
    const decimalPart = value.toFixed().split('.')[1]

    if (decimalPart) {
      return decimalPart?.length <= 15 ? decimalPart?.length : 15
    } else {
      return 0
    }
  }
  return 0
}

export const calculateAssetTotal = (formData) => {
  const totals = {
    bankCash: { amount: 0, rows: 0, isDisabled: true },
    vehicle: { amount: 0, rows: 0, isDisabled: true },
    investment: { amount: 0, rows: 0, isDisabled: true },
    retirement: { amount: 0, rows: 0, isDisabled: true },
    otherMovable: { amount: 0, rows: 0, isDisabled: true },
    property: { amount: 0, rows: 0, isDisabled: true },
    loan: { amount: 0, rows: 0, isDisabled: true },
    owner: { amount: 0, rows: 0, isDisabled: true },
    director: { amount: 0, rows: 0, isDisabled: true },
  }

  totals.bankCash.rows = formData.bankCashes.length
  totals.bankCash.amount = formData.bankCashes.reduce((acc, curr) => acc + curr.amount, 0)
  totals.bankCash.isDisabled = totals.bankCash.amount === 0

  totals.vehicle.rows = formData.vehicles.length
  totals.vehicle.amount = formData.vehicles.reduce((acc, curr) => acc + curr.totalAmount, 0)
  totals.vehicle.isDisabled = totals.vehicle.amount === 0

  totals.investment.rows = formData.investments.length
  totals.investment.amount = formData.investments.reduce((acc, curr) => acc + curr.totalAmount, 0)
  totals.investment.isDisabled = totals.investment.amount === 0

  totals.retirement.rows = formData.retirementFunds.length
  totals.retirement.amount = formData.retirementFunds.reduce(
    (acc, curr) => acc + curr.totalAmount,
    0
  )
  totals.retirement.isDisabled = totals.retirement.amount === 0

  totals.otherMovable.rows = formData.otherMovableAssets.length
  totals.otherMovable.amount = formData.otherMovableAssets.reduce(
    (acc, curr) => acc + curr.totalAmount,
    0
  )
  totals.otherMovable.isDisabled = totals.otherMovable.amount === 0

  totals.property.rows = formData.properties.length
  totals.property.amount = formData.properties.reduce((acc, curr) => acc + curr.totalAmount, 0)
  totals.property.isDisabled = totals.property.amount === 0

  totals.loan.rows = formData.loans.length
  totals.loan.amount = formData.loans.reduce((acc, curr) => acc + curr.totalAmount, 0)
  totals.loan.isDisabled = totals.loan.amount === 0

  totals.owner.rows = formData.companiesAsOwner.length
  totals.owner.amount = formData.companiesAsOwner.reduce((acc, curr) => acc + curr.totalAmount, 0)
  totals.owner.isDisabled = totals.owner.rows === 0

  totals.director.rows = formData.companiesAsDirector.length
  totals.director.amount = formData.companiesAsDirector.reduce(
    (acc, curr) => acc + curr.totalAmount,
    0
  )
  totals.director.isDisabled = totals.director.rows === 0

  return totals
}
