import { ActionIcon, Group } from '@mantine/core'

import { EditIcon, TickIcon, XIcon } from 'assets/svg'

const FMRowIcons = ({
  tickAction,
  xAction,
  editable,
  isNewRow,
  editAction,
  index,
  disableTickButton = false,
}) => {
  return isNewRow || editable ? (
    <Group position="right">
      <ActionIcon
        disabled={disableTickButton}
        onClick={() => tickAction()}
        aria-label={`tickAction${index}`}
      >
        <TickIcon disabled={disableTickButton} />
      </ActionIcon>
      <ActionIcon
        onClick={() => xAction()}
        aria-label={`xAction${index}`}
      >
        <XIcon />
      </ActionIcon>
    </Group>
  ) : (
    <Group position="right">
      <ActionIcon
        onClick={() => editAction()}
        aria-label={`edit${index}`}
      >
        <EditIcon />
      </ActionIcon>
    </Group>
  )
}

export default FMRowIcons
