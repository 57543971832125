import { Button, Group, Stack, Table, Text, Box, ScrollArea, List, Divider } from '@mantine/core'

import { ExchangeRateBanner } from 'view/components'
import { useStyle } from 'view/style'

import { FINANCIAL_MODAL_NOT_TO_DECLARE, FINANCIAL_MODAL_NOTE } from 'util/textMapping'

import CustomModalContainer from './CustomModalContainer'

export default function LearnFinancialModal({ isOpen, handleClose }) {
  const { classes } = useStyle()

  return (
    <CustomModalContainer
      title="Financial Commitment"
      opened={isOpen}
      onClose={handleClose}
      size={800}
      modalPadding={20}
    >
      <ScrollArea
        style={{ height: 660 }}
        offsetScrollbars
      >
        <ExchangeRateBanner />
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text
              size={14}
              fw={500}
            >
              Loans and Financings
            </Text>
            <Stack spacing={0}>
              <Text size={14}>
                Existing loans and financings such as Personal Loan, Hire Purchase, Home Loan taken
                from banks or non-banks (e.g. MARA, KOPETRO, etc)
              </Text>
            </Stack>
          </Stack>
          <Box className={classes.tableContainer}>
            <Table
              sx={{ th: { width: 100 } }}
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>To Declare</th>
                  <th>Not to Declare</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td>
                    <Text>All loans & financing with banks and non-banks.</Text>
                    <Text>E.g. banks, MARA, KOPETRO, AEON, etc.</Text>
                  </td>
                  <td>
                    <List
                      size={14}
                      withPadding
                    >
                      {FINANCIAL_MODAL_NOT_TO_DECLARE.map((text) => (
                        <List.Item key={text}>
                          <Text size={14}>{text}</Text>
                        </List.Item>
                      ))}
                    </List>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text
              size={14}
              fw={500}
            >
              For Loans and Financing with Institutions outside of Malaysia
            </Text>
            <Stack spacing={0}>
              <Text size={14}>
                {`Loans and financings abroad to be declared under "Bank/non-bank outside of Malaysia"
                in the Name of Institution dropdown list. This category is used to declare
                accumulated loans and financings held in foreign countries.`}
              </Text>
            </Stack>
          </Stack>
          <Box className={classes.tableContainer}>
            <Table
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>Name of Institution</th>
                  <th>Type of Loan</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td>Bank outside of Malaysia</td>
                  <td>
                    <Stack spacing={12}>
                      <Text>Home Loan - USD 50,000 (MYR 230,350) (E.g. JPMorgan Chase & Co.)</Text>
                      <Text>Personal loan - USD 10,000 (MYR 46,070) (E.g. BNP Paribas SA)</Text>
                    </Stack>
                  </td>
                  <td>MYR 276,420.00</td>
                </tr>
                <tr>
                  <td>Non-Bank outside of Malaysia</td>
                  <td>
                    <Stack spacing={12}>
                      <Text>Personal Loan - USD 10,000 (MYR 46,070) (E.g. BlackRock Inc.)</Text>
                      <Text>
                        Personal Loan - USD 15,000 (MYR 69,105) (E.g. Western Union Company)
                      </Text>
                    </Stack>
                  </td>
                  <td>MYR 115,175.00</td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Stack>
          <Text
            size={14}
            fs="italic"
          >
            Note:
            <br />
            <br />
            {FINANCIAL_MODAL_NOTE}
          </Text>
        </Stack>
      </ScrollArea>
      <Group
        pt={20}
        position="right"
      >
        <Button
          variant="default"
          onClick={handleClose}
          aria-label="closeLearnFinancial"
        >
          Close
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
