import {
  Stack,
  Text,
  Group,
  Button,
  Input,
  MultiSelect,
  Box,
  Avatar,
  ActionIcon,
} from '@mantine/core'

import { BlackCircleCloseIcon, ChevronDown } from 'assets/svg'

import dayjs from 'dayjs'

import CustomModalContainer from './CustomModalContainer'

const RedAsterisk = () => (
  <Text
    span
    color="red"
  >
    *
  </Text>
)
export const ValueBox = ({ label, onRemove }) => {
  const newLabel = label || 'Missing Name Value'
  const labelArr = newLabel.split(' ')
  return (
    <div>
      <Box
        sx={(theme) => ({
          display: 'flex',
          cursor: 'default',
          alignItems: 'center',
          background: '#EDF1F8',
          border: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]
          }`,
          borderRadius: 16,
          height: 32,
        })}
      >
        <Avatar
          size={32}
          radius="xl"
        >
          {labelArr[0][0]?.toUpperCase()}
          {labelArr[labelArr.length - 1][0]?.toUpperCase()}
        </Avatar>
        <Box sx={{ lineHeight: 1, fontSize: 12 }}>
          <Text
            color={'#343F4B'}
            size={13}
            w={135}
            truncate
          >
            {newLabel}
          </Text>
        </Box>
        <ActionIcon
          onClick={onRemove}
          variant="transparent"
        >
          <BlackCircleCloseIcon />
        </ActionIcon>
      </Box>
    </div>
  )
}

const ExtendGroupViewModal = ({ isOpen, handleClose, groupData }) => {
  const generateNamesArr = () => {
    const nameArr = groupData?.names.split(',') || []
    const emailArr = groupData?.targetValue.split(',') || []
    if (nameArr.length && emailArr.length)
      return nameArr.map((name, index) => {
        return { label: name, value: emailArr[index] }
      })
    else return []
  }
  return (
    <CustomModalContainer
      title="View Group Details"
      opened={isOpen}
      onClose={handleClose}
      size={600}
      modalPadding={'24px 24px 20px 24px'}
    >
      <Stack>
        <Stack spacing={4}>
          <Text size={12}>
            Declaration Year
            <RedAsterisk />
          </Text>
          <Input
            value={groupData?.financialYearConfigurationDto.year}
            w={322}
            readOnly
          />
        </Stack>
        <Stack spacing={4}>
          <Text size={12}>
            Group Name
            <RedAsterisk />
          </Text>
          <Input
            value={groupData?.groupName}
            w={322}
            readOnly
          />
        </Stack>
        <Stack spacing={4}>
          <Text size={12}>Description</Text>
          <Input
            value={groupData?.description}
            w={322}
            readOnly
          />
        </Stack>

        <Stack spacing={4}>
          <Text size={12}>
            Select start date and end date
            <RedAsterisk />
          </Text>
          <Input
            value={`${dayjs(groupData?.formSubmissionOpeningDate).format('DD MMM YYYY')} - ${dayjs(
              groupData?.formSubmissionClosingDate
            ).format('DD MMM YYYY')}`}
            w={322}
            readOnly
          />
        </Stack>

        <Stack spacing={4}>
          <Text size={12}>
            Reason of date extension
            <RedAsterisk />
          </Text>
          <Input
            value={groupData?.reason}
            w={552}
            readOnly
          />
        </Stack>
        <Stack spacing={4}>
          <Text size={12}>
            Find user to be included in this group
            <RedAsterisk />
          </Text>
          <MultiSelect
            data={generateNamesArr()}
            value={groupData?.targetValue.split(',') || []}
            valueComponent={ValueBox}
            rightSection={<ChevronDown />}
            w={552}
            aria-label="egMultiSelectUsers"
            readOnly
            styles={{ values: { maxHeight: 80, overflowY: 'auto' } }}
          />
        </Stack>
        <Group
          position="right"
          pt={16}
        >
          <Button
            variant="default"
            onClick={handleClose}
            aria-label="egCreateCloseButton"
          >
            Close
          </Button>
        </Group>
      </Stack>
    </CustomModalContainer>
  )
}

export default ExtendGroupViewModal
