import { showNotificationMessage } from 'config/notificationConfig'
import { useLazyGetStaffQuery } from 'store/api'

import { useEffect, useMemo, useState } from 'react'

import { Button, Group, MultiSelect, Select, Stack } from '@mantine/core'
import { getYearsRange } from '@mantine/dates'
import { useDebouncedValue, useInputState } from '@mantine/hooks'

import { CustomDateRangePicker } from 'view/components'
import { useStyle } from 'view/style'

import { ChevronDown } from 'assets/svg'

import { DELEGATE_LIST } from 'util/helper'
import { usePostRequest } from 'util/hooks'

import dayjs from 'dayjs'

const SEARCH_MINIMUM_CHAR = 3
const MAX_EMPLOYEES = 10

const ESDelegationManagement = () => {
  const { classes } = useStyle()
  const { response, status, isLoading, trigger, resetTrigger, postData } = usePostRequest()

  // State variables
  const [searchValue, setSearchValue] = useInputState('')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [activePeriod, setActivePeriod] = useState([null, null])
  const [selectedDelegate, setSelectedDelegate] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300)
  const [fetchStaffData, { data: staffApiData, isFetching }] = useLazyGetStaffQuery()

  // Get list of years
  const yearList = useMemo(
    () => getYearsRange({ from: 2023, to: dayjs().get('year') }).map((year) => year.toString()),
    []
  )

  // Fetch staff data when search value changes
  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue.length >= SEARCH_MINIMUM_CHAR)
      fetchStaffData({ searchName: debouncedSearchValue })
  }, [debouncedSearchValue])

  // Prepare data for multi select
  const multiSelectData = useMemo(() => {
    const staffListArr = staffApiData?.map((e) => e.value) || []
    if (searchValue.length <= 0) return [...selectedUsers]
    return [...staffListArr, ...selectedUsers]
  }, [staffApiData, searchValue, selectedUsers])

  // Handle form submission
  const handleOnSubmit = async () => {
    await postData('/delegate-access/create', {
      employeeList: selectedUsers,
      delegate: selectedDelegate,
      declarationYear: parseInt(selectedYear),
      startDate: dayjs(activePeriod[0]).format('YYYY-MM-DD'),
      endDate: dayjs(activePeriod[1]).format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    if (!isLoading && trigger) {
      if (status === 200) {
        showNotificationMessage(
          'Request For Delegation',
          'Your request for delegation has been submitted.',
          true
        )
        setSelectedUsers([])
        setSelectedDelegate(null)
        setSelectedYear(null)
        setActivePeriod([null, null])
      } else if (status === 404) {
        showNotificationMessage('Request For Delegation', response.violations[1].message, false)
      } else if (status === 400) {
        showNotificationMessage('Request For Delegation', response.violations[0].message, false)
      } else {
        showNotificationMessage(
          'Request For Delegation',
          'Failed to submit request for delegation. Please try again.',
          false
        )
      }
      resetTrigger()
    }
  }, [isLoading, trigger])

  const delegateList = useMemo(() => {
    return DELEGATE_LIST[window.location.hostname.split('.')[0]].filter(
      (item) => !selectedUsers.includes(item.value)
    )
  }, [selectedUsers])

  useMemo(() => {
    const isUserInList = selectedUsers.find((user) => user === selectedDelegate)
    if (selectedDelegate && isUserInList) {
      setSelectedDelegate(null)
    }
  }, [selectedUsers, selectedDelegate])

  return (
    <Stack
      pb={20}
      px={{ md: '280px' }}
    >
      <MultiSelect
        label={`Employee List (Up to ${MAX_EMPLOYEES} Employees)`}
        classNames={{
          itemsWrapper: classes.dropdownWrapper,
          rightSection: classes.dropdownRightSection,
          item: classes.dropdownitem,
        }}
        data={multiSelectData}
        onChange={setSelectedUsers}
        value={selectedUsers}
        onSearchChange={setSearchValue}
        searchValue={searchValue}
        searchable
        clearable
        maxSelectedValues={MAX_EMPLOYEES}
        nothingFound={isFetching ? 'Loading...' : 'Nothing found'}
        rightSection={<ChevronDown />}
      />
      <Select
        label="Declaration Year"
        classNames={{
          itemsWrapper: classes.dropdownWrapper,
          rightSection: classes.dropdownRightSection,
          item: classes.dropdownitem,
        }}
        value={selectedYear}
        onChange={setSelectedYear}
        data={yearList}
        rightSection={<ChevronDown />}
      />
      <Select
        label="Delegation Assignment"
        classNames={{
          itemsWrapper: classes.dropdownWrapper,
          rightSection: classes.dropdownRightSection,
          item: classes.dropdownitem,
        }}
        value={selectedDelegate}
        onChange={setSelectedDelegate}
        data={delegateList}
        rightSection={<ChevronDown />}
      />
      <CustomDateRangePicker
        placeholder={null}
        handleSubmit={setActivePeriod}
        initialValue={activePeriod}
        label="Active Period"
        minDate={new Date(dayjs().format('YYYY-MM-DD'))}
        maxDays={7}
      />
      <Group position="right">
        <Button
          mt={20}
          w={150}
          onClick={handleOnSubmit}
          disabled={
            selectedUsers.length <= 0 ||
            !selectedDelegate ||
            !selectedYear ||
            (!activePeriod[0] && !activePeriod[1])
          }
        >
          Submit
        </Button>
      </Group>
    </Stack>
  )
}

export default ESDelegationManagement
