import { words } from 'lodash'
import { useLazyGetStaffQuery } from 'store/api'

import { useEffect } from 'react'

import { Avatar, Group, Skeleton, Stack, Text, TextInput } from '@mantine/core'
import { useDebouncedValue, useInputState } from '@mantine/hooks'

import { DynamicEmptyData, TableContainerWithScroll } from 'view/components'
import { useStyle } from 'view/style'

import { NoUserToDisplayIcon, SearchIcon } from 'assets/svg'

export const EmployeeSearchTable = ({ data }) => {
  const { classes } = useStyle()
  const redirectToDeclaration = (name, email) => {
    sessionStorage.setItem('employeeDeclarationEmail', JSON.stringify({ name, email }))
    window.open('employee_declarations', '_blank')
  }
  const tableHeader = [{ name: 'Name', align: 'left' }]
  return (
    <DynamicEmptyData
      hasData={data?.length}
      icon={<NoUserToDisplayIcon />}
      message={'Use search bar to find user'}
      iconTitle={'No user to display'}
    >
      <TableContainerWithScroll
        scrollMaxHeight={data?.length > 4 && 390}
        highlightOnHover
        tableHeader={tableHeader}
        numberOfRows={data?.length}
      >
        <tbody className={classes.tableBody}>
          {data?.map((row, index) => {
            return (
              <tr
                onClick={() => redirectToDeclaration(row.name, row.value)}
                key={row.label}
                aria-label={`ESTableRow${index}`}
              >
                <td>
                  <Group>
                    <Avatar
                      size={32}
                      radius="xl"
                      pr={4}
                    >
                      {words(row?.name)[0][0].toUpperCase()}
                      {words(row?.name)[words(row?.name).length - 1][0].toUpperCase()}
                    </Avatar>
                    <Stack spacing={0}>
                      <Text>{row.name}</Text>
                      <Text color={'#677C91'}>{row.value}</Text>
                    </Stack>
                  </Group>
                </td>
              </tr>
            )
          })}
        </tbody>
      </TableContainerWithScroll>
    </DynamicEmptyData>
  )
}

const ESEmployeeTab = () => {
  const [searchValue, setSearchValue] = useInputState('')
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300)
  const [trigger, { data: staffApiData, isFetching }] = useLazyGetStaffQuery()

  const searchMinimumChar = 3

  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue.length >= searchMinimumChar)
      trigger({ searchName: debouncedSearchValue })
  }, [debouncedSearchValue])

  return (
    <Stack spacing={24}>
      <TextInput
        placeholder="Search name or email (min 3 characters)"
        rightSection={<SearchIcon />}
        value={searchValue}
        onChange={setSearchValue}
        aria-label="search"
        autoFocus
      />
      <Skeleton visible={isFetching}>
        <EmployeeSearchTable data={staffApiData} />
      </Skeleton>
    </Stack>
  )
}

export default ESEmployeeTab
