import { setStepperStep } from 'store/assetDeclareSlice'

import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { Group, Text, SimpleGrid, Paper, ThemeIcon, Anchor, Grid } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import DeclarationReminderBanner from 'view/components/DeclarationReminderBanner'
import { useStyle } from 'view/style'

import { ShieldTickIcon, TickIcon } from 'assets/svg'

import StepperBanner from './StepperBanner'

const ADStepper = ({
  activeStep,
  submittedStatus,
  financialYear,
  submissionConfig,
  checkSubmissionSuccess,
  bannerData,
}) => {
  const breakpoint = useMediaQuery('(min-width: 768px)')
  const dispatch = useDispatch()
  const { classes } = useStyle()
  const stepperConfig = [
    { classname: classes.stepperBorderActive, number: '1', text: 'Getting started' },
    {
      classname: classes.stepperBorderActive,
      number: '2',
      text: 'Fill in details',
    },
    { classname: '', number: '3', text: 'Review and submit' },
  ]

  return (
    <Paper>
      {bannerData?.showBanner ? (
        <DeclarationReminderBanner
          bannerData={bannerData}
          withCloseButton={true}
          withChevronRight={false}
        />
      ) : null}
      <StepperBanner
        checkSubmissionSuccess={checkSubmissionSuccess}
        submittedStatus={submittedStatus}
        activeStep={activeStep}
        submissionConfig={submissionConfig}
      />

      <SimpleGrid
        className={classes.stepperContainer}
        sx={{
          borderRadius: '4px',
        }}
        cols={3}
        breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 'sm' }]}
        spacing={0}
      >
        {stepperConfig.map((item, key) => {
          let stepperBgColor
          let iconBgColor

          switch (true) {
            case activeStep > key:
              stepperBgColor = '#E0F3F3'
              iconBgColor = '#009089'
              break
            case activeStep === key:
              stepperBgColor = '#FFFFFF'
              iconBgColor = '#E0F3F3'
              break
            default:
              stepperBgColor = '#FFFFFF'
              iconBgColor = '#FFFFFF'
          }

          return (
            <Group
              sx={(theme) => ({
                height: '100%',
                borderRadius: '4px',
                backgroundColor: stepperBgColor,
                [theme.fn.largerThan('sm')]: {
                  justifyContent: 'center',
                },
              })}
              spacing={20}
              pl={20}
              key={item.text}
              className={item.classname}
            >
              <ThemeIcon
                size="xl"
                radius="xl"
                variant={activeStep >= key + 1 ? 'filled' : 'outline'}
                color={activeStep >= key ? '#009089' : 'gray'}
                sx={{
                  backgroundColor: iconBgColor,
                  borderRadius: '100%',
                  zIndex: '3',
                }}
              >
                {activeStep >= key + 1 ? <TickIcon disabled /> : item.number}
              </ThemeIcon>
              <Text
                weight={500}
                color={activeStep >= key ? '#009089' : 'gray'}
                size={14}
                sx={{
                  zIndex: '2',
                }}
              >
                {item.text}
              </Text>
            </Group>
          )
        })}
      </SimpleGrid>
      <Grid
        justify="center"
        align="center"
        pt={15}
      >
        <Grid.Col
          sm={4}
          xs={12}
          offsetSm={4}
          offsetXs={0}
        >
          <Group
            spacing={2}
            position="center"
          >
            <ShieldTickIcon />
            <Text
              size={13}
              ml={5}
              fw={500}
            >
              {`Learn more about `}
              <Anchor
                component={Link}
                to={'/trust-and-safety'}
                onClick={() => dispatch(setStepperStep(activeStep < 2 ? activeStep : 1))}
              >
                <Text
                  component="span"
                  color="#009089"
                  weight={600}
                >
                  Trust & Safety
                </Text>
              </Anchor>
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col span="auto">
          {activeStep === 1 ? (
            <Text
              fw={500}
              ta={breakpoint ? 'right' : 'center'}
              size={13}
            >{`Declaration Year : ${financialYear}`}</Text>
          ) : null}
        </Grid.Col>
      </Grid>
    </Paper>
  )
}

export default ADStepper
