import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { leafApi } from 'store/api'

import history from '../config/history'
import { msGraphApi } from './msGraphApi'
import rootReducer from './rootReducer'

export function createStore(preloadedState) {
  const middleware = [routerMiddleware(history), leafApi.middleware, msGraphApi.middleware]
  const store = configureStore({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    preloadedState,
  })
  return store
}

const store = createStore()
export default store
