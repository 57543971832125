import { Fragment } from 'react'

import { Stack, Divider, Anchor, Text } from '@mantine/core'

import { ADTable, ADRows, TextWithInfo, ADInvestmentRows, ADVehicleRows } from 'view/components'

import {
  BCASH_MAP,
  VEHICLE_MAP,
  INVEST_MAP,
  RETIRE_MAP,
  OTHER_MOVABLE_MAP,
} from 'util/tableMapping'
import { ASSET_DECLARATION_CONTACT_NOTE } from 'util/textMapping'

export const moveableAssetForms = [
  {
    text: 'Cash Savings',
    tooltip:
      'Total amount of cash savings in all types of accounts (e.g. savings, current, joint accounts, fixed deposits combined in each financial institution) as of date of declaration.',
    type: 'bank',
    tableMapping: BCASH_MAP,
    row: <ADRows />,
  },
  {
    text: 'Assets - Vehicle',
    tooltip: 'All types of vehicles owned as of date of declaration.',
    type: 'vehicle',
    tableMapping: VEHICLE_MAP,
    row: <ADVehicleRows />,
  },
  {
    text: 'Investment in Shares, Bonds & Other Forms of Securities',
    tooltip: 'All types of securities and investments as of date of declaration.',
    type: 'investment',
    tableMapping: INVEST_MAP,
    row: <ADInvestmentRows />,
  },
  {
    text: 'Retirement Fund',
    tooltip: 'All types of savings or investments in retirement funds as of date of declaration.',
    type: 'fund',
    tableMapping: RETIRE_MAP,
    row: <ADRows />,
  },
  {
    text: 'Other Movable Assets (each valued at MYR 15,000 and above)',
    tooltip: 'Assets owned by employee that are in physical form as of date of declaration.',
    type: 'asset',
    tableMapping: OTHER_MOVABLE_MAP,
    row: <ADRows />,
  },
]
const MovableAssetPanel = ({ form, apiDropdownData }) => {
  const link = 'assetdeclaration@petronas.com'
  const index = ASSET_DECLARATION_CONTACT_NOTE.indexOf(link)

  const updatedCashSavingsNote = (
    <Stack>
      <Text
        size={14}
        italic
      >
        {ASSET_DECLARATION_CONTACT_NOTE.substring(0, index)}
        <Anchor
          weight={500}
          href={'mailto:assetdeclaration@petronas.com'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link}
        </Anchor>
        {ASSET_DECLARATION_CONTACT_NOTE.substring(index + link.length)}
      </Text>
      <Text
        size={14}
        italic
      >
        {`Savings held in foreign countries are to be declared as "Institution outside of Malaysia"
        under the Name of Institution dropdown list.`}
      </Text>
    </Stack>
  )

  return (
    <Stack>
      <Divider />
      {moveableAssetForms.map(({ text, tooltip, type, tableMapping, row }, index) => {
        return (
          <Fragment key={type}>
            <TextWithInfo
              text={`${index + 1}. ${text}`}
              tooltip={tooltip}
            />
            {type === 'bank' ? updatedCashSavingsNote : ''}
            <ADTable
              form={form}
              tableConfig={tableMapping}
              tableData={form.values[tableMapping['tableName']]}
              apiDropdownData={apiDropdownData}
              addLabel={`Add ${type}`}
            >
              {row}
            </ADTable>
          </Fragment>
        )
      })}
    </Stack>
  )
}

export default MovableAssetPanel
