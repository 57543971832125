import { uniq, uniqWith, isEqual } from 'lodash'
import { useLazyGetStaffQuery } from 'store/api'

import { useReducer, useState, forwardRef } from 'react'

import { Stack, Text, Group, Button, Input, Select, MultiSelect, Box, Avatar } from '@mantine/core'
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks'

import { useStyle } from 'view/style'

import { ChevronDown } from 'assets/svg'

import dayjs from 'dayjs'

import { CustomDateRangePicker } from '..'
import CustomModalContainer from './CustomModalContainer'
import { ValueBox } from './ExtendGroupViewModal'

export const initialState = {
  financialYear: '',
  financialYearConfigurationId: '',
  groupName: '',
  startDate: '',
  endDate: '',
  reason: '',
  selectedUsers: '',
  userList: [],
}

export function reducer(state, action) {
  switch (action.type) {
    case 'changed_financial_year':
      return {
        ...state,
        financialYear: action.financialYear,
        financialYearConfigurationId: action.id,
        startDate: action.opening,
        endDate: action.closing,
      }
    case 'changed_group_name':
      return {
        ...state,
        groupName: action.groupName,
      }
    case 'changed_date_range':
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      }
    case 'changed_reason':
      return {
        ...state,
        reason: action.reason,
      }
    case 'changed_users':
      return {
        ...state,
        selectedUsers: uniq([...action.selectedUsers]),
      }
    case 'update_user_list':
      return {
        ...state,
        userList: uniqWith([...state.userList, ...action.userList], isEqual),
      }
    case 'reset_data':
      return {
        ...initialState,
      }
    default:
      return {
        ...state,
      }
  }
}

const RedAsterisk = () => (
  <Text
    span
    color="red"
  >
    *
  </Text>
)

const Item = forwardRef(({ label, ...others }, ref) => {
  const newLabel = label || 'Missing Name Value'
  const labelArr = newLabel.split(' ')
  return (
    <div
      {...others}
      ref={ref}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          size={32}
          radius="xl"
          pr={4}
        >
          {labelArr[0][0]?.toUpperCase()}
          {labelArr[labelArr.length - 1][0]?.toUpperCase()}
        </Avatar>
        <div>{label.split('(')[0]}</div>
      </Box>
    </div>
  )
})

const ExtendGroupCreateModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  financialConfigMap = new Map(),
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [searchedUsers, setSearchedUsers] = useState('')
  const [debouncedSearchedName] = useDebouncedValue(searchedUsers, 400)
  const [description, setDescription] = useState('')
  const [trigger, { data: staffApiData, isFetching, isSuccess }] = useLazyGetStaffQuery()
  const currentDate = dayjs()
  const disableDateRangePicker = ['', null].includes(state.financialYear)
  const disableSubmit = Object.values(state).includes('') || Object.values(state).includes([])
  const { classes } = useStyle()
  const searchMinimumChar = 3

  useDidUpdate(() => {
    if (debouncedSearchedName && debouncedSearchedName.length >= searchMinimumChar)
      trigger({ searchName: debouncedSearchedName })
  }, [debouncedSearchedName])

  useDidUpdate(() => {
    if (staffApiData && !isFetching && isSuccess) {
      dispatch({ type: 'update_user_list', userList: staffApiData })
    }
  }, [staffApiData, isFetching, isSuccess])

  const onCloseHandler = () => {
    dispatch({ type: 'reset_data' })
    setDescription('')
    setSearchedUsers('')
    handleClose()
  }

  const generateYearsArray = () => {
    const yearsArray = []
    for (let i = currentDate.get('year') - 1; i < currentDate.get('year') + 1; i++) {
      yearsArray.push(`${i}`)
    }
    return yearsArray
  }

  const getMinDate = (financialYear) => {
    let minDate = null
    if (financialConfigMap.has(financialYear)) {
      if (currentDate.get('year').toString() !== financialYear) {
        minDate = new Date(dayjs(financialConfigMap.get(financialYear)[1]))
      } else if (currentDate.get('year').toString() === financialYear) minDate = new Date(dayjs())
    }
    return minDate
  }

  const submitHandler = () => {
    handleSubmit({
      financialYearConfigurationId: state.financialYearConfigurationId,
      formSubmissionOpeningDate: dayjs(state.startDate).format('YYYY-MM-DD'),
      formSubmissionClosingDate: dayjs(state.endDate).format('YYYY-MM-DD'),
      targetType: 'EMAIL',
      targetValue: state.selectedUsers.join(','),
      groupName: state.groupName,
      description: description,
      reason: state.reason,
    })
    onCloseHandler()
  }

  const financialYearChangeHandler = (year) => {
    let financialYearId = ''
    let financialYearOpening = ''
    let financialYearClosing = ''
    if (financialConfigMap.has(year)) {
      financialYearId = financialConfigMap.get(year)[0]
      financialYearOpening = dayjs(getMinDate(year)).toDate()
      financialYearClosing = dayjs(getMinDate(year)).add(30, 'day').toDate()
    }
    dispatch({
      type: 'changed_financial_year',
      financialYear: year,
      id: financialYearId,
      opening: financialYearOpening,
      closing: financialYearClosing,
    })
  }

  const groupNameChangeHandler = (e) => {
    dispatch({ type: 'changed_group_name', groupName: e.target.value })
  }

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value)
  }

  const dateRangeChangeHandler = (dateArray) => {
    dispatch({ type: 'changed_date_range', startDate: dateArray[0], endDate: dateArray[1] })
  }

  const reasonChangeHandler = (e) => {
    dispatch({ type: 'changed_reason', reason: e.target.value })
  }

  const userChangeHandler = (user) => {
    dispatch({ type: 'changed_users', selectedUsers: user })
  }

  return (
    <CustomModalContainer
      title="Create New Group"
      opened={isOpen}
      onClose={onCloseHandler}
      size={600}
      modalPadding="24px 24px 20px 24px"
    >
      <Stack>
        <Stack spacing={4}>
          <Text size={12}>
            Declaration Year
            <RedAsterisk />
          </Text>
          <Select
            classNames={{
              itemsWrapper: classes.dropdownWrapper,
              rightSection: classes.dropdownRightSection,
              item: classes.dropdownitem,
            }}
            placeholder="Select year"
            data={generateYearsArray()}
            value={state.financialYear}
            onChange={financialYearChangeHandler}
            clearable
            searchable
            rightSection={<ChevronDown />}
            w={322}
            aria-label="egSelectFY"
          />
        </Stack>
        <Stack spacing={4}>
          <Text size={12}>
            Group Name
            <RedAsterisk />
          </Text>
          <Input
            value={state.groupName}
            onChange={groupNameChangeHandler}
            placeholder="Name of the group"
            w={322}
            aria-label="egInputGroupName"
            maxLength={2048}
          />
        </Stack>
        <Stack spacing={4}>
          <Text size={12}>Description</Text>
          <Input
            value={description}
            onChange={descriptionChangeHandler}
            placeholder="Describe the group"
            w={322}
            aria-label="egInputGroupDescription"
            maxLength={2048}
          />
        </Stack>

        <Stack spacing={4}>
          <Text size={12}>
            Select start date and end date
            <RedAsterisk />
          </Text>
          <CustomDateRangePicker
            handleSubmit={dateRangeChangeHandler}
            initialValue={[state.startDate, state.endDate]}
            disabled={disableDateRangePicker}
            minDate={getMinDate(state.financialYear)}
            width={322}
          />
        </Stack>

        <Stack spacing={4}>
          <Text size={12}>
            Reason of date extension
            <RedAsterisk />
          </Text>
          <Input
            value={state.reason}
            onChange={reasonChangeHandler}
            placeholder="State reason of extension"
            w={552}
            aria-label="egInputExtensionReason"
            maxLength={2048}
          />
        </Stack>
        <Stack spacing={4}>
          <Text size={12}>
            Find user to be included in this group
            <RedAsterisk />
          </Text>
          <MultiSelect
            data={state.userList}
            value={state.selectedUsers}
            valueComponent={ValueBox}
            itemComponent={Item}
            onChange={userChangeHandler}
            placeholder="Find user"
            rightSection={<ChevronDown />}
            searchable
            searchValue={searchedUsers}
            onSearchChange={setSearchedUsers}
            nothingFound={isFetching ? 'Searching...' : 'Nothing found'}
            limit={20}
            w={552}
            aria-label="egMultiSelectUsers"
            maxSelectedValues={50}
          />
        </Stack>
        <Group
          position="right"
          pt={16}
        >
          <Button
            variant="default"
            onClick={onCloseHandler}
            aria-label="egCreateCloseButton"
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={disableSubmit}
            styles={{
              root: {
                '&[data-disabled]': {
                  backgroundColor: '#AFDDDC',
                  color: '#FFFFFF',
                },
              },
            }}
            aria-label="submitEditDC"
          >
            Create group
          </Button>
        </Group>
      </Stack>
    </CustomModalContainer>
  )
}

export default ExtendGroupCreateModal
