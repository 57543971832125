import { Link } from 'react-router-dom'

import { Paper, Title, Text, Group, Button, Stack } from '@mantine/core'

const NotFound = () => {
  return (
    <Paper
      px={{ md: '20rem', sm: '5rem' }}
      py="5rem"
    >
      <Stack
        align="center"
        justify="center"
        ta="center"
      >
        <Title>You have found a secret place.</Title>
        <Text
          color="dimmed"
          size="lg"
          align="center"
        >
          Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
          been moved to another URL.
        </Text>
        <Group position="center">
          <Button
            component={Link}
            to="/assets"
            aria-label="back-button"
            variant="outline"
            size="md"
          >
            Take me back to home page
          </Button>
        </Group>
      </Stack>
    </Paper>
  )
}

export default NotFound
