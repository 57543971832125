import { useMsal } from '@azure/msal-react'
import { ApmRoute } from '@elastic/apm-rum-react'
import { useGetIdentityQuery } from 'store/api'
import { setIsUnauthorized } from 'store/assetDeclareSlice'

import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Switch } from 'react-router'

import { CenterLoader, PageLayout } from 'view/components'
import Breadcrumbs from 'view/components/Breadcrumbs'
import { NotFound, Unauthorized } from 'view/pages'

import { getMsalAccountRole } from './authConfig'
import { pubRoutes, routes } from './routeConfig'

const AppRoutes = () => {
  const dispatch = useDispatch()
  const { isError, isLoading: indentityIsLoading } = useGetIdentityQuery()

  const { instance } = useMsal()
  const msalAccountRole = getMsalAccountRole(instance)

  const renderBuildRoutes = useMemo(() => {
    if (!indentityIsLoading) {
      return buildRoutes(msalAccountRole, isError)
    }
    if (isError) {
      dispatch(setIsUnauthorized(isError))
    }
  }, [isError, indentityIsLoading])

  return (
    <PageLayout>
      {indentityIsLoading ? (
        <CenterLoader />
      ) : (
        <Switch>
          {pubRoutes.map((routeConfig) => {
            const { path, component } = routeConfig
            return (
              <ApmRoute
                key={path}
                path={path}
                exact
                component={() => component}
              />
            )
          })}
          {renderBuildRoutes}
          {/* {buildRoutes(renderBuildRoutes, isError)} */}
          <ApmRoute component={NotFound} />
        </Switch>
      )}
    </PageLayout>
  )
}

export default AppRoutes

const buildRouteComponent = (routeConfig, msalAccountRole, index, isError) => {
  const { path, component, role } = routeConfig
  return !isError && msalAccountRole.includes(role) ? (
    <ApmRoute
      path={path}
      key={index}
      exact
      component={() => (
        <>
          <Breadcrumbs />
          {component}
        </>
      )}
    />
  ) : (
    <ApmRoute
      path={path}
      key={index}
      exact
      component={() => <Unauthorized role={role} />}
    />
  )
}
const buildRoutes = (msalAccountRole, isError) => {
  const res = routes.map((routeConfig, index) =>
    buildRouteComponent(routeConfig, msalAccountRole, index, isError)
  )
  return res
}
