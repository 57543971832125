import { useState } from 'react'

import { Stack, Text, Group, Button, Input } from '@mantine/core'

import { CycleStatus } from 'view/pages/DeclarationCycle'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { CustomDateRangePicker, CustomDatePicker } from '..'
import CustomModalContainer from './CustomModalContainer'

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
const DeclarationCycleEditModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  id,
  financialYear,
  initialDates,
}) => {
  const [dateRange, setDateRange] = useState(initialDates)
  const isOngoing =
    dayjs(dayjs().format('YYYY-MM-DD')).isSameOrAfter(initialDates[0]) &&
    dayjs(dayjs().format('YYYY-MM-DD')).isSameOrBefore(initialDates[1])
  const disableSubmit =
    dateRange.includes(null) || (isOngoing && dayjs(dateRange[1]).isSame(initialDates[1]))
  const setEndDateHandler = (endDate) => {
    setDateRange((prevState) => [prevState[0], endDate])
  }

  const submitHandler = () => {
    handleSubmit({
      id: id,
      formSubmissionOpeningDate: dayjs(dateRange[0]).format('YYYY-MM-DD'),
      formSubmissionClosingDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
      financialYear,
    })
    handleClose()
  }

  const closeHandler = () => {
    setDateRange(initialDates)
    handleClose()
  }

  return (
    <CustomModalContainer
      title="Edit Cycle"
      opened={isOpen}
      onClose={closeHandler}
      size={400}
      modalPadding="24px 24px 20px 24px"
    >
      <Stack>
        <Stack spacing={4}>
          <Text size={12}>Declaration Year</Text>
          <Input
            disabled
            readOnly
            value={financialYear}
            w={352}
          />
        </Stack>
        <Stack spacing={4}>
          <Text size={12}>Status</Text>
          <CycleStatus
            financialYear={financialYear}
            openingDate={dateRange[0]}
            closingDate={dateRange[1]}
          />
        </Stack>
        {isOngoing ? (
          <>
            <Stack spacing={4}>
              <Text size={12}>Start date</Text>
              <Input
                disabled
                readOnly
                value={dayjs(initialDates[0]).format('DD MMM YYYY')}
                w={352}
              />
            </Stack>
            <Stack spacing={4}>
              <Text size={12}>Select end date</Text>
              <CustomDatePicker
                handleSubmit={setEndDateHandler}
                initialValue={new Date(dateRange[1])}
                minDate={dayjs().toDate()}
                maxDate={dayjs(new Date(dateRange[1])).endOf('year').toDate()}
              />
            </Stack>
          </>
        ) : (
          <Stack spacing={4}>
            <Text size={12}>Select start date and end date</Text>
            <CustomDateRangePicker
              handleSubmit={setDateRange}
              initialValue={[new Date(dateRange[0]), new Date(dateRange[1])]}
              minDate={dayjs(dateRange[0]).startOf('year').toDate()}
              maxDate={dayjs(dateRange[1]).endOf('year').toDate()}
              width={352}
            />
          </Stack>
        )}
        <Group
          position="right"
          pt={16}
        >
          <Button
            variant="default"
            onClick={closeHandler}
            aria-label="cancelEditDC"
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={disableSubmit}
            styles={{
              root: {
                '&[data-disabled]': {
                  backgroundColor: '#AFDDDC',
                  color: '#FFFFFF',
                },
              },
            }}
            aria-label="submitEditDC"
          >
            Save
          </Button>
        </Group>
      </Stack>
    </CustomModalContainer>
  )
}

export default DeclarationCycleEditModal
