import { useMsal } from '@azure/msal-react'
import { Watermark } from '@hirohe/react-watermark'
import { getMsalAccountEmail } from 'config/authConfig'
import { useGetViewInvestigateQuery } from 'store/api'

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Paper, Stack } from '@mantine/core'

import { CenterLoader, Review } from 'view/components'

import { calculateAssetTotal, formatResponse } from 'util/helper'

import { Unauthorized } from '..'

const InvestigationPage = () => {
  const { instance } = useMsal()
  const currentEmail = getMsalAccountEmail(instance)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)?.get('uuid')
  const summaryData = useGetViewInvestigateQuery({ uuid: queryParams })

  const renderEmployeeData = useMemo(() => {
    return summaryData?.data?.map((val) => {
      return {
        ...val,
        formData: formatResponse(val.formData),
        identityData: {
          fullName: val.fullName,
          oid: val.staffId,
          email: val.email,
          department: val.department,
        },
        categoryDetails: calculateAssetTotal(formatResponse(val?.formData)),
      }
    })
  }, [summaryData])

  if (summaryData?.isError) return <Unauthorized role={'DELEGATEDAUTHORITY'} />

  if (summaryData?.isLoading) return <CenterLoader />

  return (
    <Paper mb={150}>
      <Watermark
        gutter={50}
        textColor="#cbcbcb"
        text={currentEmail}
        textSize={15}
        opacity={0.4}
      >
        <Stack spacing={'15rem'}>
          {renderEmployeeData?.map((val, key) => {
            return (
              <Paper
                withBorder
                key={key}
              >
                <Review
                  form={{ values: val.formData }}
                  categoryDetails={val.categoryDetails}
                  assetRevisionData={val.revisionNumber}
                  isSubmitted={true}
                  financialYear={val.financialYear}
                  showAmount={true}
                  identityData={val.identityData}
                  date={val.updatedAt}
                />
              </Paper>
            )
          })}
        </Stack>
      </Watermark>
    </Paper>
  )
}

export default InvestigationPage
