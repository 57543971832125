import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ActionIcon, Alert, Group } from '@mantine/core'

import { BlueAlertIcon, XIcon, BlueInfoIcon, ChevronRight } from 'assets/svg'

import { sendEmail } from 'util/helper'

import { ChevronRightButton } from '.'

const CustomAlertBanner = ({
  title = '',
  content,
  showAdditional = false,
  withCloseButton = false,
  withInfoIcon = false,
  withChevronRight = false,
  pathUrl = '',
  chevronRightText = '',
}) => {
  const [renderAlert, setRenderAlert] = useState(true)
  const additionalRenderer = [
    { text: 'FAQ Page', onClick: () => window.open('/faq', '_blank') },
    { text: 'Contact Support', onClick: () => sendEmail('main') },
  ]
  const history = useHistory()
  if (!renderAlert) return null
  return (
    <Alert
      icon={withInfoIcon ? <BlueInfoIcon /> : <BlueAlertIcon />}
      mb={12}
      title={title}
      styles={{
        root: {
          backgroundColor: '#E2F1FC',
          border: `solid #003F9A`,
          borderWidth: '1px 1px 1px 5px',
          alignItems: 'stretch',
          padding: '20px 13px',
        },
        icon: { marginRight: '10px' },
        message: { color: '#003F9A', fontWeight: 400 },
        title: { color: '#003F9A', fontWeight: 500 },
      }}
    >
      <Group position="apart">
        {content}
        {withCloseButton ? (
          <ActionIcon
            aria-label="close-alert"
            onClick={() => setRenderAlert((state) => !state)}
          >
            <XIcon color="#003F9A" />
          </ActionIcon>
        ) : null}
        {withChevronRight ? (
          <Group position="right">
            <ActionIcon
              aria-label="next-path"
              onClick={() => {
                history.push(pathUrl)
              }}
              style={{ width: 'auto', padding: '7px' }}
            >
              <div style={{ color: '#003F9A', fontSize: '14px', marginRight: '10px' }}>
                {chevronRightText}
              </div>
              <ChevronRight color="#003F9A" />
            </ActionIcon>
          </Group>
        ) : null}
      </Group>
      {showAdditional ? (
        <Group mt={10}>
          {additionalRenderer.map((item) => {
            return (
              <ChevronRightButton
                {...item}
                key={item.text}
                fontWeight={500}
                color="#003F9A"
              />
            )
          })}
        </Group>
      ) : null}
    </Alert>
  )
}

export default CustomAlertBanner
