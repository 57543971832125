import {
  useGetFinancialYearConfigQuery,
  useGetSubmissionTicketQuery,
  usePostSubmissionTicketMutation,
} from 'store/api'

import { useMemo, useState } from 'react'

import { Button, Group, Menu, Stack, Text } from '@mantine/core'
import { useDidUpdate, useDisclosure } from '@mantine/hooks'

import { CustomYearDropdown, MenuButton, TableContainerWithScroll } from 'view/components'
import { ExtendGroupCreateModal, ExtendGroupViewModal } from 'view/components/Modal'
import { useStyle } from 'view/style'

import dayjs from 'dayjs'

import { CycleStatus } from './DeclarationCycle'

const ExtendGroupMenu = ({ index, data }) => {
  const [modalIsOpen, { close: closeModal, open: openModal }] = useDisclosure(false)
  return (
    <>
      <MenuButton ariaLabel={index}>
        <Menu.Item onClick={openModal}>View</Menu.Item>
      </MenuButton>
      {modalIsOpen ? (
        <ExtendGroupViewModal
          isOpen={modalIsOpen}
          handleClose={closeModal}
          groupData={data}
        />
      ) : null}
    </>
  )
}

const ExtendGroupTable = ({ data }) => {
  const scrollMaxHeight = data?.length > 4 && 290
  const { classes } = useStyle()
  const tableHeader = [
    { name: 'No', align: 'left' },
    { name: 'Group Name', align: 'left' },
    { name: 'Status', align: 'left' },
    { name: 'Financial Year', align: 'left' },
    { name: 'Duration of Submission', align: 'left' },
    { name: '', align: 'left' },
  ]
  return (
    <TableContainerWithScroll
      scrollMaxHeight={scrollMaxHeight}
      tableHeader={tableHeader}
      numberOfRows={data?.length}
    >
      <tbody className={classes.tableBody}>
        {data
          ? data.map((row, index) => {
              return (
                <tr key={row.id}>
                  <td>
                    <Text>{index + 1 < 10 ? `0${index + 1}` : index + 1}</Text>
                  </td>
                  <td>
                    <Text>{row.groupName}</Text>
                  </td>
                  <td>
                    <CycleStatus
                      openingDate={row.formSubmissionOpeningDate}
                      closingDate={row.formSubmissionClosingDate}
                      financialYear={row.financialYearConfigurationDto.year}
                    />
                  </td>
                  <td>
                    <Text>{row.financialYearConfigurationDto.year}</Text>
                  </td>
                  <td>
                    <Text>
                      {dayjs(row.formSubmissionOpeningDate).format('DD MMM YYYY')}
                      {' - '}
                      {dayjs(row.formSubmissionClosingDate).format('DD MMM YYYY')}
                    </Text>
                  </td>
                  <td>
                    <ExtendGroupMenu
                      data={row}
                      index={index}
                    />
                  </td>
                </tr>
              )
            })
          : null}
      </tbody>
    </TableContainerWithScroll>
  )
}

export const getFinancialMapConfig = (financialConfigData) => {
  const currentYear = dayjs().get('year')
  const currentDate = dayjs().format('YYYY-MM-DD')

  if (!financialConfigData?.length) {
    return new Map()
  }

  const financialMap = financialConfigData.map((row) => {
    const rowYear = row.year.toString()
    const isPastYear = rowYear < currentYear

    const openingDate = isPastYear ? currentDate : row.formSubmissionOpeningDate
    const closingDate = isPastYear
      ? new Date(row.formSubmissionClosingDate).setFullYear(currentYear)
      : row.formSubmissionClosingDate

    return [rowYear, [row.id, openingDate, closingDate]]
  })

  return new Map(financialMap)
}

const ExtendGroup = () => {
  const [currentYear, setCurrentYear] = useState(null)

  const { data: submissionTicketData } = useGetSubmissionTicketQuery({
    year: currentYear,
    page: 0,
  })
  const { data: financialConfigData, refetch } = useGetFinancialYearConfigQuery()
  const [postSubmissionTicket] = usePostSubmissionTicketMutation()
  const financialConfigMap = useMemo(
    () => getFinancialMapConfig(financialConfigData),
    [financialConfigData]
  )

  const [modalIsOpen, { close: closeModal, open: openModal }] = useDisclosure(false)

  useDidUpdate(() => {
    refetch()
  }, [currentYear])

  return (
    <>
      <Group
        position="apart"
        pb={24}
      >
        <Stack spacing={4}>
          <Text
            size={24}
            fw={600}
          >
            Extend Group
          </Text>
          <Text>Configure a specific group of user to be part of the special case delivery</Text>
        </Stack>
        <Group>
          <Button onClick={openModal}>Create new group</Button>
          <CustomYearDropdown onChange={setCurrentYear} />
        </Group>
      </Group>
      <ExtendGroupTable data={submissionTicketData} />
      <ExtendGroupCreateModal
        isOpen={modalIsOpen}
        handleClose={closeModal}
        handleSubmit={postSubmissionTicket}
        financialConfigMap={financialConfigMap}
      />
    </>
  )
}

export default ExtendGroup
