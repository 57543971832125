import { Badge, Text } from '@mantine/core'

import { useStyle } from 'view/style'

const ADExampleRow = ({ tableConfig }) => {
  const { classes } = useStyle()
  return (
    <tr>
      <td>
        <Badge
          styles={{
            root: {
              color: 'black',
              backgroundColor: '#EDF1F8',
              border: '1px solid #D3DEE8 !important',
              borderRadius: '3px',
            },
          }}
          ml={-13}
          variant="outline"
        >
          Example
        </Badge>
      </td>
      {tableConfig['headerKeys'].map((item) => {
        const colConfig = tableConfig['fields'][item]
        return (
          <td
            key={item}
            style={{ maxWidth: tableConfig['fields'][item].maxWidth, wordWrap: 'break-word' }}
          >
            <Text
              color="#8D8D8D"
              align={colConfig.align}
            >
              {colConfig.exampleContent}
            </Text>
          </td>
        )
      })}
      <td
        className={classes.stickyColumn}
        style={{ backgroundColor: '#ffffff' }}
      ></td>
    </tr>
  )
}

export default ADExampleRow
