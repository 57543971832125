import { setScrolledBottom } from 'store/assetDeclareSlice'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Text, Paper, Grid, Group } from '@mantine/core'

import { ChevronRightButton } from 'view/components'

import RenderNews from './RenderNews'
import TimelineContent from './TimelineContent'

const ADMain = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleViewportNoScroll = () => {
    //If content fill screen size
    if (document.body.clientHeight <= window.innerHeight) {
      dispatch(setScrolledBottom(true))
    }
  }

  //Check  on first render
  useEffect(() => {
    handleViewportNoScroll()
  }, [])

  //Check if user trigger the zoom in and out button
  window.addEventListener('resize', () => {
    handleViewportNoScroll()
  })

  //Detect if scroll reached bottom
  window.addEventListener('scroll', (e) => {
    const bottom =
      e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop - 200 <=
      e.target.scrollingElement.clientHeight

    if (bottom) {
      dispatch(setScrolledBottom(true))
    }
  })

  return (
    <Grid>
      <Grid.Col
        xs={12}
        sm={7}
        order={1}
        orderXs={2}
        mb={50}
      >
        <Paper>
          <Text
            ml={55}
            mb={30}
            p={4}
            weight={700}
            size={20}
          >
            Asset Declaration: What you need to know
          </Text>
          <TimelineContent />
          <ChevronRightButton
            ml={70}
            onClick={() => history.push('/faq')}
            text="Read the myAsset FAQ for more details"
          />
        </Paper>
      </Grid.Col>
      <Grid.Col
        xs={12}
        sm={5}
        orderSm={2}
        orderXs={1}
      >
        <Paper>
          <Group
            position="apart"
            pb={12}
          >
            <Text
              weight={700}
              size={20}
            >
              News & Updates
            </Text>
            <ChevronRightButton
              onClick={() =>
                window.open(
                  'https://mypetronas.com/myhrm/main-page',
                  '_blank',
                  'noopener noreferrer'
                )
              }
              text="More Updates"
            />
          </Group>
          <RenderNews />
        </Paper>
      </Grid.Col>
    </Grid>
  )
}

export default ADMain
