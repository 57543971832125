import { UnstyledButton, Group, Text } from '@mantine/core'

import { ChevronRight } from 'assets/svg'

const ChevronRightButton = ({
  text,
  size = null,
  onClick,
  ml,
  color = '#00847C',
  fontWeight = 400,
}) => {
  return (
    <UnstyledButton onClick={onClick}>
      <Group
        ml={ml}
        sx={{
          color: color,
          width: size,
        }}
        spacing={10}
      >
        <Text
          weight={fontWeight}
          size="sm"
        >
          {text}
        </Text>
        <ChevronRight />
      </Group>
    </UnstyledButton>
  )
}

export default ChevronRightButton
