import { memo, useCallback, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Affix, Button, Menu } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import { EmailIcon, FeedbackIcon, QuestionIcon, QuestionMarkWihoutCircleIcon } from 'assets/svg'

import { sendEmail } from 'util/helper'

const FloatingHelpButton = memo(() => {
  const withoutHelpButtonPath = useMemo(() => ['/faq', '/investigate'], [])
  const { pathname } = useLocation()

  const notBlockingFooterBtns = useMediaQuery('(min-width: 98em)')

  const handleSendEmail = useCallback((type) => {
    sendEmail(type)
  }, [])

  // Disable help button display on FAQ page
  if (withoutHelpButtonPath.some((item) => pathname.includes(item))) return null

  return (
    <Affix
      position={{
        bottom: notBlockingFooterBtns ? 100 : 140,
        right: 50,
      }}
    >
      <Menu
        withArrow
        position="top-end"
      >
        <Menu.Target>
          <Button
            sx={{ borderRadius: 32, backgroundColor: '#FDB924', color: '#65490E' }}
            color="yellow"
            leftIcon={<QuestionIcon color="#65490E" />}
            aria-label="helpButton"
          >
            Help
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            icon={<FeedbackIcon />}
            onClick={() =>
              window.open(`https://forms.office.com/r/Lc4Y2FxVVJ`, '_blank', 'noopener noreferrer')
            }
            aria-label="feedbackButton"
          >
            Give Feedback
          </Menu.Item>
          <Menu.Item
            icon={<EmailIcon />}
            onClick={() => handleSendEmail('ict')}
            aria-label="ictButton"
          >
            ICT Service Desk
          </Menu.Item>
          <Menu.Item
            icon={<EmailIcon />}
            onClick={() => handleSendEmail('main')}
            aria-label="hrButton"
          >
            HR Support
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={'/faq/'}
            key={'/faq/'}
            target="_blank"
            icon={<QuestionMarkWihoutCircleIcon />}
          >
            FAQ
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Affix>
  )
})

export default FloatingHelpButton
