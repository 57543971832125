import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { msalInstance } from 'config/authConfig'

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://graph.microsoft.com/v1.0/',
  prepareHeaders: async (headers) => {
    const request = {
      scopes: ['User.Read', 'User.Read.All', 'Directory.Read.All'],
      account: msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0],
    }
    const token = await msalInstance.acquireTokenSilent(request)
    if (token) {
      headers.set('authorization', `Bearer ${token.accessToken}`)
    }
    return headers
  },
})

export const msGraphApi = createApi({
  reducerPath: 'msGraphApi',
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getMyProfile: builder.query({
      query: () => ({
        url: 'me/',
      }),
    }),
    getMyPhoto: builder.query({
      query: () => ({
        url: 'me/photo/$value/',
        responseHandler: async (response) => URL.createObjectURL(await response.blob()),
      }),
    }),
  }),
})

export const { useGetMyProfileQuery, useGetMyPhotoQuery } = msGraphApi
