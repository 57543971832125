import { Redirect } from 'react-router'
import { getStoredPath } from 'util/localRedirectUrlStorage'

export const allPaths = [
  '/assets',
  '/assets/revision',
  '/declaration-overview',
  '/declaration-overview/review',
  '/declaration-overview/revision',
  '/faq',
  '/trust-and-safety',
  '/privacy-statement',
  '/admin/dashboard',
  '/admin/manage_form',
  '/admin/employee_search',
  '/admin/employee_declarations',
  '/admin/employee_declarations/view',
  '/investigate',
  '/unauthorized'
]

export const isValidRoute = (path) => {
  if(path === null || path === undefined || path === '/' || path === '') 
    return false
  // Check if the path starts with any of the valid paths
  return allPaths.some(validPath => path.startsWith(validPath))
}

const Home = () => {
  const storedPath = getStoredPath()
  const redirectLocation = isValidRoute(storedPath) ? storedPath : '/assets'
  
  return <Redirect to={redirectLocation} />
}

export default Home
