import { useGetIdentityQuery } from 'store/api'
import {
  calculateTotalAmount,
  calculateTotalRows,
  setDOPreview,
  setStepperStep,
} from 'store/assetDeclareSlice'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  Breadcrumbs as MantineBreadcrumbs,
  Anchor,
  Paper,
  Group,
  Button,
  Tooltip,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { Review } from 'view/components'
import { DOModal } from 'view/components/Modal'

import { DownloadIcon, EditIcon } from 'assets/svg'

import { formatResponse } from 'util/helper'

const DOReview = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: identityData } = useGetIdentityQuery()
  const categoryDetails = useSelector((state) => state.assetDeclare)
  const { formData, revision, financialYear, submitted, updatedAt } = categoryDetails.tempPreview
  const [DOModalIsOpen, { close: closeDOModal, open: openDOModal }] = useDisclosure(false)
  const breadcrumbs = [
    { title: 'Declaration Overview', href: '/declaration-overview' },
    { title: `Declaration Year ${financialYear}`, href: '#' },
  ]

  const formattedData = formatResponse(formData)

  const handleReviseClick = () => {
    history.push(submitted ? '/declaration-overview/revision' : '/assets')

    if (!submitted) {
      dispatch(setStepperStep(1))
    }
  }

  useEffect(() => {
    // redirect to earlier page if user refreshes
    if (history.action === 'POP') {
      const sessionSaveAsset = sessionStorage.getItem('assetform')
      if (sessionSaveAsset) {
        const parsedAsset = JSON.parse(sessionSaveAsset)
        dispatch(calculateTotalAmount(formatResponse(parsedAsset.formData)))
        dispatch(calculateTotalRows(JSON.stringify(formatResponse(parsedAsset.formData))))
        dispatch(setDOPreview(parsedAsset))
      } else history.push('/declaration-overview')
    }
  }, [])

  const items = breadcrumbs.map((item, index) => {
    return (
      <Anchor
        color={index === breadcrumbs.length - 1 && 'dark'}
        size={12}
        weight={500}
        component={Link}
        to={item.href}
        key={item.title}
      >
        {item.title}
      </Anchor>
    )
  })
  return (
    <>
      <Paper
        p={26}
        mb={100}
      >
        <Group
          mb={34}
          position="apart"
        >
          <MantineBreadcrumbs>{items}</MantineBreadcrumbs>
          <Button.Group>
            {/* Download button  */}
            {/* {submitted ? (
              <Button
                variant="default"
                leftIcon={<DownloadIcon />}
                onClick={openDOModal}
              >
                Download
              </Button>
            ) : (
              <Tooltip
                withinPortal
                withArrow
                label={'Download disabled for draft'}
              >
                <Button
                  variant="default"
                  leftIcon={<DownloadIcon />}
                  onClick={(e) => e.preventDefault()}
                  sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                  data-disabled
                  aria-label="disabledDownload"
                >
                  Download
                </Button>
              </Tooltip>
            )} */}
            <Button
              variant="default"
              leftIcon={<EditIcon />}
              onClick={handleReviseClick}
            >
              {submitted ? 'Revise' : 'Edit Draft'}
            </Button>
          </Button.Group>
        </Group>
        <Paper withBorder>
          <Review
            form={{ values: formattedData }}
            categoryDetails={categoryDetails}
            assetRevisionData={revision}
            isSubmitted={submitted}
            financialYear={financialYear}
            identityData={identityData}
            date={updatedAt}
          />
        </Paper>
      </Paper>
      {DOModalIsOpen ? (
        <DOModal
          isOpen={DOModalIsOpen}
          onClose={closeDOModal}
        />
      ) : null}
    </>
  )
}

export default DOReview
