import { Center, Loader } from '@mantine/core'

const CenterLoader = ({ height = '100vh' }) => {
  return (
    <Center sx={{ height: height }}>
      <Loader
        color="#009089"
        variant="bars"
      />
    </Center>
  )
}

export default CenterLoader
