import {
  useGetMDAcquisitionQuery,
  useCreateMDAcquisitionMutation,
  useUpdateMDAcquisitionMutation,
} from 'store/api'

import { FMACQUISITIONTYPE_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMAssetAcquisitionTypePanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDAcquisitionQuery({
    body: {
      page: 0,
      size: 1000,
      sortColumn: 'name',
      sortOrder: 'asc',
    },
  })
  const [addAcquisitionType] = useCreateMDAcquisitionMutation()
  const [updateAcquisitionType] = useUpdateMDAcquisitionMutation()
  return (
    <FMPanelTemplate
      addButtonLabel={`Add Asset Acquisition Type`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMACQUISITIONTYPE_MAP}
      apiAddHandler={addAcquisitionType}
      apiUpdateHandler={updateAcquisitionType}
    />
  )
}

export default FMAssetAcquisitionTypePanel
