import { Text, Title, Group, Badge } from '@mantine/core'

const AccordianHeader = ({ title, count, totalAmount, showAmount }) => {
  return (
    <Group
      spacing="xs"
      mr={20}
    >
      <Title order={4}>{title}</Title>

      <Badge
        size="sm"
        sx={{
          backgroundColor: count ? '#AFDDDC' : '#D3DEE8',
        }}
        color={count ? '#009089' : 'dark.3'}
      >
        {count}
      </Badge>
      {showAmount ? (
        <Text
          color="textGray"
          size="sm"
        >{`Total (MYR) : ${totalAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`}</Text>
      ) : null}
    </Group>
  )
}

export default AccordianHeader
