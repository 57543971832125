import { showNotification } from '@mantine/notifications'

import { RoundTickIcon, XIcon } from 'assets/svg'

// All the config for notification
//  For usage, import the show notification component from mantine and spred the props
export const successNotification = {
  title: 'Success',
  icon: <RoundTickIcon />,
  message: 'Success',
  autoClose: 4000,
  styles: {
    root: {
      backgroundColor: '#E5F4E7',
      border: `solid #00580F`,
      borderWidth: '1px 1px 1px 5px',
      alignItems: 'stretch',
      padding: '20px 5px',
      top: '50px',
    },
    icon: {
      width: '1rem',
      height: '1.3rem',
      backgroundColor: `#E5F4E7 !important`,
    },
    title: { color: '#00580F !important' },
    description: { color: '#00580F !important' },
  },
}

export const failNotification = {
  title: 'Fail',
  icon: <XIcon color="#e31e1e" />,
  message: 'Failed',
  autoClose: 4000,
  styles: {
    root: {
      backgroundColor: '#f4e5e5',
      border: `solid #e31e1e`,
      borderWidth: '1px 1px 1px 5px',
      maxHeight: '100vh !important',
      alignItems: 'stretch',
      padding: '20px 5px',
      top: '50px',
    },
    icon: {
      width: '1rem',
      height: '1.3rem',
      backgroundColor: `#f4e5e5 !important`,
    },
    title: { color: '#e31e1e !important' },
    description: { color: '#e31e1e !important' },
  },
}

export const showNotificationMessage = (title, message, isSuccess) => {
  showNotification({
    ...(isSuccess ? successNotification : failNotification),
    title,
    message,
  })
}
