//Mapping helper
const STATIC_DATE = new Date().getFullYear()

const STATIC_BOOLEAN_DROPDOWN = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

export const FINANCIAL_TYPE_DROPDOWN = [
  { value: 'Bank', label: 'Bank' },
  { value: 'Non-Bank', label: 'Non-Bank' },
]

//All Table mapping is below
const BCASH_MAP = {
  tableName: 'bankCashes',
  headerKeys: ['bankId', 'amount'],
  strictsColumn: ['bankId'],
  fields: {
    bankId: {
      maxWidth: 835,
      headerName: 'Name of Institution',
      exampleContent: 'CIMB Bank',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    amount: {
      maxWidth: 145,
      headerName: 'Amount (MYR)',
      exampleContent: '201,009.01',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    bankId: '',
    amount: 0,
  },
  totalAmountField: 'amount',
  dropdownApiKey: {
    bankType: 'bankId',
  },
}
const LOAN_MAP = {
  tableName: 'loans',
  headerKeys: [
    'financialInstitutionId',
    'financialInstitutionType',
    'loanTypeId',
    'amount',
    'monthlyRepayment',
    'commencementYear',
    'duration',
  ],
  fields: {
    financialInstitutionId: {
      maxWidth: 172,
      headerName: 'Name of Institution',
      exampleContent: 'Perbadanan Tabung Pendidikan Tinggi Nasional (PTPTN)',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
      // dependantKey: 'financialInstitutionType',
    },
    financialInstitutionType: {
      maxWidth: 132,
      headerName: 'Type of Institution',
      exampleContent: 'Non-Bank',
      inputType: 'dropdown',
      mandatory: true,
      dropdownSource: 'local',
      dropdownData: FINANCIAL_TYPE_DROPDOWN,
      disable: true,
    },
    loanTypeId: {
      maxWidth: 145,
      headerName: 'Type of Loan',
      exampleContent: 'Personal Loan',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    amount: {
      maxWidth: 130,
      headerName: 'Total loan amount (MYR)',
      exampleContent: '123,456.91',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
    },
    monthlyRepayment: {
      maxWidth: 130,
      headerName: 'Monthly Commitment (MYR)',
      exampleContent: '421.99',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
    },
    commencementYear: {
      maxWidth: 151,
      headerName: 'Commencement of loan (Year)',
      exampleContent: '2001',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
    duration: {
      maxWidth: 120,
      headerName: 'Total Duration (Years)',
      exampleContent: '10',
      inputType: 'number',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    financialInstitutionType: '',
    financialInstitutionId: '',
    loanTypeId: '',
    amount: 0,
    monthlyRepayment: 0,
    duration: 0,
    commencementYear: STATIC_DATE,
  },
  totalAmountField: 'amount',
  dropdownApiKey: {
    loanInstitution: 'financialInstitutionId',
    loanType: 'loanTypeId',
  },
}

const VEHICLE_MAP = {
  tableName: 'vehicles',
  headerKeys: [
    'vehicleTypeId',
    'vehicleBrandId',
    'model',
    'year',
    'acquisitionTypeId',
    'purchasedValue',
    'yearOfAcquisition',
  ],
  fields: {
    vehicleTypeId: {
      maxWidth: 160,
      headerName: 'Type',
      exampleContent: 'Motorcycle',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    vehicleBrandId: {
      maxWidth: 160,
      headerName: 'Brand',
      exampleContent: 'Yamaha',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
      dependantKey: 'vehicleTypeId',
    },
    model: {
      maxWidth: 160,
      headerName: 'Model',
      exampleContent: 'MT09',
      inputType: 'text',
      mandatory: true,
    },
    year: {
      maxWidth: 120,
      headerName: 'Production Year',
      exampleContent: '2023',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
    acquisitionTypeId: {
      maxWidth: 80,
      headerName: 'Type of Acquisition',
      exampleContent: 'Purchase',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    purchasedValue: {
      maxWidth: 200,
      headerName: 'Value (MYR)',
      exampleContent: '78,888.01',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
    },
    yearOfAcquisition: {
      maxWidth: 80,
      headerName: 'Acquisition Year',
      exampleContent: '2023',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    vehicleTypeId: '',
    vehicleBrandId: '',
    model: '',
    year: STATIC_DATE,
    acquisitionTypeId: '',
    purchasedValue: undefined,
    yearOfAcquisition: STATIC_DATE,
  },
  totalAmountField: 'purchasedValue',
  dropdownApiKey: {
    vehicleType: 'vehicleTypeId',
    vehicleBrand: 'vehicleBrandId',
    acquisitionType: 'acquisitionTypeId',
  },
}

const PROPERTY_MAP = {
  tableName: 'properties',
  headerKeys: ['propTypeId', 'propLocId', 'acquisitionTypeId', 'purchasedValue', 'year'],
  fields: {
    propTypeId: {
      maxWidth: 150,
      headerWidth: 120,
      headerName: 'Type of property',
      exampleContent: 'Residential Property',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    propLocId: {
      maxWidth: 150,
      headerWidth: 150,
      headerName: 'Location (State, Country)',
      exampleContent: 'Selangor, Malaysia',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    acquisitionTypeId: {
      maxWidth: 150,
      headerWidth: 150,
      headerName: 'Type of Acquisition',
      exampleContent: 'Purchase',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    purchasedValue: {
      maxWidth: 200,
      headerName: 'Value (MYR)',
      exampleContent: '560,890.11',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
    },
    year: {
      maxWidth: 147,
      headerWidth: 110,
      headerName: 'Acquisition Year',
      exampleContent: '2023',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    propTypeId: '',
    propLocId: '',
    acquisitionTypeId: '',
    purchasedValue: undefined,
    year: STATIC_DATE,
  },
  totalAmountField: 'purchasedValue',
  dropdownApiKey: {
    propertyType: 'propTypeId',
    propertyLocation: 'propLocId',
    acquisitionType: 'acquisitionTypeId',
  },
}

const OTHER_MOVABLE_MAP = {
  tableName: 'otherMovableAssets',
  headerKeys: [
    'otherMovableAssetTypeId',
    'description',
    'acquisitionTypeId',
    'totalValue',
    'acquisitionYear',
  ],
  fields: {
    otherMovableAssetTypeId: {
      maxWidth: 150,
      headerName: 'Type of asset',
      exampleContent: 'Painting',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    description: {
      maxWidth: 358,
      headerName: 'Description',
      exampleContent: 'The Starry Night, Vincent van Gogh',
      inputType: 'text',
      mandatory: true,
    },
    acquisitionTypeId: {
      maxWidth: 200,
      headerName: 'Type of Acquisition',
      exampleContent: 'Purchase',
      inputType: 'dropdown',
      mandatory: true,
      dropdownSource: 'api',
    },
    totalValue: {
      maxWidth: 150,
      headerName: 'Value (MYR)',
      exampleContent: '321,012,914.11',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
      minimumValue: 15000,
    },
    acquisitionYear: {
      maxWidth: 120,
      headerName: 'Acquisition Year',
      exampleContent: '2023',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
  },
  totalAmountField: 'totalValue',
  defaultValue: {
    otherMovableAssetTypeId: '',
    description: '',
    acquisitionTypeId: '',
    totalValue: undefined,
    acquisitionYear: STATIC_DATE,
  },
  dropdownApiKey: {
    otherMovableType: 'otherMovableAssetTypeId',
    acquisitionType: 'acquisitionTypeId',
  },
}
const INVEST_MAP = {
  tableName: 'investments',
  headerKeys: ['corporation', 'investmentTypeId', 'petAssociated', 'unit', 'amount', 'year'],
  fields: {
    corporation: {
      maxWidth: 252,
      headerName: 'Name of corporation',
      exampleContent: 'Apple Inc',
      inputType: 'text',
      mandatory: true,
    },
    investmentTypeId: {
      maxWidth: 250,
      headerName: 'Type of Investment',
      exampleContent: 'Stocks',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    petAssociated: {
      maxWidth: 60,
      headerName: 'PETRONAS Group of Companies',
      exampleContent: 'No',
      inputType: 'dropdown',
      mandatory: true,
      dropdownSource: 'local',
      dropdownData: STATIC_BOOLEAN_DROPDOWN,
    },
    unit: {
      maxWidth: 200,
      headerName: 'No. of Units',
      exampleContent: '312,914',
      inputType: 'number',
      align: 'right',
    },
    amount: {
      maxWidth: 145,
      headerName: 'Total Value (MYR)',
      exampleContent: '105,989.00',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
    },
    year: {
      maxWidth: 80,
      headerName: 'Latest Acquisition Year',
      exampleContent: '2023',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    corporation: '',
    investmentTypeId: '',
    petAssociated: '',
    unit: 0,
    amount: 0,
    year: STATIC_DATE,
  },
  totalAmountField: 'amount',
  dropdownApiKey: { investmentType: 'investmentTypeId' },
}

const RETIRE_MAP = {
  tableName: 'retirementFunds',
  headerKeys: ['retirementFundTypeId', 'totalValue'],
  strictsColumn: ['retirementFundTypeId'],
  fields: {
    retirementFundTypeId: {
      maxWidth: 836,
      headerName: 'Type of fund',
      exampleContent: 'Employee Provident Fund (EPF)',
      inputType: 'dropdown',
      dropdownSource: 'api',
      mandatory: true,
    },
    totalValue: {
      maxWidth: 145,
      headerName: 'Total value (MYR)',
      exampleContent: '205,675.97',
      inputType: 'currency',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    retirementFundTypeId: '',
    totalValue: 0,
  },
  totalAmountField: 'totalValue',
  dropdownApiKey: { retirementType: 'retirementFundTypeId' },
}

const OWNER_MAP = {
  tableName: 'companiesAsOwner',
  headerKeys: [
    'companyName',
    'petAssociated',
    'businessRegistrationNumber',
    'totalShares',
    'percentageOfShares',
    'year',
  ],
  fields: {
    companyName: {
      maxWidth: 193,
      headerName: 'Name of Business',
      exampleContent: 'Astro Radio Sdn Bhd',
      inputType: 'text',
      mandatory: true,
    },
    petAssociated: {
      maxWidth: 193,
      headerName: `PETRONAS' Contractor`,
      exampleContent: 'Yes',
      inputType: 'dropdown',
      mandatory: true,
      dropdownSource: 'local',
      dropdownData: STATIC_BOOLEAN_DROPDOWN,
    },
    businessRegistrationNumber: {
      maxWidth: 193,
      headerName: 'Business registration number',
      exampleContent: 'B-H212-4210',
      inputType: 'text',
      mandatory: true,
    },
    totalShares: {
      maxWidth: 120,
      headerName: 'Total shares owned',
      exampleContent: '312,914',
      inputType: 'number',
      align: 'right',
      mandatory: true,
    },
    percentageOfShares: {
      maxWidth: 145,
      headerName: 'Percentage of shares owned (%)',
      exampleContent: '42',
      inputType: 'percentage',
      align: 'right',
      mandatory: true,
    },
    year: {
      maxWidth: 120,
      headerName: 'Acquisition Year',
      exampleContent: '2023',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    companyName: '',
    petAssociated: '',
    businessRegistrationNumber: '',
    totalShares: 0,
    percentageOfShares: 0,
    year: STATIC_DATE,
  },
}

const DIRECTOR_MAP = {
  tableName: 'companiesAsDirector',
  headerKeys: ['companyName', 'petAssociated', 'businessRegistrationNumber', 'salary', 'year'],
  fields: {
    companyName: {
      maxWidth: 286,
      headerName: 'Name of company',
      exampleContent: 'Media Prima Sdn Bhd',
      inputType: 'text',
      mandatory: true,
    },
    petAssociated: {
      maxWidth: 120,
      headerName: `PETRONAS' Contractor`,
      exampleContent: 'No',
      inputType: 'dropdown',
      mandatory: true,
      dropdownSource: 'local',
      dropdownData: STATIC_BOOLEAN_DROPDOWN,
    },
    businessRegistrationNumber: {
      maxWidth: 286,
      headerName: 'Business registration number',
      exampleContent: 'MP-B7318-N',
      inputType: 'text',
      align: 'right',
      mandatory: true,
    },
    salary: {
      maxWidth: 140,
      headerName: 'Annual Salary (MYR) (if any)',
      exampleContent: '200',
      inputType: 'currency',
      align: 'right',
      mandatory: false,
    },
    year: {
      maxWidth: 145,
      headerName: 'Year of appointment',
      exampleContent: '2023',
      inputType: 'year',
      align: 'right',
      mandatory: true,
    },
  },
  defaultValue: {
    companyName: '',
    petAssociated: '',
    businessRegistrationNumber: '',
    salary: 0,
    year: STATIC_DATE,
  },
  totalAmountField: 'salary',
}

//Initial state based on the table mapping
const initialStateAssetDeclare = {
  [BCASH_MAP['tableName']]: [],
  [LOAN_MAP['tableName']]: [],
  [VEHICLE_MAP['tableName']]: [],
  [OTHER_MOVABLE_MAP['tableName']]: [],
  [PROPERTY_MAP['tableName']]: [],
  [OWNER_MAP['tableName']]: [],
  [DIRECTOR_MAP['tableName']]: [],
  [INVEST_MAP['tableName']]: [],
  [RETIRE_MAP['tableName']]: [],
}

export {
  BCASH_MAP,
  LOAN_MAP,
  VEHICLE_MAP,
  PROPERTY_MAP,
  OTHER_MOVABLE_MAP,
  INVEST_MAP,
  RETIRE_MAP,
  OWNER_MAP,
  DIRECTOR_MAP,
  initialStateAssetDeclare,
}
