import { useTranslation } from 'react-i18next'

import { Text, Box, Tabs, Group, Stack } from '@mantine/core'

import {
  FMBankPanel,
  FMVehicleBrandPanel,
  FMPropertyTypePanel,
  FMPropertyLocationPanel,
  FMVehicleTypePanel,
  FMInvestmentTypePanel,
  FMRetirementFundPanel,
  FMLoanTypePanel,
  FMOtherMoveablePanel,
  FMLoanInstitutionPanel,
} from 'view/pages'
import { useStyle } from 'view/style'

import { FORM_MANAGEMENT_TABS } from 'util/helper'

import FMAssetAcquisitionTypePanel from './AdminPage/FormManagementPage/FMAssetAcquisitionTypePanel'

const FormManagement = () => {
  const { classes } = useStyle()
  const { t } = useTranslation(['common'])

  const panelRenderer = [
    {
      value: 'institutionName',
      component: <FMBankPanel />,
    },
    {
      value: 'loanInstitutionName',
      component: <FMLoanInstitutionPanel />,
    },
    {
      value: 'loanType',
      component: <FMLoanTypePanel />,
    },
    {
      value: 'vehicleBrand',
      component: <FMVehicleBrandPanel />,
    },
    {
      value: 'vehicleType',
      component: <FMVehicleTypePanel />,
    },
    {
      value: 'propertyType',
      component: <FMPropertyTypePanel />,
    },
    {
      value: 'propertyLocation',
      component: <FMPropertyLocationPanel />,
    },
    {
      value: 'otherMoveable',
      component: <FMOtherMoveablePanel />,
    },
    {
      value: 'investmentType',
      component: <FMInvestmentTypePanel />,
    },
    {
      value: 'retirementFund',
      component: <FMRetirementFundPanel />,
    },
    {
      value: 'assetAcquisitionName',
      component: <FMAssetAcquisitionTypePanel />,
    },
  ]

  return (
    <Box>
      <Group position="apart">
        <Stack spacing={4}>
          <Text
            size={24}
            fw={600}
          >
            Form Management
          </Text>
          <Text>{t('Manage your taxonomies on the asset declaration form')}</Text>
        </Stack>
      </Group>
      <Tabs
        pt={40}
        defaultValue="institutionName"
        orientation="vertical"
        radius="xs"
        styles={{
          tab: classes.fmTab,
          tabsList: classes.fmTabList,
        }}
      >
        {/* render the tabs list */}
        <Tabs.List pr={9}>
          {FORM_MANAGEMENT_TABS.map(({ title, tabs }) => {
            return (
              <div key={title}>
                <Text
                  py={10}
                  key={title}
                  size={12}
                  color="#758CA3"
                >
                  {title}
                </Text>
                {tabs.map(({ value, name }) => {
                  return (
                    <Tabs.Tab
                      w="100%"
                      key={value}
                      value={value}
                    >
                      {name}
                    </Tabs.Tab>
                  )
                })}
              </div>
            )
          })}
        </Tabs.List>
        {/* render the tabs panel component in loop */}
        {panelRenderer.map(({ value, component }) => {
          return (
            <Tabs.Panel
              key={value}
              value={value}
            >
              {component}
            </Tabs.Panel>
          )
        })}
      </Tabs>
    </Box>
  )
}

export default FormManagement
