import { useGetMDLoanTypeQuery } from 'store/api'

const useFormatChartData = (data) => {
  const getPayload = {
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  }
  const { data: loanMasterData = [] } = useGetMDLoanTypeQuery(getPayload)
  const color = [
    '#00A19C',
    '#9466FF',
    '#FC930D',
    '#704C5E',
    '#B22CC8',
    '#048DEC',
    '#619B8A',
    '#DC7653',
  ]
  const getPercentage = (part, whole) => {
    return (part / whole) * 100
  }

  if (data) {
    const { financialCommitments, immovableAsset, movableAssets, otherSourceOfIncome } =
      data.formData
    //BARCHART data factory
    const _directorWithPetTotal = otherSourceOfIncome['companiesAsDirector'].filter(
      (e) => e.petAssociated === true
    )
    const _ownerWithPetTotal = otherSourceOfIncome['companiesAsOwner'].filter(
      (e) => e.petAssociated === true
    )
    const _totalDirectorSalary = otherSourceOfIncome['companiesAsDirector'].reduce(
      (a, b) => a + (b['salary'] || 0),
      0
    )
    const _totalDirectorPetAssociatedSalary = otherSourceOfIncome['companiesAsDirector'].reduce(
      (a, b) => {
        const c = b['petAssociated'] === true ? b['salary'] : 0
        return a + (c || 0)
      },
      0
    )
    const _totalDirectorBusinessSalary = otherSourceOfIncome['companiesAsDirector'].reduce(
      (a, b) => {
        const c = b['petAssociated'] === false ? b['salary'] : 0
        return a + (c || 0)
      },
      0
    )
    const barData = [
      {
        name: 'Business ownership',
        rendererName: 'Business',
        petAssociated: _ownerWithPetTotal.length,
        business: otherSourceOfIncome['companiesAsOwner'].length - _ownerWithPetTotal.length,
      },
      {
        name: 'Company directorship',
        rendererName: 'Company',
        petAssociated: _directorWithPetTotal.length,
        business: otherSourceOfIncome['companiesAsDirector'].length - _directorWithPetTotal.length,
        totalSalary: _totalDirectorSalary,
        salary: {
          business: _totalDirectorBusinessSalary,
          petAssociated: _totalDirectorPetAssociatedSalary,
        },
      },
    ].map((item) => ({ ...item, business: item.business > 0 ? item.business : 0 }))

    //PIE CHART data factory
    //Overview factory
    const pieOverviewTotal =
      immovableAsset.totalAmount +
      movableAssets.totalAmount +
      otherSourceOfIncome.totalAmount +
      financialCommitments.totalAmount
    const _liabilitiesTotal = financialCommitments.totalAmount
    const _assetTotal =
      immovableAsset.totalAmount + movableAssets.totalAmount + otherSourceOfIncome.totalAmount

    let overview = {
      total: pieOverviewTotal,
      data: [
        {
          name: 'Asset',
          value:
            immovableAsset.totalAmount +
            movableAssets.totalAmount +
            otherSourceOfIncome.totalAmount,
          fill: color[0],
          percentage: getPercentage(_assetTotal, pieOverviewTotal),
        },
        {
          name: 'Liabilities',
          value: _liabilitiesTotal,
          fill: color[1],
          percentage: getPercentage(_liabilitiesTotal, pieOverviewTotal),
        },
      ],
    }

    //filter overview data that is empty
    overview = { ...overview, data: overview.data.filter((e) => e.percentage !== 0) }

    //Asset factory
    const totalCashSaving = movableAssets['bankCashes'].reduce(
      (a, b) => a + (b['totalAmount'] || 0),
      0
    )
    const totalAutomobiles = movableAssets['vehicles'].reduce(
      (a, b) => a + (b['purchasedValue'] || 0),
      0
    )
    const totalShares = movableAssets['investments'].reduce(
      (a, b) => a + (b['totalAmount'] || 0),
      0
    )
    const totalRetirementFund = movableAssets['retirementFunds'].reduce(
      (a, b) => a + (b['totalValue'] || 0),
      0
    )
    const totalProperties = immovableAsset['properties'].reduce(
      (a, b) => a + (b['purchasedValue'] || 0),
      0
    )
    const totalOtherMovable = movableAssets['otherMovableAssets'].reduce(
      (a, b) => a + (b['totalValue'] || 0),
      0
    )
    const totalCompanyDirector = otherSourceOfIncome['companiesAsDirector'].reduce(
      (a, b) => a + (b['salary'] || 0),
      0
    )
    const pieAssetTotal =
      totalCashSaving +
      totalAutomobiles +
      totalShares +
      totalRetirementFund +
      totalProperties +
      totalOtherMovable +
      totalCompanyDirector
    const assets = {
      total: pieAssetTotal,
      data: [
        {
          name: 'Cash Savings',
          value: totalCashSaving,
          fill: color[0],
          percentage: getPercentage(totalCashSaving, pieAssetTotal),
        },
        {
          name: 'Vehicle',
          value: totalAutomobiles,
          fill: color[1],
          percentage: getPercentage(totalAutomobiles, pieAssetTotal),
        },
        {
          name: 'Shares, Bonds & Other Forms of Securities',
          value: totalShares,
          fill: color[3],
          percentage: getPercentage(totalShares, pieAssetTotal),
        },
        {
          name: 'Retirement Funds',
          value: totalRetirementFund,
          fill: color[4],
          percentage: getPercentage(totalRetirementFund, pieAssetTotal),
        },
        {
          name: 'Other Movable Assets',
          value: totalOtherMovable,
          fill: color[5],
          percentage: getPercentage(totalOtherMovable, pieAssetTotal),
        },
        {
          name: 'Property',
          value: totalProperties,
          fill: color[6],
          percentage: getPercentage(totalProperties, pieAssetTotal),
        },
        {
          name: 'Directorship',
          value: totalCompanyDirector,
          fill: color[7],
          percentage: getPercentage(totalCompanyDirector, pieAssetTotal),
        },
      ],
    }
    // Liabilities Factory
    const loanTypes = loanMasterData?.content?.map((loan) => loan.name) ?? []
    //use a loop to iterate over the different loan types and store the total amount for each type in an object.
    let totalAmounts = {}
    loanTypes.forEach((loanTypeName) => {
      const totalAmount = financialCommitments['loans']
        .filter((e) => e.loanTypeName === loanTypeName)
        .reduce((a, b) => a + (b['totalAmount'] || 0), 0)
      totalAmounts[`total${loanTypeName.replaceAll(' ', '')}`] = totalAmount
    })

    const liabilities = {
      total: _liabilitiesTotal,
      data: Object.values(totalAmounts).map((loan, index) => {
        return {
          name: loanTypes[index],
          value: loan,
          fill: index + 1 >= color.length ? color[index - 7] : color[index], // reuse color if types more than 8, max color 8
          percentage: getPercentage(loan, _liabilitiesTotal),
        }
      }),
    }
    return {
      pieData: { overview, assets, liabilities: _liabilitiesTotal ? liabilities : null },
      barData:
        otherSourceOfIncome['companiesAsOwner'].length ||
        otherSourceOfIncome['companiesAsDirector'].length
          ? barData
          : null,
    }
  } else {
    return { pieData: null, barData: null }
  }
}

export default useFormatChartData
