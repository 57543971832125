import { setStepperStep } from 'store/assetDeclareSlice'

import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { Text, Flex, Button, Group, Divider } from '@mantine/core'

import { EyeIcon } from 'assets/svg'

import { ADMIN_LINK, USER_LINK } from 'util/helper'

const NavButton = ({ content: { title = '', href } }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isActive =
    !location.pathname.includes('/declaration-overview/revision') &&
    location.pathname.includes(href)

  return (
    <Button
      key={title}
      variant={isActive ? 'white' : 'subtle'}
      sx={{
        backgroundColor: isActive ? '#E0F3F3' : null,
        color: !isActive ? 'white' : null,
      }}
      styles={{ label: { minheight: 10, margin: 12 } }}
      p={0}
      radius={8}
      onClick={() => dispatch(setStepperStep(0))}
      component={Link}
      to={href}
      fw={500}
    >
      {title}
    </Button>
  )
}

const NavbarLinks = ({ renderAdmin, breakpoint }) => {
  const location = useLocation()

  const checkWhitelist = [
    'assets',
    'revision',
    'faq',
    'trust-and-safety',
    'privacy-statement',
  ].some((e) => location.pathname.split('/').includes(e))

  return (
    <>
      {!renderAdmin && (
        <Button
          variant={checkWhitelist ? 'white' : 'subtle'}
          sx={{
            backgroundColor: checkWhitelist ? '#E0F3F3' : null,
            color: !checkWhitelist ? 'white' : null,
          }}
          styles={{ label: { minheight: 10, margin: 12 } }}
          p={0}
          radius={8}
          component={Link}
          to={'/assets'}
          fw={500}
        >
          Home
        </Button>
      )}
      {renderAdmin ? (
        <Group gap={16}>
          {ADMIN_LINK.map((item) => (
            <NavButton
              key={item.itemKey}
              content={item}
            />
          ))}
          {breakpoint && (
            <Divider
              orientation="vertical"
              color="#6AC2BE"
            />
          )}

          <Flex
            gap="5px"
            justify="center"
            align="center"
            sx={{ borderRadius: '4px', padding: '1px 8px', height: '24px' }}
            bg="#EDF1F8"
          >
            <EyeIcon color={'#677C91'} />
            <Text
              size="sm"
              weight={500}
              color={'#677C91'}
            >
              Admin view
            </Text>
          </Flex>
        </Group>
      ) : (
        USER_LINK.map((item) => (
          <Group
            gap={16}
            key={item.itemKey}
          >
            <NavButton
              key={item.itemKey}
              content={item}
            />
            {breakpoint && (
              <Divider
                orientation="vertical"
                color="#6AC2BE"
              />
            )}
          </Group>
        ))
      )}
    </>
  )
}

export default NavbarLinks
