import { useMsal } from '@azure/msal-react'
import { getMsalAccountRole } from 'config/authConfig'
import {
  useGetEmployeeDeclarationListQuery,
  useLazyDownloadEmployeeDeclarationQuery,
  usePostLogViewOthersMutation,
} from 'store/api'
import { calculateTotalAmount, calculateTotalRows, setDOPreview } from 'store/assetDeclareSlice'

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ActionIcon, Box, Group, Paper, Skeleton, Stack, Text, Tooltip } from '@mantine/core'
import { useSessionStorage } from '@mantine/hooks'

import { AvatarIcon, DynamicEmptyData, TableContainerWithScroll } from 'view/components'
import { useStyle } from 'view/style'

import { DownloadIcon, EyeIcon } from 'assets/svg'

import { formatResponse } from 'util/helper'

import dayjs from 'dayjs'

export const EmployeeeDetails = ({ name, email }) => {
  return (
    <Group>
      <AvatarIcon profileData={{ fullName: name }} />
      <Stack spacing={0}>
        <Text
          size={20}
          fw={700}
        >
          {name}
        </Text>

        <Text
          size={16}
          fw={400}
          color={'#677C91'}
        >
          {email}
        </Text>
      </Stack>
    </Group>
  )
}

export const TableRowIconButtons = ({ submitted, row, employeeName }) => {
  const [trigger, { isFetching }] = useLazyDownloadEmployeeDeclarationQuery()
  const [postLogViewOthers] = usePostLogViewOthersMutation()
  const dispatch = useDispatch()
  const history = useHistory()
  const clickDispatch = () => {
    dispatch(calculateTotalAmount(formatResponse(row.formData)))
    dispatch(calculateTotalRows(JSON.stringify(formatResponse(row.formData))))
    dispatch(setDOPreview(row))
    sessionStorage.setItem('assetform', JSON.stringify(row))
  }

  const viewClickHandler = () => {
    clickDispatch()
    history.push('employee_declarations/view')
    postLogViewOthers({
      email: JSON.parse(sessionStorage.getItem('employeeDeclarationEmail')).email,
      revision: `R.${row.revision}`,
      year: row.financialYear,
    })
  }

  // const downloadClickHandler = () => {
  //   trigger({
  //     id: row.id,
  //     revision: row.revision,
  //     financialYear: row.financialYear,
  //     fullName: employeeName,
  //   })
  // }

  const renderConfig = [
    {
      label: submitted ? 'View' : 'Preview',
      icon: <EyeIcon />,
      handleClick: viewClickHandler,
      buttonDisabled: false,
      loading: false,
    },
    // {
    //   label: submitted ? 'Download' : 'Download disabled for draft',
    //   icon: <DownloadIcon />,
    //   handleClick: downloadClickHandler,
    //   buttonDisabled: !submitted,
    //   loading: isFetching,
    // },
  ]
  return (
    <Box w={72}>
      <Group>
        {renderConfig.map(({ label, icon, handleClick, buttonDisabled, loading }, index) => {
          return (
            <Tooltip
              key={label}
              withinPortal
              withArrow
              label={label}
            >
              {buttonDisabled ? (
                <ActionIcon
                  aria-label={`ESEDIcon${index}`}
                  onClick={(e) => e.preventDefault()}
                  data-disabled
                  sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                >
                  {icon}
                </ActionIcon>
              ) : (
                <ActionIcon
                  aria-label={`ESEDIcon${index}`}
                  onClick={handleClick}
                  loading={loading}
                >
                  {icon}
                </ActionIcon>
              )}
            </Tooltip>
          )
        })}
      </Group>
    </Box>
  )
}

export const EmployeeDeclarationTable = ({ data, employeeName }) => {
  const { instance } = useMsal()
  const hasSuperAdminRole = getMsalAccountRole(instance).includes('SUPERADMIN')
  const { classes } = useStyle()

  const tableHeader = [
    { name: 'No', align: 'left' },
    { name: 'Declaration Year', align: 'right' },
    { name: 'Revision', align: 'right' },
    { name: 'Total Items Declared', align: 'right', hide: !hasSuperAdminRole },
    { name: 'Total Value (MYR)', align: 'right', hide: !hasSuperAdminRole },
    { name: 'Last Updated', align: 'right' },
    { name: 'Status', align: 'left' },
    { name: '', align: 'left' },
  ]
  return (
    <DynamicEmptyData
      hasData={data?.length}
      message={'Use search to find user'}
      iconTitle={'No user to display'}
    >
      <TableContainerWithScroll
        scrollMaxHeight={data?.length > 4 && 310}
        highlightOnHover
        tableHeader={tableHeader}
        numberOfRows={data?.length}
      >
        <tbody className={classes.tableBody}>
          {data?.map((row, index) => {
            return (
              <tr
                key={row.id}
                aria-label={`EDTableRow${index}`}
              >
                <td>
                  <Text>{index + 1 < 10 ? `0${index + 1}` : index + 1}</Text>
                </td>
                <td>
                  <Text className={classes.alignTextRight}>{row.financialYear}</Text>
                </td>
                <td>
                  <Text className={classes.alignTextRight}>R.{row.revision}</Text>
                </td>
                {hasSuperAdminRole ? (
                  <>
                    <td>
                      <Text className={classes.alignTextRight}>
                        {Number(row.formData.totalNumberOfAssets).toLocaleString()}
                      </Text>
                    </td>
                    <td>
                      <Text className={classes.alignTextRight}>
                        {Number(row.formData.totalAmount).toLocaleString()}
                      </Text>
                    </td>
                  </>
                ) : null}

                <td>
                  <Text className={classes.alignTextRight}>
                    {dayjs(row.updatedAt).format('DD MMM YYYY')}
                  </Text>
                </td>
                <td>
                  <Text className={row.submitted ? classes.statusSubmit : classes.statusDraft}>
                    {row.submitted ? 'Submitted' : 'Draft'}
                  </Text>
                </td>
                <td>
                  {hasSuperAdminRole ? (
                    <TableRowIconButtons
                      submitted={row.submitted}
                      row={row}
                      employeeName={employeeName}
                    />
                  ) : null}
                </td>
              </tr>
            )
          })}
        </tbody>
      </TableContainerWithScroll>
    </DynamicEmptyData>
  )
}

const ESEmployeeDeclaration = () => {
  const [value] = useSessionStorage({
    key: 'employeeDeclarationEmail',
    defaultValue: { name: 'Missing Name', email: 'missingName@email.com' },
  })
  const { data, isFetching } = useGetEmployeeDeclarationListQuery({ email: value.email })

  return (
    <Paper mb={150}>
      <Stack>
        <Skeleton visible={isFetching}>
          <EmployeeeDetails
            name={value.name}
            email={value.email}
          />
        </Skeleton>
        <Skeleton visible={isFetching}>
          <EmployeeDeclarationTable
            data={data}
            employeeName={value.name}
          />
        </Skeleton>
      </Stack>
    </Paper>
  )
}

export default ESEmployeeDeclaration
