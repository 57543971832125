import { useState } from 'react'

import { Stack, Group, Button } from '@mantine/core'

import { CustomCheckbox } from '..'
import CustomModalContainer from './CustomModalContainer'

export default function FYSelectModal({
  isOpen,
  onClose,
  onChange,
  defaultValue,
  onProceed,
  dateRange,
}) {
  const [checked, setChecked] = useState(defaultValue)

  const handleProceed = () => {
    onProceed()
    onClose()
  }

  return (
    <CustomModalContainer
      title="Select Declaration Year"
      opened={isOpen}
      onClose={onClose}
      size={600}
      styles={{ title: { fontWeight: 500, fontSize: 18 } }}
    >
      <Stack spacing={20}>
        {dateRange.map((year) => {
          return (
            <CustomCheckbox
              year={year}
              key={year}
              value={checked === year}
              onChange={(e) => {
                setChecked(e)
                onChange(e)
              }}
            />
          )
        })}
      </Stack>

      <Group
        mt={20}
        position="right"
      >
        <Button
          variant="default"
          onClick={onClose}
          aria-label="closeFYSelect"
        >
          Close
        </Button>
        <Button
          disabled={!checked}
          onClick={handleProceed}
          aria-label="proceedFYSelect"
        >
          Proceed
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
