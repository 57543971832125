import { isEmpty } from 'lodash'
import { useLazyGetDownloadUserGuideQuery } from 'store/api'

import React, { useState, useEffect, useRef } from 'react'

import {
  Accordion,
  Anchor,
  Box,
  Paper,
  Group,
  Input,
  Stack,
  Text,
  List,
  Button,
} from '@mantine/core'
import { useId } from '@mantine/hooks'

import { DynamicEmptyData } from 'view/components'

import { DownloadIcon, EmailIcon, SearchIcon, XIcon, CallIcon, ChatIcon } from 'assets/svg'

const faqData = [
  {
    contentTitle: 'What is myAsset?',
    content: (
      <Text>
        {`myAsset is a digital platform or tool for PETRONAS employee to declare their asset with the
        objective to ensure highest level of integrity and transparency in line with the PETRONAS'
        effort to prevent corruption, illicit enrichment, abuse of position and conflict of
        interest.`}
      </Text>
    ),
  },
  {
    contentTitle: 'Why do I need to declare my asset?',
    content: (
      <Stack>
        <Text>
          {`Implementation of Asset Declaration is part of PETRONAS’ efforts in maintaining integrity and preventing corruption. This duty 
to declare asset is contained in PETRONAS Code of Conduct and Business Ethics (CoBE) Country Supplement: Malaysia, Part II: 
Duties of Good Faith, Fidelity, Diligence and Integrity.
`}
        </Text>
        <Text>
          {`The objective is to ensure the highest level of integrity and transparency in line with PETRONAS’ effort to prevent corruption, illicit enrichment, abuse of position and conflict of interest.
`}
        </Text>
      </Stack>
    ),
  },
  // {
  //   contentTitle: 'Can I submit my Asset Declaration via mobile?',
  //   content: (
  //     <Text>
  //       Yes, you may access to myAsset via mobile (handphone or ipad) provided the gadget is
  //       connected with PETRONAS wifi. However staff is required to install Zscaler application prior
  //       to accessing myAsset outside of PETRONAS network
  //     </Text>
  //   ),
  // },
  {
    contentTitle: 'Where can I get the User Guide for Asset Declaration?',
    content: (
      <Group>
        <Text>
          {'From the Download User Guide link in the '}
          <Anchor href="#">Asset Declaration : What you Should Know.</Anchor>
        </Text>
      </Group>
    ),
  },
  {
    contentTitle: 'How often do I need to declare my assets?',
    content: (
      <Stack>
        <Text>Annually, during the declaration period as determined by the Company.</Text>
        <Text>
          You may also be requested by the Company to declare your asset as and when required, if
          warranted.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'What are the type of assets I need to declare?',
    content: (
      <Text>
        You are required to declare all of your assets that you currently own and/or partly own as
        of the date of declaration. The items that need to be declared are:
        <List
          withPadding
          listStyleType="decimal"
        >
          <List.Item>
            Movable assets:
            <List withPadding>
              <List.Item>
                Cash, vehicles, all types of securities (including but not limited to shares, bond,
                debentures, unit trust, ASB, warrants, retirement fund, etc.), trading, business or
                commercial licenses or permit.
              </List.Item>
              <List.Item>
                Jewelleries and other types of movable assets each worth equivalent to determined
                amount.
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            Immovable assets such as all types of land, buildings, and residences.
          </List.Item>
          <List.Item>Financial commitment.</List.Item>
          <List.Item>Business ownership and/or directorship of the Companies</List.Item>
        </List>
      </Text>
    ),
  },
  {
    contentTitle: 'Who can access my data in myAsset?',
    content: (
      <Stack>
        <Text>
          {`The access of users' data is strictly limited to one Super Admin i.e. Senior Vice
          President (SVP) Group HRM. Access is restricted and only granted for the purpose of
          investigations, litigations, or other legally required disclosures.`}
        </Text>
        <Text>
          {`Other than for the said purpose, your data cannot be freely accessed by the Company.`}
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'Who do I reach out for more inquiries?',
    content: (
      <Text>
        For first time user, you can read the Introduction page and view the ‘Learn More’ tabs to
        assist you in completing the form. Alternatively, you may contact
        assetdeclaration@petronas.com for further assistance.
      </Text>
    ),
  },
  {
    contentTitle: 'I have assets that I received as a gift; do I need to declare them as well?',
    content: (
      <Text>
        Yes. All assets that you currently own and/or partly own regardless the type of acquisition
        (purchased or received as gifts, inheritance, or winnings) as of the date of declaration
        need to be declared.
      </Text>
    ),
  },
  {
    contentTitle: 'Is the declaration of asset based on purchase value or market value?',
    content: (
      <Stack>
        <Text>
          Your declaration will be based on purchase value for asset that you had purchased either
          solely and/or jointly with others.
        </Text>
        <Text>
          If you received an asset as a gift, inheritance, or through winnings, you can estimate its
          value based on the market value of similar assets available in the market.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'Do I need to declare cash in hand?',
    content: (
      <Text>No. You only need to declare cash held in banks or other financial institutions.</Text>
    ),
  },
  {
    contentTitle: "I can't remember the purchase value of my asset, what do I do?",
    content: (
      <Text>
        You may check the estimated purchase value based on similar assets that are available in the
        market.
      </Text>
    ),
  },
  {
    contentTitle: 'I discovered an error in my submission. What can I do?',
    content: (
      <Stack>
        <Text>
          To correct any inaccurate information you have entered, please email our support team at
          assetdeclaration@petronas.com.
        </Text>
        <Text>
          State your reason(s) for the corrections for our review and if justified, the
          administrator will allow you to access your form to make the necessary correction and
          resubmit your declaration within the given timeframe.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'Can I access the information that I have submitted?',
    content: (
      <Text>
        Yes. You have the right to access and view the information you submitted via myAsset.
      </Text>
    ),
  },
  {
    contentTitle: 'What happens if I fail to declare my assets?',
    content: (
      <Text>
        Employee’s failure to submit Asset Declaration is a non-compliance of the CoBE 2022
        (effective 18th August 2022) and Country Supplement: Malaysia (effective 18 January 2023)
      </Text>
    ),
  },
]

const FAQPage = () => {
  const uuid = useId()
  const inputRef = useRef(null)
  const [trigger] = useLazyGetDownloadUserGuideQuery()
  const [searchTerm, setSearchTerm] = useState('')
  const [contents, setContents] = useState([])

  const handleDownload = async () => {
    await trigger()
  }

  const supportItems = [
    {
      title: 'Call: 03-2331 3333',
      link: 'tel:+60323313333',
      icon: <CallIcon color="#00847C" />,
    },
    {
      title: 'Chat and Self-Service',
      link: 'https://mygenieplus.petronas.com/',
      icon: <ChatIcon color="#00847C" />,
    },
    {
      title: 'Contact ICT Service Desk',
      link: 'mailto:ict.servicedesk@petronas.com',
      icon: <EmailIcon color="#00847C" />,
    },
    {
      title: 'Contact HR Support',
      link: 'mailto:assetdeclaration@petronas.com',
      icon: <EmailIcon color="#00847C" />,
    },
    {
      title: 'Download User Guide',
      link: handleDownload,
      icon: <DownloadIcon />,
    },
  ]

  useEffect(() => {
    const getFilteredContentTitles = () => {
      return !searchTerm ? [''] : filteredData.map((obj) => obj.contentTitle)
    }

    setContents(getFilteredContentTitles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  // Filter the view to only filtered data that matches with the search value
  const filteredData = faqData.filter(
    (faq) =>
      faq.contentTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      searchChildren(faq.content.props.children)
  )

  // Press Enter button to search function
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSearchTerm(event.target.value)
    }
  }

  // Searching text in the children of the Accordion
  function searchChildren(children) {
    if (typeof children === 'string') {
      if (children.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }
    } else if (Array.isArray(children)) {
      return children.some((child) => searchChildren(child))
    } else if (children?.props && children?.props?.children) {
      return searchChildren(children?.props?.children)
    }

    return false
  }

  // Highlighting the searched value in FAQ contents
  const highlightText = (text) => {
    const regex = new RegExp(`(${searchTerm})`, 'gi')
    return text.split(regex).map((match, index) => (
      <span
        key={`${uuid + index + match}`}
        style={
          match.toLowerCase() === searchTerm.toLowerCase() ? { backgroundColor: 'orange' } : {}
        }
      >
        {match}
      </span>
    ))
  }

  // Recursive function to extracts the text content of each component,
  // apply the desired styling and return it back to its original component
  // by replacing the original text content with the styled text

  function styleReactComponent(component) {
    if (typeof component === 'string') {
      return highlightText(component)
    }

    if (Array.isArray(component)) {
      return component.map((child) => styleReactComponent(child))
    }

    if (component?.props && component?.props?.children) {
      const newProps = { ...component.props }
      newProps.children = styleReactComponent(component.props.children)
      return React.createElement(component.type, newProps)
    }

    return component
  }

  const handleClearInput = () => {
    inputRef.current.value = ''
    setSearchTerm('')
  }

  return (
    <Box pb={90}>
      <Paper px={102}>
        <Stack
          align="center"
          spacing={0}
        >
          <Text
            weight={700}
            size={32}
          >
            Asset Declaration : What you Should Know
          </Text>
          <Input
            sx={{ width: '30em' }}
            icon={<SearchIcon />}
            type="text"
            placeholder="Search for your answer here.."
            mt={40}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            rightSection={
              <Button
                variant="white"
                size="xs"
                compact
                onClick={handleClearInput}
              >
                <XIcon color="#939597" />
              </Button>
            }
          />

          <Group
            position="centre"
            my={42}
          >
            {supportItems.map((index) => {
              const additionalProps =
                index.title === 'Download User Guide'
                  ? { onClick: index.link, download: true }
                  : { href: index.link }
              return (
                <Anchor
                  key={index.title}
                  size={12}
                  weight={500}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...additionalProps}
                >
                  <Group spacing={5}>
                    {index.title}
                    {index.icon}
                  </Group>
                </Anchor>
              )
            })}
          </Group>
        </Stack>
        <DynamicEmptyData
          hasData={!isEmpty(filteredData)}
          message={'Try using different keywords to search'}
          iconTitle={'No results found'}
          py={80}
        >
          <Paper
            mb={40}
            p={0}
            withBorder
          >
            <Accordion
              multiple
              sx={{ backgroundColor: 'white' }}
              value={contents}
              onChange={setContents}
            >
              {filteredData.map(({ contentTitle, content }) => {
                return (
                  <Accordion.Item
                    key={contentTitle}
                    value={contentTitle}
                    role="accordionItem"
                  >
                    <Accordion.Control
                      py={22}
                      px={24}
                    >
                      <Text
                        fw={600}
                        fz={16}
                      >
                        {styleReactComponent(contentTitle)}
                      </Text>
                    </Accordion.Control>
                    <Accordion.Panel
                      px={24}
                      pb={4}
                    >
                      {styleReactComponent(content)}
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </Paper>
        </DynamicEmptyData>
      </Paper>
    </Box>
  )
}

export default FAQPage
