import { Paper, Divider, Group, Stack, Text, SimpleGrid, Image, Flex } from '@mantine/core'

import { CustomBadge } from 'view/components'
import { financialCommitmentForms } from 'view/pages/AssetDeclaration/AccordianItem/FinancialCommitmentPanel'
import { immoveableAssetForms } from 'view/pages/AssetDeclaration/AccordianItem/ImmovableAssetPanel'
import { moveableAssetForms } from 'view/pages/AssetDeclaration/AccordianItem/MovableAssetPanel'
import { otherSourceForms } from 'view/pages/AssetDeclaration/AccordianItem/OtherSourcePanel'
import { useStyle } from 'view/style'

import PetronasLogoTitle from 'assets/images/PetronasLogoTitle.png'

import { useGetDropdownData } from 'util/hooks'

import dayjs from 'dayjs'

import ReviewTable from './ReviewTable'

export const ShowAmount = ({ children, show }) => {
  return show ? children : <Text>****</Text>
}

const ReviewHeadings = ({ isSubmitted, financialYear, date, assetRevisionData }) => {
  const badgeRenderer = [
    { content: 'Private and Confidential' },
    { content: isSubmitted ? 'Submitted' : 'Draft' },
  ]

  const rightRenderer = [
    { title: 'Date', content: dayjs(date).format('DD MMM YYYY') },
    {
      title: 'Revison Number',
      content: `R.${assetRevisionData}`,
    },
  ]
  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      justify={{ sm: 'space-between', xs: 'center' }}
      align={{ xs: 'center' }}
      mb={40}
    >
      <Image
        maw="6rem"
        src={PetronasLogoTitle}
      />
      <Stack spacing={'16px'}>
        <Group position="center">
          {badgeRenderer.map(({ content }) => {
            return (
              <CustomBadge
                key={content}
                label={content}
                badgeWidth={null}
                fontWeight={600}
                bgColor="#EDF1F8"
                fontColor="#677C91"
              />
            )
          })}
        </Group>
        <Text
          ta="center"
          fz={{ md: 32, xs: 20 }}
          fw={700}
        >
          Asset Declaration {financialYear || date.getFullYear()}
        </Text>
      </Stack>
      <Flex
        gap={15}
        direction="column"
        ta={{ base: 'center', sm: 'right' }}
      >
        {rightRenderer.map(({ title, content }) => {
          return (
            <Stack
              key={title}
              spacing={0}
            >
              <Text
                color="#677C91"
                size={12}
              >
                {title}
              </Text>
              <Text
                size={16}
                fw={900}
              >
                {content}
              </Text>
            </Stack>
          )
        })}
      </Flex>
    </Flex>
  )
}

const ReviewDetails = ({ data, totalAsset, totalAmount, showAmount }) => {
  return (
    <SimpleGrid
      cols={2}
      breakpoints={[
        { maxWidth: 'md', cols: 2 },
        { maxWidth: 'sm', cols: 1 },
      ]}
    >
      <Stack>
        <Stack spacing={0}>
          <Text
            color="#677C91"
            fw={500}
          >
            Name
          </Text>
          <Text fw={900}>{data?.fullName || ' '}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text
            color="#677C91"
            fw={500}
          >
            Staff ID
          </Text>
          <Text fw={900}>{data?.oid || ' '}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text
            color="#677C91"
            fw={500}
          >
            Email
          </Text>
          <Text fw={900}>{data?.email || ' '}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text
            color="#677C91"
            fw={500}
          >
            Department
          </Text>
          <Text fw={900}>{data?.department || ' '}</Text>
        </Stack>
      </Stack>
      <Flex
        direction={{ base: 'row', sm: 'column' }}
        justify={{ sm: 'right', xs: 'space-between' }}
        ta={{ sm: 'right', xs: 'center' }}
        mb={40}
      >
        <Stack spacing={0}>
          <Text
            color="#677C91"
            size={20}
            fw={500}
          >
            Total Items Declared
          </Text>
          <Text
            size={24}
            fw={900}
          >
            {totalAsset}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text
            color="#677C91"
            size={20}
            fw={500}
          >
            Total Amount (MYR)
          </Text>
          <ShowAmount show={showAmount}>
            <Text
              size={24}
              fw={900}
            >
              {totalAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </Text>
          </ShowAmount>
        </Stack>
      </Flex>
    </SimpleGrid>
  )
}

const ReviewTableContainer = ({ title, totalRows, totalAmount, children, showAmount }) => {
  const { classes } = useStyle()
  return (
    <Paper
      p={24}
      mb={24}
      className={classes.reviewTableCard}
    >
      <Group spacing={40}>
        <Text
          size={20}
          fw={700}
        >
          {title}
        </Text>
        <Group spacing={24}>
          <Text
            fw={500}
            size={16}
          >
            {totalRows}
          </Text>
        </Group>

        <Group spacing={24}>
          <Text
            color={'#677C91'}
            fw={400}
            size={16}
          >
            {'Total (MYR)'}
          </Text>
          <ShowAmount show={showAmount}>
            <Text
              fw={500}
              size={16}
            >
              {totalAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </Text>
          </ShowAmount>
        </Group>
      </Group>
      <Divider
        mb={12}
        mt={12}
      />
      {children}
    </Paper>
  )
}

const RenderReviewTable = ({ isDisabled, mapping, title = null, form, showAmount }) => {
  const { apiDropdownData, dropdownIsSuccess } = useGetDropdownData()
  if (dropdownIsSuccess) {
    return isDisabled ? null : (
      <ReviewTable
        title={title}
        bodyValues={form.values[mapping['tableName']]}
        headerKeys={mapping['headerKeys']}
        bodyConfig={mapping['fields']}
        apiDropdownData={apiDropdownData[mapping['tableName']]}
        showAmount={showAmount}
      />
    )
  }
}

const Review = ({
  form,
  categoryDetails,
  assetRevisionData = 0,
  isSubmitted = null,
  financialYear = null,
  showAmount = true,
  identityData,
  date = new Date(),
}) => {
  const {
    bankCash,
    loan,
    vehicle,
    property,
    owner,
    director,
    investment,
    retirement,
    otherMovable,
  } = categoryDetails

  const moveableTotalAmount =
    bankCash.amount + vehicle.amount + investment.amount + retirement.amount + otherMovable.amount
  const moveableTotalRows =
    bankCash.rows + vehicle.rows + investment.rows + retirement.rows + otherMovable.rows
  const immoveableTotalAmount = property.amount
  const immoveableTotalRows = property.rows
  const financialTotalAmount = loan.amount
  const financialTotalRows = loan.rows
  const otherSourceTotalAmount = owner.amount + director.amount
  const otherSourceTotalRows = owner.rows + director.rows
  const reviewMappings = [
    {
      containerTitle: 'Movable Asset',
      containerDisabled:
        bankCash.isDisabled &&
        vehicle.isDisabled &&
        investment.isDisabled &&
        retirement.isDisabled &&
        otherMovable.isDisabled,
      containerRows: moveableTotalRows,
      containerAmount: moveableTotalAmount,
      tables: [
        {
          title: moveableAssetForms[0].text,
          mapping: moveableAssetForms[0].tableMapping,
          isDisabled: bankCash.isDisabled,
        },
        {
          title: moveableAssetForms[1].text,
          mapping: moveableAssetForms[1].tableMapping,
          isDisabled: vehicle.isDisabled,
        },
        {
          title: moveableAssetForms[2].text,
          mapping: moveableAssetForms[2].tableMapping,
          isDisabled: investment.isDisabled,
        },
        {
          title: moveableAssetForms[3].text,
          mapping: moveableAssetForms[3].tableMapping,
          isDisabled: retirement.isDisabled,
        },
        {
          title: moveableAssetForms[4].text,
          mapping: moveableAssetForms[4].tableMapping,
          isDisabled: otherMovable.isDisabled,
        },
      ],
    },
    {
      containerTitle: 'Immovable Asset',
      containerDisabled: property.isDisabled,
      containerRows: immoveableTotalRows,
      containerAmount: immoveableTotalAmount,
      tables: [
        {
          title: immoveableAssetForms[0].text,
          mapping: immoveableAssetForms[0].tableMapping,
          isDisabled: property.isDisabled,
        },
      ],
    },
    {
      containerTitle: 'Financial Commitment',
      containerDisabled: loan.isDisabled,
      containerRows: financialTotalRows,
      containerAmount: financialTotalAmount,
      tables: [
        {
          title: financialCommitmentForms[0].text,
          mapping: financialCommitmentForms[0].tableMapping,
          isDisabled: loan.isDisabled,
        },
      ],
    },
    {
      containerTitle: 'Other Source Of Income',
      containerDisabled: owner.isDisabled && director.isDisabled,
      containerRows: otherSourceTotalRows,
      containerAmount: otherSourceTotalAmount,
      tables: [
        {
          title: otherSourceForms[0].text,
          mapping: otherSourceForms[0].tableMapping,
          isDisabled: owner.isDisabled,
        },
        {
          title: otherSourceForms[1].text,
          mapping: otherSourceForms[1].tableMapping,
          isDisabled: director.isDisabled,
        },
      ],
    },
  ]

  return (
    <Paper p={40}>
      <ReviewHeadings
        isSubmitted={isSubmitted}
        financialYear={financialYear}
        date={date}
        assetRevisionData={assetRevisionData}
      />
      <ReviewDetails
        data={identityData}
        totalAsset={
          moveableTotalRows + immoveableTotalRows + financialTotalRows + otherSourceTotalRows
        }
        totalAmount={
          moveableTotalAmount +
          immoveableTotalAmount +
          financialTotalAmount +
          otherSourceTotalAmount
        }
        showAmount={showAmount}
      />
      <Divider
        mt={24}
        mb={24}
      />
      {reviewMappings.map(
        ({ containerDisabled, containerRows, containerTitle, containerAmount, tables }) => {
          return containerDisabled ? null : (
            <ReviewTableContainer
              key={containerTitle}
              title={containerTitle}
              totalRows={containerRows}
              totalAmount={containerAmount}
              showAmount={showAmount}
            >
              {tables.map(({ title, mapping, isDisabled }) => {
                return (
                  <RenderReviewTable
                    key={title}
                    form={form}
                    isDisabled={isDisabled}
                    mapping={mapping}
                    title={title}
                    showAmount={showAmount}
                  />
                )
              })}
            </ReviewTableContainer>
          )
        }
      )}
    </Paper>
  )
}

export default Review
