// Allows developer to create dynamic icon with description placeholder
// By default the widget will render empty component
import { useTranslation } from 'react-i18next'

import { Stack, Text } from '@mantine/core'

import { NoDataIcon } from 'assets/svg'

const DynamicEmptyData = ({
  message = 'No Data',
  icon = <NoDataIcon />,
  iconTitle = '',
  hasData = false,
  children,
  px = 0,
  py = 0,
}) => {
  const { t } = useTranslation(['common'])

  return (
    <>
      {hasData ? (
        children
      ) : (
        <Stack
          align="center"
          spacing={8}
          px={px}
          py={py}
        >
          {icon}
          <Text
            weight={700}
            size={18}
          >
            {t(iconTitle)}
          </Text>
          <Text>{message}</Text>
        </Stack>
      )}
    </>
  )
}

export default DynamicEmptyData
