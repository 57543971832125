import { useRef, useState } from 'react'

import { useStyle } from 'view/style'

const { Table, ScrollArea, Box, Group } = require('@mantine/core')

const TableContainerWithScroll = ({
  scrollMaxHeight,
  children,
  highlightOnHover = false,
  tableHeader,
  numberOfRows,
  showFooterShadow = true,
}) => {
  const { classes, cx } = useStyle()
  const viewportRef = useRef()
  const [scrolled, setScrolled] = useState(false)
  const [reachedBottom, setReachedBottom] = useState(false)
  return (
    <Box className={classes.tableContainer}>
      <ScrollArea
        sx={{ height: scrollMaxHeight, borderRadius: 'inherit' }}
        viewportRef={viewportRef}
        onScrollPositionChange={({ y }) => {
          setReachedBottom(viewportRef.current.scrollHeight - scrollMaxHeight - y <= 10)
          setScrolled(y !== 0)
        }}
        scrollbarSize={10}
      >
        <Table
          highlightOnHover={highlightOnHover}
          className={classes.tableSize}
          horizontalSpacing="sm"
          verticalSpacing="sm"
        >
          <thead className={cx(classes.tableHeader, { [classes.scrolledDown]: scrolled })}>
            <tr>
              {tableHeader.map(({ name, align, hide = false }) =>
                hide ? null : (
                  <th
                    key={name}
                    style={{ textAlign: align }}
                  >
                    {name}
                  </th>
                )
              )}
            </tr>
          </thead>
          {children}
        </Table>
      </ScrollArea>
      <Group
        className={cx(classes.tableFooter, {
          [classes.scrolledUp]: !reachedBottom && numberOfRows > 5 && showFooterShadow,
        })}
      />
    </Box>
  )
}

export default TableContainerWithScroll
