import { Stack, Paper, Text, Group, Skeleton } from '@mantine/core'

import dayjs from 'dayjs'

const ALDeclarationCycle = ({ data, isLoading }) => {
  return (
    <Paper
      radius={4}
      p={24}
      withBorder
    >
      <Stack>
        <Group position="apart">
          <Text
            size={16}
            fw={600}
          >
            Declaration Cycle
          </Text>
          {/* <ActionIcon>
            <HambergerDotIcon />
          </ActionIcon> */}
        </Group>
        <Skeleton visible={isLoading}>
          <Text
            size={32}
            fw={600}
          >
            {data?.daysLeft} days left
          </Text>
          <Text
            size={14}
            fw={400}
            color="#475467"
          >
            {`${dayjs(data?.formSubmissionOpeningDate).format('DD MMM YYYY')} - ${dayjs(
              data?.formSubmissionClosingDate
            ).format('DD MMM YYYY')}`}
          </Text>
        </Skeleton>
      </Stack>
    </Paper>
  )
}

export default ALDeclarationCycle
