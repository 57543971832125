import { Button, Group, Stack, Table, Text, Divider, Box, ScrollArea, List } from '@mantine/core'

import { ExchangeRateBanner } from 'view/components'
import { useStyle } from 'view/style'

import {
  IMMOVABLE_MODAL_TO_DECLARE,
  IMMOVABLE_MODAL_NOT_TO_DECLARE,
  IMMOVABLE_MODAL_NOTE,
} from 'util/textMapping'

import CustomModalContainer from './CustomModalContainer'

export default function LearnImmoveableModal({ isOpen, handleClose }) {
  const { classes } = useStyle()

  return (
    <CustomModalContainer
      title="Immovable Asset"
      opened={isOpen}
      onClose={handleClose}
      size={800}
      modalPadding={20}
    >
      <ScrollArea
        style={{ height: 660 }}
        offsetScrollbars
      >
        <ExchangeRateBanner />
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text
              size={14}
              fw={500}
            >
              Property
            </Text>
            <Stack spacing={0}>
              <Text size={14}>
                All types of property (Residential, Commercial, Agricultural, Industrial) purchased
                or received as gifts, inheritance or winnings.
              </Text>
            </Stack>
          </Stack>
          <Box className={classes.tableContainer}>
            <Table
              sx={{ th: { width: 50 } }}
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>To Declare</th>
                  <th>Not to Declare</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td>
                    <Text>Property owned/partly owned by employee including:</Text>
                    <List
                      size={14}
                      withPadding
                    >
                      {IMMOVABLE_MODAL_TO_DECLARE.map((text) => (
                        <List.Item key={text}>
                          <Text size={14}>{text}</Text>
                        </List.Item>
                      ))}
                    </List>
                  </td>
                  <td>
                    <Text>Not To Declare</Text>
                    <List
                      size={14}
                      withPadding
                    >
                      <List.Item>Rent-to-own property</List.Item>
                    </List>
                    <br />
                    <Text>Installation to the immovable assets such as:</Text>
                    <List
                      size={14}
                      withPadding
                    >
                      {IMMOVABLE_MODAL_NOT_TO_DECLARE.map((text) => (
                        <List.Item key={text}>
                          <Text size={14}>{text}</Text>
                        </List.Item>
                      ))}
                    </List>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Stack>
          <Text
            size={14}
            fs="italic"
          >
            Note:
            <br />
            <List listStyleType="decimal">
              {IMMOVABLE_MODAL_NOTE.map((text) => (
                <List.Item key={text}>
                  <Text
                    size={14}
                    italic
                    style={{ maxWidth: '720px' }}
                  >
                    <span style={{ whiteSpace: 'pre-line' }}>
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </span>
                    <br />
                  </Text>
                </List.Item>
              ))}
            </List>
          </Text>
        </Stack>
      </ScrollArea>
      <Group
        pt={20}
        position="right"
      >
        <Button
          variant="default"
          onClick={handleClose}
          aria-label="closeLearnImmovable"
        >
          Close
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
