import { routes, excludedPath } from 'config/routeConfig'

import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { Breadcrumbs as MantineBreadcrumbs, Anchor, Paper, Button } from '@mantine/core'

import { ChevronLeft } from 'assets/svg'

const Breadcrumbs = ({ breadcrumbs }) => {
  const location = useLocation()
  const history = useHistory()

  const items = breadcrumbs.map((item, index) => {
    // assigning home to the default link of /assets
    const defaultLink = index === 0 ? '/assets' : item.key

    // skip home if in admin page
    if (location.pathname.includes('/admin') && index === 0) return null

    if (location.pathname.includes('/investigate')) return null

    //For admin we need to manually configure it path and name
    return (
      <Anchor
        color={index === breadcrumbs.length - 1 && 'dark'}
        size={12}
        weight={500}
        component={Link}
        to={item.key === '/admin' ? '/admin/dashboard' : defaultLink}
        key={item.key}
      >
        {item.breadcrumb['props']['children'] === 'Admin' ? 'Dashboard' : item.breadcrumb}
      </Anchor>
    )
  })
  return (
    <>
      {!excludedPath.includes(location.pathname) ? (
        <Paper
          mb="-5px"
          p={26}
        >
          <MantineBreadcrumbs>{items}</MantineBreadcrumbs>
        </Paper>
      ) : null}
      {location.pathname.includes('/trust-and-safety') ? (
        <Paper
          mb="-5px"
          p={26}
        >
          <Button
            variant="default"
            onClick={() => history.push('assets')}
            leftIcon={<ChevronLeft />}
            aria-label="back-button"
          >
            Back
          </Button>
        </Paper>
      ) : null}
    </>
  )
}

export default withBreadcrumbs(routes)(Breadcrumbs)
