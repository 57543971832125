import { useMemo, useCallback, useEffect, memo } from 'react'
import ReactPlayer from 'react-player'

import { Timeline, Text, List, Image } from '@mantine/core'

import { useStyle } from 'view/style'

import VideoImage from 'assets/images/GuideVideoCover.png'
import VideoContent from 'assets/videos/GuideVideo.mp4'

const TIMELINE_CONTENT = [
  {
    title: null,
    children: (
      <ReactPlayer
        url={VideoContent}
        controls={true}
        playing={true}
        light={
          <Image
            withPlaceholder
            src={VideoImage}
            alt="Thumbnail"
          />
        }
      />
    ),
  },
  {
    title: 'Why do I need to declare my assets?',
    children: (
      <Text>
        {`The implementation of Asset Declaration is part of PETRONAS' efforts in maintaining integrity and preventing corruption. This duty to declare asset is contained in PETRONAS Code of Conduct and Business Ethics (CoBE) Country Supplement: Malaysia, Part II: Duties of Good Faith, Fidelity, Diligence and Integrity. `}
      </Text>
    ),
  },
  {
    title: 'Who are required to declare their assets?',
    children: (
      <Text>
        All employees (permanent and contract direct hire) of the PETRONAS Group as provided under
        Code of Conduct and Business Ethics (CoBE) and the applicable Country Supplement.
      </Text>
    ),
  },
  {
    title: 'What are the assets I need to declare?​',
    children: (
      <Text>
        The assets you need to declare are:
        <List type="ordered">
          <List.Item>Movable assets such as cash savings, vehicles, jewellery</List.Item>
          <List.Item>Immovable assets such as property</List.Item>
          <List.Item>Financial commitment</List.Item>
          <List.Item>Business ownership or directorship of companies</List.Item>
        </List>
      </Text>
    ),
  },
  {
    title: 'When should I declare my assets?',
    children: (
      <Text>
        You will need to submit your Asset Declaration within sixty (60) days from the date you
        receive the email notification from the Company.
      </Text>
    ),
  },
]

const TimelineContent = memo(() => {
  const { classes } = useStyle()

  const handleUnmount = useCallback(() => {
    // Clean up the ReactPlayer component when it unmounts
    ReactPlayer.canPlay = () => false
  }, [])

  useEffect(() => {
    return handleUnmount
  }, [handleUnmount])

  const memoizedTimelineItems = useMemo(
    () =>
      TIMELINE_CONTENT.map(({ title, children }, index) => (
        <Timeline.Item
          key={title + index}
          title={title}
          m={20}
          pb={40}
        >
          {children}
        </Timeline.Item>
      )),
    []
  )

  return (
    <Timeline
      styles={{
        itemTitle: {
          fontSize: '16px !important',
        },
      }}
      active={TIMELINE_CONTENT.length}
      bulletSize={24}
      lineWidth={5}
      classNames={{ itemTitle: classes.timelineTitle, itemContent: classes.timelineBody }}
    >
      {memoizedTimelineItems}
    </Timeline>
  )
})

export default TimelineContent
