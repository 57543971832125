import { Stack } from '@mantine/core'

import FMTable from './FMTable'

const FMPanelTemplate = ({
  addButtonLabel,
  isLoading,
  isFetching,
  isSuccess,
  data,
  tableConfig,
  apiAddHandler,
  apiUpdateHandler,
}) => {
  return (
    <Stack>
      <FMTable
        apiState={{ isLoading, isFetching, isSuccess }}
        data={data?.content}
        tableConfig={tableConfig}
        addButtonLabel={addButtonLabel}
        onAdd={apiAddHandler}
        onEdit={apiUpdateHandler}
      />
    </Stack>
  )
}

export default FMPanelTemplate
