import { FINANCIAL_TYPE_DROPDOWN } from './tableMapping'

const FMBANK_MAP = {
  modalTitle: 'Institution Name',
  queryKey: 'name',
  tableName: 'bankCashes',
  headerKeys: ['name', 'active'],
  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Institution Name',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMVEHICLEBRAND_MAP = {
  modalTitle: 'Vehicle Brand',
  queryKey: 'name',
  tableName: 'vehicleBrands',
  headerKeys: ['name', 'types', 'active'],

  fields: {
    name: {
      maxWidth: 100,
      headerName: 'Vehicle Brand',
      inputType: 'text',
      mandatory: true,
    },
    types: {
      maxWidth: 200,
      headerName: 'Type',
      inputType: 'multiselect',
      mandatory: false,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
    types: [],
  },
}

const FMPROPERTYTYPE_MAP = {
  modalTitle: 'Property Type',
  queryKey: 'name',
  tableName: 'properties',
  headerKeys: ['name', 'active'],

  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Type of property',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMPROPERTYLOCATION_MAP = {
  modalTitle: 'Property Location',
  queryKey: 'name',
  tableName: 'properties',
  headerKeys: ['name', 'active'],

  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Area',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMVEHICLETYPE_MAP = {
  modalTitle: 'Vehicle Type',
  queryKey: 'name',
  tableName: 'vehicleType',
  headerKeys: ['name', 'active'],

  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Vehicle Type',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMINVESTMENTTYPE_MAP = {
  modalTitle: 'Investment Type',
  queryKey: 'name',
  tableName: 'investmentType',
  headerKeys: ['name', 'active'],

  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Investment Type',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMRETIREMENTFUND_MAP = {
  modalTitle: 'Retirement Fund',
  queryKey: 'name',
  tableName: 'retirementFund',
  headerKeys: ['name', 'active'],
  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Retirement Fund',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMLOANTYPE_MAP = {
  modalTitle: 'Loan Type',
  queryKey: 'name',
  tableName: 'loanType',
  headerKeys: ['name', 'active'],
  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Loan Type',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMOTHERMOVEABLE_MAP = {
  modalTitle: 'Other Movable Asset',
  queryKey: 'name',
  tableName: 'otherMoveable',
  headerKeys: ['name', 'active'],
  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Other Movable Asset',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

const FMLOANINSTITUTION_MAP = {
  modalTitle: 'Loan Institution',
  queryKey: 'name',
  tableName: 'otherMoveable',
  headerKeys: ['name', 'type', 'active'],
  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Name of Institution',
      inputType: 'text',
      mandatory: true,
    },
    type: {
      headerName: 'Type of Institution',
      inputType: 'dropdown',
      mandatory: true,
      dropdownSource: 'local',
      dropdownData: FINANCIAL_TYPE_DROPDOWN,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    type: '',
    active: true,
  },
}

const FMACQUISITIONTYPE_MAP = {
  modalTitle: 'Asset Acquisition Type',
  queryKey: 'name',
  tableName: 'acquisitionType',
  headerKeys: ['name', 'active'],
  fields: {
    name: {
      maxWidth: 835,
      headerName: 'Asset Acquisition Type',
      inputType: 'text',
      mandatory: true,
    },
    active: {
      headerName: 'Status',
      inputType: 'switch',
      mandatory: true,
    },
  },
  defaultValue: {
    name: '',
    active: true,
  },
}

export {
  FMBANK_MAP,
  FMVEHICLEBRAND_MAP,
  FMPROPERTYTYPE_MAP,
  FMPROPERTYLOCATION_MAP,
  FMVEHICLETYPE_MAP,
  FMINVESTMENTTYPE_MAP,
  FMRETIREMENTFUND_MAP,
  FMLOANTYPE_MAP,
  FMOTHERMOVEABLE_MAP,
  FMLOANINSTITUTION_MAP,
  FMACQUISITIONTYPE_MAP,
}
