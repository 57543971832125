import { Stack, Table, Text } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'

export default function EditModal({ onEditSubmit, title, value }) {
  //Temporary solution - render all the list of typs inside a table
  const RenderTable = ({ newValue }) => {
    const rows = newValue.map((element) => (
      <tr key={element.label}>
        <td>{element.label}</td>
      </tr>
    ))

    if (!newValue.length) return null
    return (
      <>
        <Text size="sm">Adding below types under brand</Text>
        <Table
          striped
          withBorder
        >
          <thead>
            <tr>
              <th>List of Type</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </>
    )
  }

  const RenderText = () => {
    return value?.map(({ current, newValue, key }) => {
      if (key === 'types') {
        return (
          <RenderTable
            key={current.label}
            newValue={newValue}
          />
        )
      } else {
        return current !== newValue ? (
          <Text
            key={current}
            size="sm"
          >
            {`Are you sure you want to change ${key === 'active' ? 'status from' : ''}`}
            <Text
              span
              fw={500}
              inherit
            >
              {` ${current} `}
            </Text>
            to
            <Text
              span
              fw={500}
              inherit
            >
              {` ${newValue} `}
            </Text>
            ?
          </Text>
        ) : null
      }
    })
  }

  return openConfirmModal({
    title: `Edit ${title}`,
    centered: true,
    closeButtonLabel: 'confirm-button',
    children: (
      <Stack>
        <RenderText />
      </Stack>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    confirmProps: { color: 'brand' },
    onConfirm: async () => await onEditSubmit(),
  })
}
