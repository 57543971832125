import { Box, Paper, Group, Stack, Text, List } from '@mantine/core'

const privacyStatementData = [
  {
    contentTitle: 'PETRONAS PRIVACY STATEMENT',
    children: (
      <Stack>
        <Text>
          Petroliam Nasional Berhad (PETRONAS) 197401002911 (20076-K) and its group of companies,
          (“PETRONAS” or “We”) are committed to protecting and respecting your privacy.
        </Text>
        <Text>
          This Privacy Statement (“Privacy Statement”) explains what personal data we collect when
          and why we collect it, how we use it, the conditions under which we may disclose it to
          others, and how we keep it secure. Please read it carefully to understand our views and
          practices regarding your personal data and how we will treat it.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'WHO WE ARE',
    children: (
      <Stack>
        <Text>
          PETRONAS is Malaysia’s fully integrated oil and gas multinational with its parent company
          registered in Malaysia.
        </Text>
        <Text>
          Our address is Tower 1, PETRONAS Twin Towers, Kuala Lumpur City Centre, 50088 Kuala
          Lumpur, Malaysia.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'INFORMATION GATHERING AND USAGE',
    children: (
      <Stack>
        <Text>
          The implementation of Asset Declaration is part of PETRONAS’ efforts in maintaining
          integrity and preventing corruption. This duty to declare asset is contained in PETRONAS
          Code of Conduct and Business Ethics (CoBE) Country Supplement: Malaysia, Part II: Duties
          of Good Faith, Fidelity, Diligence and Integrity
        </Text>

        <Text>
          <Text fw={700}>Processing your personal data </Text>
          The basis for processing your personal data pursuant to this Asset Declaration based on
          the duty to declare asset as contained in PETRONAS Code of Conduct and Business Ethics
          (CoBE) Country Supplement: Malaysia, Part II: Duties of Good Faith, Fidelity, Diligence
          and Integrity Storage of your information
        </Text>
        <Text>
          All information you provide to us is stored in a database that is hosted within PETRONAS
          secure Cloud system. We maintain appropriate administrative, technical and physical
          safeguards to protect against loss, misuse or unauthorized access, disclosure, alteration
          or destruction of the personal data you provide to us in compliance with applicable laws.
        </Text>
        <Text>
          Where we have given you (or where you have chosen) a password which enables you to access
          certain parts of our site, you are responsible for keeping this password confidential. We
          ask you not to share a password with anyone.
        </Text>

        <Text>
          <Text fw={700}>Period for which we store your personal data. </Text>
          All record is retained for seven (7) years from the submission date.
        </Text>

        <Text>
          <Text fw={700}>Transfers of your information </Text>
          We may transfer your personal data to, or store it in, a destination outside of the
          jurisdiction of the entity to which you provided it. This is because PETRONAS is a global
          business, with operations located in various jurisdictions, which uses third party service
          providers based in a number of locations worldwide.
        </Text>

        <Text>
          <Text fw={700}>Information Sharing </Text>
          Information you have provided under this declaration is restricted to a single Super
          Admin. However, we may share your information to Government agencies, law enforcement
          agencies, courts, or tribunals, if required or authorized to do so, to satisfy any
          applicable law, regulation, order or judgment of a court or tribunal or queries from the
          relevant authorities. We will not otherwise use, share, disseminate, publish or disclose
          your personal data except as may be required in response to litigation, investigations or
          other legally required disclosures or to protect our rights.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'SECURITY COMMITMENT',
    children: (
      <Stack>
        <Text>
          myAsset is committed to the security of users’ and their data. The myAsset portal which
          undergoes periodic penetration testing, is designed to be the General Data Protection
          Regulation (GDPR) compliant with the privacy standards which is the General Data
          Protection Regulation (GDPR) adopted in the European Union, and users’ data is always
          encrypted.
        </Text>
        <Text>
          myAsset employs industry-standard security framework to ensure your data is collected and
          stored safely. Rest assured that your data is collected strictly in compliance with
          PETRONAS Code of Conduct and Business Ethics and Personal Data Protection Act 2010.
        </Text>
        <Text>
          We have reasonable security measures in place to help protect against the loss, misuse and
          alteration of the information under our control. While we cannot guarantee that loss,
          misuse or alteration to data will not occur, we ensure that our systems adhere to market
          security standard so as to help safeguard against such occurrences.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'YOUR RIGHTS TO YOUR PERSONAL DATA',
    children: (
      <Stack>
        <Text>
          You have the following rights:
          <List withPadding>
            <Stack>
              <List.Item>
                Access
                <Text>
                  As a user, you have the right to access and update your personal information at
                  any time. However, it is important to note that submission of this information is
                  only permitted during the approved time frame specified by PETRONAS. PETRONAS
                  reserves the right to regulate the submission process to ensure efficient and
                  effective management of user data.
                </Text>
              </List.Item>

              <List.Item>
                Rectification
                <Text>
                  Should you have mistakenly entered inaccurate information, you may rectify it by
                  sending an email to our support team assetdeclaration@petronas.com. Kindly state
                  your reason and notify us any relevant details about the information you wish to
                  correct, and our team will promptly review your request. You will be notified once
                  access is granted for you to submit a new declaration within the approved time
                  frame specified by PETRONAS.
                </Text>
              </List.Item>
            </Stack>
          </List>
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'COOKIES AND SIMILAR TECHNOLOGIES',
    children: (
      <Stack>
        <Text>
          PETRONAS and its group of companies, our affiliates and our third-party providers set and
          use cookies and similar technologies in order to distinguish you from other users of our
          Website, to provide a better experience when you browse our Website, and to improve the
          Website’s performance and usefulness. The use of cookies and similar technologies is
          standard across websites and applications through which information is collected about
          your online activities.
        </Text>
        <Text>
          A cookie is a small data file that websites place on your hard drive when you visit. A
          cookie file can contain information such as a user ID that tracks the pages you’ve visited
          within that site. The cookies on this website are primarily used to recognize that a user
          has visited the website previously and to track user traffic patterns. We do not correlate
          this information with data about individual users, nor do we share this information or
          sell it to any third party.
        </Text>
        <Text>
          <Text fw={700}>Managing Cookies</Text>
          If you prefer not to receive cookies through the Website, you can set your browser to warn
          you before accepting cookies and refuse the cookie when your browser alerts you to its
          presence. You also can refuse all cookies by turning them off in your browser. For more
          information about cookies, including how to set your browser to reject cookies, visit
          www.allaboutcookies.org.
        </Text>
        <Text>
          <Text fw={700}>Cookie Expiration</Text>
          The cookies will remain on your computer after the browser is closed. Until removed, the
          cookies will become active again when the website is reopened. Cookies can be deleted by
          you, at any time, and will not collect any information when you are not accessing the
          website.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'CHANGES TO OUR PRIVACY STATEMENT',
    children: (
      <Stack>
        <Text>
          Any changes we make to our Privacy Statement in the future will be posted on this page
          and, where appropriate, notified to you by e-mail. Please check back frequently to see any
          updates or changes to our Privacy Statement.
        </Text>
      </Stack>
    ),
  },
  {
    contentTitle: 'HOW YOU CAN CONTACT US',
    children: (
      <Stack>
        <Text>
          Any questions, comments and requests regarding this Privacy Statement are welcomed and
          should be addressed to:
        </Text>
        <Text>
          Head Industrial Relations, Industrial Relations Department, Human Capital Expertise, Group
          Human Resource Management
        </Text>
        <Text>
          <Group>
            <Text sx={{ alignSelf: 'flex-start' }}>Address:</Text>
            <Stack spacing={0}>
              <Text>Level 62, Tower 1, PETRONAS Twin Towers,</Text>
              <Text>Kuala Lumpur City Centre, </Text>
              <Text>50088 Kuala Lumpur, Malaysia</Text>
            </Stack>
          </Group>
        </Text>
        <Text>Email Address: assetdeclaration@petronas.com </Text>
      </Stack>
    ),
  },
]

const PrivacyStatementPage = () => {
  return (
    <Box pb={90}>
      <Paper px={102}>
        <Stack spacing={0}>
          {privacyStatementData.map(({ contentTitle, children }) => {
            return (
              <>
                <Paper sx={{ backgroundColor: 'white' }}>
                  <Stack spacing="lg">
                    <Text
                      fw={600}
                      fz={16}
                      color="#00A19C"
                    >
                      {contentTitle}
                    </Text>
                    <Text>{children}</Text>
                  </Stack>
                </Paper>
              </>
            )
          })}
        </Stack>
      </Paper>
    </Box>
  )
}

export default PrivacyStatementPage
