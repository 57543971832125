import { Text } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'

export default function SubmitModal({ onSubmit, title, item }) {
  return openConfirmModal({
    title: `Confirm ${title}`,
    centered: true,
    children: <Text size="sm">{`Are you sure you want to add ${item} to the ${title} list?`}</Text>,
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    confirmProps: { color: 'brand' },
    onConfirm: async () => await onSubmit(),
  })
}
