const SessionStorageKey = 'local-url'

export const storeCurrentPath = () => {
  if (window.location.pathname !== '/')
    window.sessionStorage.setItem(SessionStorageKey, window.location.pathname)
}

export const getStoredPath = () => {
  return window.sessionStorage.getItem(SessionStorageKey)
}

export const clearStoredPath = () => {
  window.sessionStorage.removeItem(SessionStorageKey)
}
