import { PieChart, Pie, Label, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import { Text, Box, Tabs, Center } from '@mantine/core'

import { DynamicEmptyData } from 'view/components'

import DOCustomTooltip from './DOCustomTooltip'

const LegendFormatter = ({ title }) => {
  return <span style={{ marginLeft: 10 }}>{title}</span>
}

const RenderCustomizeLebel = ({ viewBox, totalVal, type }) => {
  const { cx, cy } = viewBox
  return (
    <>
      <text
        x={cx}
        y={cy - 5}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {type.includes('Overview') ? (
          <>
            <tspan
              x={cx}
              y={cy - 30}
              alignmentBaseline="middle"
              fontSize={14}
            >
              Total amount
            </tspan>
            <tspan
              x={cx}
              y={cy - 10}
              alignmentBaseline="middle"
              fontSize={14}
            >
              declared in (MYR)
            </tspan>
          </>
        ) : (
          <tspan
            alignmentBaseline="middle"
            fontSize={14}
          >
            Amount declared in (MYR)
          </tspan>
        )}
      </text>
      <text
        x={cx}
        y={cy + 20}
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontWeight={700}>
          {totalVal.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </tspan>
      </text>
    </>
  )
}

const DOPieChart = ({ pieData }) => {
  const { overview, assets, liabilities } = pieData

  const renderConfig = [
    { ...overview, title: 'Overview', enable: overview?.total },
    { ...assets, title: 'Assets', enable: assets?.total },
    { ...liabilities, title: 'Liabilities', enable: liabilities?.total },
  ]

  return (
    <Box sx={{ border: '1px solid #D3DEE8', borderRadius: 4, padding: 20 }}>
      <Text
        size={20}
        weight={700}
        pb={30}
      >
        Total Assets & Liabilities
      </Text>
      <Tabs
        styles={{
          tabsList: { borderBottom: '0 !important' },
        }}
        defaultValue="Overview"
      >
        <Tabs.List>
          {renderConfig.map(({ title }) => {
            return (
              <Tabs.Tab
                key={title}
                value={title}
                px={20}
                py={16}
              >
                <Text fw={500}>{title}</Text>
              </Tabs.Tab>
            )
          })}
        </Tabs.List>
        {renderConfig.map(({ data, title, total, enable }) => {
          return (
            <Tabs.Panel
              key={title}
              value={title}
            >
              <Center h={400}>
                <DynamicEmptyData
                  message={`No ${title.toLowerCase()} declared`}
                  hasData={enable}
                >
                  <ResponsiveContainer height={400}>
                    <PieChart>
                      <Pie
                        data={data}
                        dataKey="value"
                        innerRadius={100}
                        outerRadius={160}
                        fill="#8884d8"
                        blendStroke
                        paddingAngle={0}
                      >
                        <Label
                          position="center"
                          content={
                            <RenderCustomizeLebel
                              totalVal={total}
                              type={title}
                            />
                          }
                        />
                      </Pie>
                      <Tooltip content={<DOCustomTooltip />} />
                      <Legend
                        verticalAlign="bottom"
                        iconType="circle"
                        iconSize={8}
                        formatter={(value) => LegendFormatter({ title: value })}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </DynamicEmptyData>
              </Center>
            </Tabs.Panel>
          )
        })}
      </Tabs>
    </Box>
  )
}

export default DOPieChart
