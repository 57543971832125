import Big from 'big.js'

import { ScrollArea, Table, Text } from '@mantine/core'
import { useId } from '@mantine/hooks'

import { useStyle } from 'view/style'

// import { findOthersInDropdown } from 'util/helper'
import { ShowAmount } from '.'

const ReviewTable = ({
  bodyValues,
  title,
  headerKeys,
  bodyConfig,
  apiDropdownData,
  showAmount = true,
}) => {
  const { classes } = useStyle()
  const uuid = useId()
  const getRowValue = (inputType, inputConfigs, value, rowValues, apiDropdownData = []) => {
    if (inputType === 'dropdown') {
      if (typeof value === 'string') return value

      if (inputConfigs.dropdownSource === 'api') {
        const { dependantKey } = inputConfigs

        if (dependantKey === 'vehicleTypeId') {
          const { vehicleTypeId } = rowValues
          const tempBrandDropdown =
            apiDropdownData
              .find((val) => val.value === vehicleTypeId)
              ?.brands?.map(({ id, name }) => ({
                value: id,
                label: name,
              })) || []

          const selectedBrand = tempBrandDropdown.find((obj) => obj.value === value)
          return selectedBrand?.label
        } else {
          const selectedOption = apiDropdownData.find((obj) => obj.value === value)
          return selectedOption?.label
        }
      }
      if (inputConfigs.dropdownSource === 'local') {
        return inputConfigs.dropdownData.find((obj) => obj.value === value)?.label
      }
    } else if (inputType === 'currency') {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      })
    } else if (inputType === 'number') return new Big(value).toFixed()
    else return value
  }
  return (
    <>
      <Text
        size={18}
        fw={700}
        pb={16}
        pl={8}
      >
        {title}
      </Text>
      <ScrollArea
        type="always"
        offsetScrollbars
      >
        <Table mb={28}>
          <thead>
            <tr>
              {headerKeys.map((headerKey) => (
                <th
                  key={headerKey}
                  className={
                    bodyConfig[headerKey].align === 'right'
                      ? classes.cellAlignRight
                      : classes.cellAlignLeft
                  }
                >
                  <Text
                    color={'#677C91'}
                    fw={400}
                    size={16}
                  >
                    {bodyConfig[headerKey].headerName}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bodyValues?.length
              ? bodyValues.map((rowValues, index) => {
                  return (
                    <tr key={`${uuid + index}`}>
                      {headerKeys.map((headerKey) => {
                        const getCreateableDropdownKey =
                          rowValues[headerKey] === 0 ? headerKey.replace('Id', 'Name') : headerKey
                        return (
                          <td
                            className={
                              bodyConfig[headerKey].align === 'right'
                                ? classes.cellAlignRight
                                : classes.cellAlignLeft
                            }
                            style={{
                              maxWidth: bodyConfig[headerKey].maxWidth,
                              wordWrap: 'break-word',
                            }}
                            key={headerKey}
                          >
                            <ShowAmount
                              show={
                                showAmount ||
                                (bodyConfig[headerKey].inputType !== 'currency' && !showAmount)
                              }
                            >
                              <Text
                                fw={500}
                                size={16}
                              >
                                {getRowValue(
                                  bodyConfig[headerKey].inputType,
                                  bodyConfig[headerKey],
                                  rowValues[getCreateableDropdownKey],
                                  rowValues,
                                  apiDropdownData?.[headerKey]
                                )}
                              </Text>
                            </ShowAmount>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              : null}
          </tbody>
        </Table>
      </ScrollArea>
    </>
  )
}

export default ReviewTable
