import i18next from 'i18next'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from '@mantine/core'

const LanguagePicker = () => {
  const { i18n } = useTranslation(['common'])

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')?.length > 2) {
      i18next.changeLanguage('en')
    }
  }, [])
  return (
    <Select
      py={10}
      value={localStorage.getItem('i18nextLng')}
      data={[
        { value: 'en', label: 'English' },
        { value: 'my', label: 'Malay' },
      ]}
      onChange={(e) => i18n.changeLanguage(e)}
    />
  )
}

export default LanguagePicker
