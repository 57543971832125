import { first } from 'lodash'

import { useState, useRef, useMemo } from 'react'

import { Table, ScrollArea, Group, Box, Text } from '@mantine/core'
import { useId } from '@mantine/hooks'

import { ADRow, PlusButton } from 'view/components'
import { useStyle } from 'view/style'

import { findOthersInDropdown } from 'util/helper'

import ADExampleRow from './ADExampleRow'

const RenderExample = ({ tableData, tableConfig }) => {
  //Render example row if there is no data
  const emptyState =
    tableData.length && tableData.length < 1 ? Object.values(first(tableData)).includes('') : false

  if (tableData?.length < 1 || emptyState) {
    return <ADExampleRow tableConfig={tableConfig} />
  } else {
    return null
  }
}

const getExcludedDropdown = (dropdownObject) => {
  const newArray = []
  Object.keys(dropdownObject).forEach((category) => {
    return Object.keys(dropdownObject[category]).forEach((tableKey) => {
      if (findOthersInDropdown(dropdownObject[category][tableKey])) return newArray.push(tableKey)
    })
  })
  return newArray
}

const ADTable = ({ addLabel, form, tableConfig, tableData, apiDropdownData = {}, children }) => {
  const uuid = useId()
  const { classes, cx } = useStyle()
  const viewportRef = useRef()
  const scrollMaxHeight = tableData?.length > 5 && 310
  const [scrolled, setScrolled] = useState(false)
  const [newRowActive, setIsNewRowActive] = useState(false)
  const [tableBeingEdited, setTableBeingEdited] = useState(false)
  const addRow = () => {
    setIsNewRowActive(true)
    setTimeout(function () {
      viewportRef.current?.scrollTo({
        top: viewportRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }, 100)
  }
  const excludedDropdownKeys = useMemo(
    () => getExcludedDropdown(apiDropdownData),
    [apiDropdownData]
  )

  return (
    <Box className={classes.tableContainer}>
      <ScrollArea
        type="always"
        sx={{ height: scrollMaxHeight, borderRadius: 'inherit' }}
        viewportRef={viewportRef}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          sx={{ minWidth: tableConfig['headerKeys'].length > 2 ? 1200 : 300 }}
          className={classes.tableSize}
          horizontalSpacing="sm"
          verticalSpacing="sm"
        >
          <thead className={cx(classes.tableHeader, { [classes.scrolledDown]: scrolled })}>
            <tr>
              <th>No</th>
              {tableConfig['headerKeys'].map((index) => {
                const { headerName, align, mandatory, headerWidth } = tableConfig['fields'][index]
                return (
                  <th
                    key={headerName}
                    style={{
                      maxWidth: headerWidth,
                      textAlign: align,
                    }}
                  >
                    {headerName}
                    {mandatory ? (
                      <Text
                        span
                        color="red"
                      >
                        *
                      </Text>
                    ) : null}
                  </th>
                )
              })}
              <th
                className={classes.stickyColumn}
                style={{ backgroundColor: '#f4f6fa' }}
              ></th>
            </tr>
          </thead>
          <tbody className={classes.tableBody}>
            <RenderExample
              tableData={tableData}
              tableConfig={tableConfig}
            />
            {tableData.map((rowItems, rowIndex) => (
              <ADRow
                form={form}
                rowItems={rowItems}
                rowIndex={rowIndex}
                key={`${uuid + rowItems}`}
                tableConfig={tableConfig}
                apiDropdownData={apiDropdownData}
                setTableBeingEdited={setTableBeingEdited}
                tableBeingEdited={tableBeingEdited}
                excludedDropdownKeys={excludedDropdownKeys}
              >
                {children}
              </ADRow>
            ))}
            {newRowActive ? (
              <ADRow
                form={form}
                rowItems={tableConfig['defaultValue']}
                rowIndex={tableData.length}
                key={tableData.length}
                tableConfig={tableConfig}
                apiDropdownData={apiDropdownData}
                isNewRow={newRowActive}
                setIsNewRow={setIsNewRowActive}
                excludedDropdownKeys={excludedDropdownKeys}
              >
                {children}
              </ADRow>
            ) : null}
          </tbody>
        </Table>
      </ScrollArea>

      <Group
        position="right"
        className={cx(classes.tableFooter, {
          [classes.scrolledUp]: !scrolled && tableData?.length > 5,
        })}
      >
        <PlusButton
          disable={newRowActive}
          onClick={addRow}
          customText={addLabel}
          ariaLabel={'addRow' + tableConfig['tableName']}
        />
      </Group>
    </Box>
  )
}

export default ADTable
