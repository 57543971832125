import {
  useGetMDLoanTypeQuery,
  useCreateMDLoanTypeMutation,
  useUpdateMDLoanTypeMutation,
} from 'store/api'

import { FMLOANTYPE_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMLoanTypePanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDLoanTypeQuery({
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  })
  const [addLoanTypeName] = useCreateMDLoanTypeMutation()
  const [updateLoanTypeName] = useUpdateMDLoanTypeMutation()
  return (
    <FMPanelTemplate
      addButtonLabel={`Add Loan Type`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMLOANTYPE_MAP}
      apiAddHandler={addLoanTypeName}
      apiUpdateHandler={updateLoanTypeName}
    />
  )
}

export default FMLoanTypePanel
