import { ActionIcon, Group, Menu } from '@mantine/core'

import { EditIcon, PencilIcon, TickIcon, TrashIcon, XIcon } from 'assets/svg'

const ADRenderIcon = ({
  disabledMoreIcon,
  tickAction,
  xAction,
  editable,
  isNewRow,
  editAction,
  deleteAction,
  index,
  tableName,
  disableTickButton = false,
  disableXButton = false,
}) => {
  return isNewRow || editable ? (
    <Group
      position="center"
      w={100}
    >
      <ActionIcon
        disabled={disableTickButton}
        onClick={() => tickAction()}
        aria-label={`tickAction${index}`}
      >
        <TickIcon disabled={disableTickButton} />
      </ActionIcon>
      <ActionIcon
        disabled={disableXButton}
        onClick={() => xAction()}
        aria-label={`xAction${index}`}
      >
        <XIcon color={disableXButton ? 'gray' : null} />
      </ActionIcon>
    </Group>
  ) : (
    <Group position="right">
      <Menu
        withArrow
        position="left-start"
        transition="pop"
        transitionDuration={150}
        withinPortal
      >
        <Menu.Target>
          <ActionIcon
            aria-label={`${tableName}menu${index}`}
            disabled={disabledMoreIcon}
          >
            <EditIcon disabled={disabledMoreIcon} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            icon={<PencilIcon />}
            onClick={() => editAction()}
            aria-label={`editAction${index}`}
          >
            Edit
          </Menu.Item>
          <Menu.Item
            icon={<TrashIcon />}
            onClick={() => deleteAction()}
            aria-label={`deleteAction${index}`}
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}

export default ADRenderIcon
