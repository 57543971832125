import { useGetSubmissionAnalysisQuery, useLazyGetDownloadSubmissionReportQuery } from 'store/api'

import { memo } from 'react'

import { Stack, Title, Paper, SimpleGrid, Button, Group, Menu } from '@mantine/core'

import { DownloadIcon } from 'assets/svg'

import dayjs from 'dayjs'

import ALDeclarationCycle from './ALDeclarationCycle'
import ALSubmissionStats from './ALSubmissionStats'
import Breakdown from './Breakdown'

const AdminLandingPage = () => {
  const { data, isLoading } = useGetSubmissionAnalysisQuery()
  const [trigger] = useLazyGetDownloadSubmissionReportQuery()

  const downloadSubmissionReport = async (currentyear) => {
    //Take current year as for now- future enhancement can choose between different years as mentioned in US
    // await trigger({ year: dayjs(new Date()).format('YYYY') })
    await trigger({ year: currentyear })
  }

  return (
    <Paper mb={150}>
      <Group
        position="apart"
        mb={24}
      >
        <Stack spacing={0}>
          <Title
            order={3}
            weight={700}
          >
            Organization overview
          </Title>
          <Title
            order={6}
            weight={400}
          >
            {`Declaration Year ${dayjs().get('year')}`}
          </Title>
        </Stack>

        <Menu
          shadow="md"
          width={200}
          withArrow
          withinPortal
        >
          <Menu.Target>
            <Button leftIcon={<DownloadIcon />}>Download Submission Report</Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() => downloadSubmissionReport(dayjs().get('year'))}
              target="_blank"
            >
              {dayjs().get('year')} Submission Report
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              onClick={() => downloadSubmissionReport(dayjs().subtract(1, 'year').format('YYYY'))}
              target="_blank"
            >
              {dayjs().subtract(1, 'year').format('YYYY')} Submission Report
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>

      <SimpleGrid
        cols={2}
        breakpoints={[
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'sm', cols: 1 },
        ]}
      >
        <ALSubmissionStats
          data={data}
          isLoading={isLoading}
        />
        <ALDeclarationCycle
          data={data?.declarationCycle}
          isLoading={isLoading}
        />
      </SimpleGrid>
      <Breakdown tableData={data?.businessBreakDown} />
    </Paper>
  )
}

export default memo(AdminLandingPage)
