import { useState, memo } from 'react'

import { Group, Text, UnstyledButton, Title } from '@mantine/core'

import { ChevronLeft } from 'assets/svg'

import BreakdownTable from './BreakdownTable'

const RenderTable = memo(({ tableData = [], companyBreakdown, handleRowClick }) => {
  const { type, render } = companyBreakdown

  const data =
    render && Array.isArray(tableData)
      ? tableData.find((e) => e.businessUnit === type)?.companyBreakDown
      : tableData

  const header = [
    { name: 'No', align: 'left' },
    { name: render ? 'Company' : 'Business', align: 'left' },
    { name: 'Submission', align: 'right' },
    { name: 'Number Declaration', align: 'right' },
  ]

  return (
    <BreakdownTable
      tableData={data}
      tableHeader={header}
      onRowClick={handleRowClick}
      isCompanyBreakdown={render}
    />
  )
})

const Breakdown = ({ tableData }) => {
  const [companyBreakdown, setCompanyBreakdown] = useState({ render: false, type: null })

  const handleRowClick = (rowVal) => {
    setCompanyBreakdown({ render: true, type: rowVal })
  }

  return (
    <>
      <Group
        py={24}
        position="apart"
      >
        <Title
          order={3}
          weight={700}
        >
          {`Breakdown of ${companyBreakdown.render ? companyBreakdown.type : 'Businesses'}`}
        </Title>
        {companyBreakdown.render && (
          <UnstyledButton onClick={() => setCompanyBreakdown({ render: false, type: null })}>
            <Group
              aria-label="breakdownBack-Button"
              sx={{ color: '#00847C' }}
              spacing={10}
            >
              <ChevronLeft />
              <Text size="sm">View Breakdown of Businesses</Text>
            </Group>
          </UnstyledButton>
        )}
      </Group>
      <RenderTable
        tableData={tableData}
        handleRowClick={handleRowClick}
        companyBreakdown={companyBreakdown}
        key={companyBreakdown.type}
      />
    </>
  )
}

export default Breakdown
