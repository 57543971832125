import { Paper, Stack, Text } from '@mantine/core'

const TooltipBody = ({ children }) => {
  return (
    <Paper
      p={10}
      sx={{ backgroundColor: 'black', minWidth: '5rem' }}
    >
      <Stack
        spacing={4}
        sx={{ color: 'white' }}
      >
        {children}
      </Stack>
    </Paper>
  )
}
const DOCustomTooltip = ({ active, payload, type, isHover }) => {
  if (!active || !payload || !payload.length) return null

  const { name, value } = payload[0]

  //Check if the type BAR - Hence, render custom tooltip configuration | otherwise render pie configuration
  if (type === 'bar') {
    for (const bar of payload)
      if (bar.dataKey === isHover) {
        const { rendererName, totalSalary, salary } = bar.payload

        return (
          <TooltipBody>
            <Text size={12}>{`${rendererName}: ${bar.value}`}</Text>
            {totalSalary ? (
              <Text size={12}>{`Total Salary (MYR): ${salary[bar.dataKey]?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                }
              )}`}</Text>
            ) : null}
          </TooltipBody>
        )
      }
    return null
  } else {
    return (
      <TooltipBody>
        <Text size={12}>{`${name}: ${payload[0]?.payload?.payload?.percentage?.toFixed(1)}%`}</Text>
        <Text size={12}>{`Total Amount (MYR): ${value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`}</Text>
      </TooltipBody>
    )
  }
}
export default DOCustomTooltip
