import { useState } from 'react'

import { Button, Group, Stack, Text, Checkbox, Box } from '@mantine/core'

import { LEGAL_TEXT, LEGEAL_CHECKBOX_TEXT } from 'util/textMapping'

import CustomModalContainer from './CustomModalContainer'

export default function LegalModal({ isOpen, onClose, onSubmit }) {
  const [isChecked, setChecked] = useState(false)

  return (
    <CustomModalContainer
      title="Submit Declaration"
      opened={isOpen}
      onClose={onClose}
      size="38rem"
      fw={500}
    >
      <Stack>
        <Box sx={{ backgroundColor: '#EDF1F8', padding: 20 }}>
          <Text
            size={14}
            sx={{ maxHeight: '20rem', whiteSpace: 'pre-line' }}
          >
            {LEGAL_TEXT}
          </Text>
        </Box>
        <Checkbox
          size="sm"
          aria-label="legalcheckbox2"
          label={LEGEAL_CHECKBOX_TEXT}
          styles={{ label: { paddingLeft: 0 }, labelWrapper: { marginLeft: 10 } }}
          onChange={(event) => setChecked(event.currentTarget.checked)}
        />
      </Stack>

      <Group
        py={10}
        position="right"
      >
        <Button
          variant="default"
          onClick={onClose}
          aria-label="cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          aria-label="submit"
          disabled={!isChecked}
        >
          Submit
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
