import {
  useGetMDOtherMovableQuery,
  useCreateMDOtherMovableMutation,
  useUpdateMDOtherMovableMutation,
} from 'store/api'

import { FMOTHERMOVEABLE_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMOtherMoveablePanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDOtherMovableQuery({
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  })

  const [addOtherMoveable] = useCreateMDOtherMovableMutation()
  const [updateOtherMoveable] = useUpdateMDOtherMovableMutation()

  return (
    <FMPanelTemplate
      addButtonLabel={`Add other movable asset`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMOTHERMOVEABLE_MAP}
      apiAddHandler={addOtherMoveable}
      apiUpdateHandler={updateOtherMoveable}
    />
  )
}

export default FMOtherMoveablePanel
