import { useGetMDBankQuery, useCreateMDBankMutation, useUpdateMDBankMutation } from 'store/api'

import { FMBANK_MAP } from 'util/adminMapping'

import FMPanelTemplate from './FMPanelTemplate'

const FMBankPanel = () => {
  const { data, isSuccess, isLoading, isFetching } = useGetMDBankQuery({
    body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
  })

  const [addBankName] = useCreateMDBankMutation()
  const [updateBankName] = useUpdateMDBankMutation()

  return (
    <FMPanelTemplate
      addButtonLabel={`Add Institution Name`}
      isLoading={isLoading}
      isFetching={isFetching}
      isSuccess={isSuccess}
      data={data}
      tableConfig={FMBANK_MAP}
      apiAddHandler={addBankName}
      apiUpdateHandler={updateBankName}
    />
  )
}

export default FMBankPanel
