import { useGetMyPetronasNewsQuery } from 'store/api'

import { memo, Fragment } from 'react'

import { Carousel } from '@mantine/carousel'
import { Text, Stack, Image, Card, Skeleton, AspectRatio } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const NewsCard = memo(({ title, image, slug }) => {
  const NEWS_ENV = window.location.hostname !== 'myasset.petronas.com' ? 'staging.' : ''
  return (
    <Card
      sx={{ cursor: 'pointer' }}
      onClick={() =>
        window.open(
          `https://${NEWS_ENV}mypetronas.com/post/${slug}`,
          '_blank',
          'noopener noreferrer'
        )
      }
      withBorder
    >
      <Card.Section>
        <AspectRatio
          ratio={16 / 9}
          mx="auto"
        >
          <Image src={image} />
        </AspectRatio>
        <Text
          p={20}
          size={20}
          color="#343F4B"
        >
          {title}
        </Text>
      </Card.Section>
    </Card>
  )
})

const RenderNews = () => {
  const { data: myPetronasData, isSuccess, isLoading } = useGetMyPetronasNewsQuery()

  const breakpoint = useMediaQuery('(min-width: 768px)')

  if (isLoading) {
    return (
      <>
        <Skeleton
          height={100}
          mb={10}
        />
        <Skeleton height={30} />
      </>
    )
  }
  if (isSuccess) {
    //Take latest 3 of the array

    const latestThreeData = myPetronasData?.data
      .filter((e) => e.hashtags?.includes('Duty2Declare'))
      .slice(0, 3)

    if (breakpoint) {
      return (
        <Stack>
          {latestThreeData.map(({ postImageUrl, title, slug }) => {
            return (
              <Fragment key={title}>
                <NewsCard
                  title={title}
                  image={postImageUrl}
                  slug={slug}
                />
              </Fragment>
            )
          })}
        </Stack>
      )
    } else {
      return (
        <Carousel
          height="100%"
          align="start"
          slideSize="70%"
          slideGap={40}
        >
          {latestThreeData.map(({ postImageUrl, title, slug }) => {
            return (
              <Carousel.Slide
                size="100%"
                key={title}
              >
                <NewsCard
                  title={title}
                  image={postImageUrl}
                  slug={slug}
                />
              </Carousel.Slide>
            )
          })}
        </Carousel>
      )
    }
  }
}

export default RenderNews
