import { Fragment } from 'react'

import { Divider, Stack, Text, Anchor } from '@mantine/core'

import { ADTable, ADRows, TextWithInfo } from 'view/components'

import { OWNER_MAP, DIRECTOR_MAP } from 'util/tableMapping'

export const otherSourceForms = [
  {
    text: 'Business Ownership (outside of PETRONAS)',
    tooltip:
      'Employee that has legal and economic control over a business enterprise (outside of PETRONAS) as of date of declaration - either sole proprietorships, partnerships, corporations, and Limited Liability Partnership.',
    type: 'business',
    tableMapping: OWNER_MAP,
  },
  {
    text: 'Directorship (outside of PETRONAS)',
    tooltip: `Employee that holds a position and serves on the board of directors of a company outside PETRONAS as of date of declaration. `,
    type: 'company',
    tableMapping: DIRECTOR_MAP,
  },
]

const OtherSourcePanel = ({ form }) => {
  return (
    <Stack>
      <Divider />
      {otherSourceForms.map(({ text, tooltip, type, tableMapping }, index) => {
        return (
          <Fragment key={type}>
            <TextWithInfo
              text={`${index + 1}. ${text}`}
              tooltip={tooltip}
            />
            <Text
              italic
              size={14}
              weight={300}
              mt={-15}
            >
              {
                'Note: Employee shall ensure that approval or acknowledgement has been obtained from the Company through '
              }
              <Anchor
                href="https://png-prod-oic01-sdcduez8aeek-sy.integration.ocp.oraclecloud.com/ic/builder/rt/Forms/live/webApps/webapp/?page=shell&shell=ir02"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text
                  component="span"
                  color="#009089"
                  weight={600}
                >
                  Declaration of Activity (IR02) Form
                </Text>
              </Anchor>
            </Text>
            <ADTable
              form={form}
              tableConfig={tableMapping}
              tableData={form.values[tableMapping['tableName']]}
              addLabel={`Add ${type}`}
            >
              <ADRows />
            </ADTable>
          </Fragment>
        )
      })}
    </Stack>
  )
}

export default OtherSourcePanel
