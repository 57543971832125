import {
  AssetDeclaration,
  Home,
  AdminLandingPage,
  FAQPage,
  TrustAndSafety,
  PrivacyStatementPage,
  DeclarationOverview,
  ManageForm,
  EmployeeSearch,
  ESEmployeeDeclaration,
  ESEmployeeDeclarationView,
  InvestigationPage,
} from 'view/pages'
import DOAssetDeclaration from 'view/pages/DeclarationOverview/DOAssetDeclaration'
import DOReview from 'view/pages/DeclarationOverview/DOReview'

export const routes = [
  { path: '/assets', component: <AssetDeclaration />, role: 'USER', breadcrumb: 'New Asset' },
  {
    path: '/declaration-overview',
    component: <DeclarationOverview />,
    role: 'USER',
    breadcrumb: 'Declaration Overview',
  },
  {
    path: '/declaration-overview/review',
    component: <DOReview />,
    role: 'USER',
    breadcrumb: 'Declaration Overview Review',
  },
  {
    path: '/declaration-overview/revision',
    component: <DOAssetDeclaration />,
    role: 'USER',
    breadcrumb: 'Revision',
  },
  {
    path: '/faq',
    component: <FAQPage />,
    role: 'USER',
    breadcrumb: 'Frequently Asked Questions (FAQ)',
  },
  {
    path: '/trust-and-safety',
    component: <TrustAndSafety />,
    role: 'USER',
    breadcrumb: 'Trust & Safety',
  },
  {
    path: '/privacy-statement',
    component: <PrivacyStatementPage />,
    role: 'USER',
    breadcrumb: 'Privacy Statement',
  },
  // Admin route
  {
    path: '/admin/dashboard',
    component: <AdminLandingPage />,
    role: 'ADMIN',
    breadcrumb: 'Dashboard',
  },

  {
    path: '/admin/manage_form',
    component: <ManageForm />,
    role: 'ADMIN',
    breadcrumb: 'Manage Form',
  },
  {
    path: '/admin/employee_search',
    component: <EmployeeSearch />,
    role: 'ADMIN',
    breadcrumb: 'Employee Search',
  },
  {
    path: '/admin/employee_declarations',
    component: <ESEmployeeDeclaration />,
    role: 'ADMIN',
    breadcrumb: 'Employee Declaration',
  },
  {
    path: '/admin/employee_declarations/view',
    component: <ESEmployeeDeclarationView />,
    role: 'ADMIN',
    breadcrumb: 'View',
  },
  {
    path: '/investigate',
    component: <InvestigationPage />,
    role: 'DELEGATEDAUTHORITY',
    breadcrumb: null,
  },
]
export const pubRoutes = [
  { path: '/', isExact: false, component: <Home />, role: 'USER', breadcrumb: 'Home' },
]
export const excludedPath = [
  '/',
  '/unauthorized',
  '/assets',
  '/assets/',
  '/admin/dashboard',
  '/declaration-overview/review',
  '/declaration-overview/revision',
  '/assets/revision',
  '/assets/revision/',
  '/trust-and-safety',
]
