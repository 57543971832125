import { useMemo } from 'react'

import { Paper, Text, Stack } from '@mantine/core'

import { NoAccessComponent } from 'assets/svg'

import { useGetCurrentPath } from 'util/hooks'

const Unauthorized = ({ role }) => {
  const path = useGetCurrentPath()

  const renderText = useMemo(() => {
    if (path?.includes('investigate')) {
      return (
        <>
          <Text
            weight={700}
            size={20}
          >
            You do not have access to the link.
          </Text>
          <Text>
            The link is only accessible to persons with a valid
            <Text
              span
              inherit
              fs="italic"
            >
              {` Delegated Authority `}
            </Text>
            role and duration of delegation.
          </Text>
        </>
      )
    } else {
      return (
        <>
          <Text
            weight={700}
            size={20}
          >
            You do not have access to myAsset.
          </Text>
          <Text>
            myAsset is only accessible by
            <Text
              span
              inherit
              fs="italic"
            >
              {` Permanent `}
            </Text>
            and
            <Text
              span
              inherit
              fs="italic"
            >
              {` Contract Direct Hire (CDH) `}
            </Text>
            employees of PETRONAS common T&C companies and PETRONAS Digital Sdn Bhd (PDSB).
          </Text>
        </>
      )
    }
  }, [path])

  return (
    <Paper
      px={{ md: '20rem', sm: '5rem' }}
      py="5rem"
      mb="10rem"
    >
      <Stack
        align="center"
        justify="center"
        ta="center"
      >
        {role === 'USER' || role === 'DELEGATEDAUTHORITY' ? (
          <Stack>
            <NoAccessComponent />
            {renderText}
          </Stack>
        ) : (
          <Text
            color="dimmed"
            size="lg"
            align="center"
          >
            Unauthorized. Please request for {role} access
          </Text>
        )}
      </Stack>
    </Paper>
  )
}

export default Unauthorized
