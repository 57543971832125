import {
  useGetMDBankQuery,
  useGetMDAcquisitionQuery,
  useGetMDInvestmentQuery,
  useGetMDLoanInstitutionQuery,
  useGetMDLoanTypeQuery,
  useGetMDPropertyLocationQuery,
  useGetMDPropertyTypeQuery,
  useGetMDRetirementTypeQuery, // useGetMDVehicleBrandQuery,
  // useGetMDVehicleTypeQuery,
  useGetMDOtherMovableQuery,
  useGetVehicleTypeRelationQuery,
} from 'store/api'

import { useMemo, useState } from 'react'

import { formatDropdownBody } from 'util/helper'

const getPayload = {
  body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
}

const useGetDropdownData = () => {
  const [dropdownIsSuccess, setIsDropdownIsSuccess] = useState(false)
  const bankResponse = useGetMDBankQuery(getPayload)
  const acquisitionResponse = useGetMDAcquisitionQuery(getPayload)
  const investmentResponse = useGetMDInvestmentQuery(getPayload)
  const loanInstitutionResponse = useGetMDLoanInstitutionQuery(getPayload)
  const loanTypeResponse = useGetMDLoanTypeQuery(getPayload)
  const propertyLocResponse = useGetMDPropertyLocationQuery(getPayload)
  const propertyTypeResponse = useGetMDPropertyTypeQuery(getPayload)
  const retirementResponse = useGetMDRetirementTypeQuery(getPayload)
  // const vehicleBrandResponse = useGetMDVehicleBrandQuery(getPayload)
  const vehicleTypeResponse = useGetVehicleTypeRelationQuery(getPayload)
  const otherMovableResponse = useGetMDOtherMovableQuery(getPayload)

  const apiDropdownData = useMemo(() => {
    if (
      bankResponse.data &&
      investmentResponse.data &&
      loanInstitutionResponse.data &&
      loanTypeResponse.data &&
      propertyLocResponse.data &&
      propertyTypeResponse.data &&
      retirementResponse.data &&
      // vehicleBrandResponse.data &&
      vehicleTypeResponse.data &&
      acquisitionResponse.data &&
      otherMovableResponse.data
    ) {
      setIsDropdownIsSuccess(true)
      return formatDropdownBody({
        bankType: bankResponse,
        investmentType: investmentResponse,
        vehicleType: vehicleTypeResponse,
        vehicleBrand: vehicleTypeResponse,
        propertyLocation: propertyLocResponse,
        propertyType: propertyTypeResponse,
        loanInstitution: loanInstitutionResponse,
        loanType: loanTypeResponse,
        retirementType: retirementResponse,
        acquisitionType: acquisitionResponse,
        otherMovableType: otherMovableResponse,
      })
    }
  }, [
    bankResponse.data,
    investmentResponse.data,
    loanInstitutionResponse.data,
    loanTypeResponse.data,
    propertyLocResponse.data,
    propertyTypeResponse.data,
    retirementResponse.data,
    // vehicleBrandResponse.data,
    vehicleTypeResponse.data,
    acquisitionResponse.data,
    otherMovableResponse.data,
  ])

  return { apiDropdownData: dropdownIsSuccess ? apiDropdownData : [], dropdownIsSuccess }
}

export default useGetDropdownData
