// Import Mantine and theme config
import { EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { init as initApm, apm } from '@elastic/apm-rum'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { NotificationsProvider } from '@mantine/notifications'

import { storeCurrentPath } from 'util/localRedirectUrlStorage'

// MSAL imports
import { msalInstance } from '../src/config/authConfig'
import App from './App'
import './config/i18n'
import { theme } from './config/themeConfig'
import './index.css'
import store from './store/store'

const apmEnvironmentPath = {
  myasset: 'Production',
  localhost: 'localhost',
  stag: 'Staging',
  dev: 'Development',
}

initApm({
  serviceName: 'myAsset',
  serverUrl: 'https://prd.apm.petronas.com:443',
  environment: apmEnvironmentPath[window.location.hostname.split('.')[0]],
  serviceVersion: '1.2.1',
  distributedTracingOrigins: process.env.REACT_APP_API_ENDPOINT,
  propagateTracestate: true,
})

storeCurrentPath()

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
      sessionStorage.setItem(`apiAccessToken`, event.payload.accessToken)
      sessionStorage.setItem(`apiIdToken`, event.payload.idToken)
      sessionStorage.setItem('triggeredLoginRecently', 'no')
      apm.setUserContext({
        email: event.payload.idTokenClaims.email,
        username: event.payload.idTokenClaims.name,
      })
    } else if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload.account) {
      sessionStorage.setItem(`apiAccessToken`, event.payload.accessToken)
      sessionStorage.setItem(`apiIdToken`, event.payload.idToken)
      sessionStorage.setItem('triggeredLoginRecently', 'no')
      apm.setUserContext({
        email: event.payload.idTokenClaims.email,
        username: event.payload.idTokenClaims.name,
      })
    }
  })

  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{ ...theme }}
        >
          <ModalsProvider>
            <NotificationsProvider
              position="top-right"
              zIndex={2077}
            >
              <Provider store={store}>
                <App pca={msalInstance} />
              </Provider>
            </NotificationsProvider>
          </ModalsProvider>
        </MantineProvider>
      </MsalProvider>
    </React.StrictMode>
  )
})
