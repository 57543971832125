import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { getMsalAccountEmail, getMsalAccountRole } from 'config/authConfig'
import { setStepperStep, setDOPreview } from 'store/assetDeclareSlice'

import { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Text, Header, Container, UnstyledButton, Group } from '@mantine/core'
import { useDidUpdate, useMediaQuery } from '@mantine/hooks'

import { useStyle } from 'view/style'

import { PetronasLogo } from 'assets/svg'

import { useGetCurrentPath } from 'util/hooks'

import DropdownMenu from './DropdownMenu'
import NavbarLinks from './NavbarLinks'

const NavBar = memo(() => {
  const isUnauthorized = useSelector((state) => state.assetDeclare.isUnauthorized)

  const isAuthenticated = useIsAuthenticated()
  const history = useHistory()
  const path = useGetCurrentPath()
  const { classes } = useStyle()
  const { instance } = useMsal()

  const dispatch = useDispatch()
  const breakpoint = useMediaQuery('(min-width: 768px)')

  const [adminButtons, setAdminButtons] = useState(false)

  useDidUpdate(() => {
    setAdminButtons(path.includes('admin'))
  }, [path])

  const isInvestigate = useMemo(() => path?.includes('investigate'), [path])

  const msalAccountRoles = useMemo(() => getMsalAccountRole(instance), [instance])
  const msalAccountEmail = useMemo(() => getMsalAccountEmail(instance), [instance])
  const handleAuth = useCallback(async () => {
    sessionStorage.clear()
    localStorage.clear()
    await instance.logoutRedirect()
  }, [instance])

  //Props for dropdownmenu
  const dropdownProps = {
    isAuthenticated,
    msalAccountRoles,
    msalAccountEmail,
    setAdminButtons,
    handleAuth,
    breakpoint,
    adminButtons,
  }

  const handleHomeClick = () => {
    if (!isInvestigate) {
      window.scrollTo(0, 0)
      setAdminButtons(false)
      dispatch(setStepperStep(0))
      dispatch(setDOPreview(null))
      history.push('/assets')
    }
  }

  return (
    <Header
      height={64}
      className={classes.header}
    >
      <Container
        size="xl"
        px="xs"
      >
        <div className={classes.headerInner}>
          <UnstyledButton onClick={handleHomeClick}>
            <Group>
              <PetronasLogo />
              <Text
                color="white"
                size="sm"
                weight={600}
              >
                myAsset
              </Text>
            </Group>
          </UnstyledButton>
          {!isInvestigate ? (
            <Group
              spacing={16}
              align="center"
            >
              {breakpoint && !isUnauthorized ? (
                <>
                  <NavbarLinks
                    renderAdmin={adminButtons}
                    breakpoint={breakpoint}
                    onClick={handleHomeClick}
                  />
                </>
              ) : null}
              <DropdownMenu {...dropdownProps} />
            </Group>
          ) : null}
        </div>
      </Container>
    </Header>
  )
})

export default NavBar
