import { useLazyGetDownloadRevisionQuery, useGetIdentityQuery } from 'store/api'

import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Stack, Group, Button, Checkbox } from '@mantine/core'

import { DOWNLOAD_NOTE } from 'util/textMapping'

export default function DOModal({ isOpen, onClose }) {
  const { data } = useGetIdentityQuery()
  const [trigger] = useLazyGetDownloadRevisionQuery()
  const [isChecked, setChecked] = useState(false)

  const categoryDetails = useSelector((state) => state.assetDeclare)
  const { id, revision, financialYear } = categoryDetails.tempPreview

  const handleDownload = async () => {
    await trigger({
      id,
      revision,
      financialYear: financialYear,
      fullName: data?.fullName.replace(/ /g, ''),
    })
    handleClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      centered
      opened={isOpen}
      onClose={handleClose}
      withCloseButton={false}
      size={600}
    >
      <Stack>
        <Checkbox
          size="sm"
          py={20}
          label={DOWNLOAD_NOTE}
          styles={{ label: { paddingLeft: 0 }, labelWrapper: { marginLeft: 10 } }}
          onChange={(event) => setChecked(event.currentTarget.checked)}
        />
        <Group position="right">
          <Button
            variant="default"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!isChecked}
            onClick={handleDownload}
            aria-label="downloadButton"
          >
            Download
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
