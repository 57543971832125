import { useGetVehicleTypeRelationQuery } from 'store/api'

import { multiSelectDataFormatter } from 'util/helper'

const getPayload = {
  body: { page: 0, size: 1000, sortColumn: 'name', sortOrder: 'asc' },
}

const useGetBrandsList = () => {
  const data = useGetVehicleTypeRelationQuery(getPayload)

  return multiSelectDataFormatter(data?.data?.content) || []
}

export default useGetBrandsList
