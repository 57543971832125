import { Button, Group, Stack, Table, Text, Divider, Box, ScrollArea } from '@mantine/core'

import { ExchangeRateBanner } from 'view/components'
import { useStyle } from 'view/style'

import {
  OTHER_SOURCE_OF_INCOME_OWNERSHIP,
  OTHER_SOURCE_OF_INCOME_DIRECTORSHIP,
  OTHER_SOURCE_OF_INCOME_NOTE,
} from 'util/textMapping'

import CustomModalContainer from './CustomModalContainer'

export default function LearnOtherIncomeModal({ isOpen, handleClose }) {
  const { classes } = useStyle()

  return (
    <CustomModalContainer
      title="Other Source of Income"
      opened={isOpen}
      onClose={handleClose}
      size={800}
      modalPadding={20}
    >
      <ScrollArea
        style={{ height: 660 }}
        offsetScrollbars
      >
        <ExchangeRateBanner />
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text
              size={14}
              fw={500}
            >
              Ownership
            </Text>
            <Stack spacing={0}>
              <Text size={14}>
                Business ownership refers to the legal and economic control that an employee has
                over a business enterprise (outside PETRONAS) , including the ability to make
                decisions regarding the company’s operations, finances, and direction. It includes
                sole proprietorships, partnerships, corporations, and Limited Liability Partnership.
              </Text>
            </Stack>
          </Stack>
          <Box className={classes.tableContainer}>
            <Table
              sx={{ th: { width: 100 } }}
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>Field</th>
                  <th>Definition</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                {OTHER_SOURCE_OF_INCOME_OWNERSHIP.map((text) => (
                  <tr key={text.contentTitle}>
                    <td>{text.contentTitle}</td>
                    <td>{text.children}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text
              size={14}
              fw={500}
            >
              Directorship
            </Text>
            <Stack spacing={0}>
              <Text size={14}>
                Business Directorship refers to the position held by an employee who serves on the
                board of directors of a company or organization outside PETRONAS.
              </Text>
            </Stack>
          </Stack>
          <Box className={classes.tableContainer}>
            <Table
              sx={{ th: { width: 100 } }}
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>Field</th>
                  <th>Definition</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                {OTHER_SOURCE_OF_INCOME_DIRECTORSHIP.map((text) => (
                  <tr key={text.contentTitle}>
                    <td>{text.contentTitle}</td>
                    <td>{text.children}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Text
          size={14}
          fs="italic"
        >
          Note:
          <br />
          {OTHER_SOURCE_OF_INCOME_NOTE.map((text) => (
            <Text
              key={text}
              size={14}
              italic
              style={{ maxWidth: '720px' }}
            >
              {text}
            </Text>
          ))}
        </Text>
      </ScrollArea>
      <Group
        pt={20}
        position="right"
      >
        <Button
          variant="default"
          onClick={handleClose}
          aria-label="closeLearnOther"
        >
          Close
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
