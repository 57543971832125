import { isNull } from 'lodash'

import { forwardRef, useState } from 'react'

import { Select, ScrollArea, Stack, UnstyledButton, Text } from '@mantine/core'
import { useDidUpdate, useInputState } from '@mantine/hooks'

import { useStyle } from 'view/style'

import { ChevronDown } from 'assets/svg'

const OtherDropdown = ({
  data = [],
  invalid,
  value,
  onChange,
  canCreate,
  disabled,
  placeholder = '',
  dropdownSource = '',
}) => {
  const checkValueandCreatable = canCreate && value && typeof value === 'string'
  const { classes } = useStyle()
  const createdValueList = checkValueandCreatable ? [...data, { value: value, label: value }] : data
  const [dropdownList, setDropdownList] = useState(createdValueList)
  const [dropdownValue, setDropdownValue] = useState(value)
  const [searchValue, setSearchValue] = useInputState('')
  const [dropdownPlaceholder, setDropdownPlaceholder] = useState(placeholder)
  const [createMode, setCreateMode] = useState(false)
  useDidUpdate(() => {
    const createdValueList = checkValueandCreatable
      ? [...data, { value: value, label: value }]
      : data
    setDropdownList(createdValueList)
    setDropdownValue(value)
  }, [value, data])

  const dropdownComponent = forwardRef(({ style, ...others }, ref) => {
    const enableSave = others.children.props.children.props.data.length <= 0 && searchValue !== ''
    const onItemSelect = others.children.props.children.props.onItemSelect
    return (
      <Stack
        style={{ width: '100%', overflowY: 'hidden', ...style }}
        {...others}
        ref={ref}
        spacing={0}
      >
        {/* create Button */}
        {canCreate && !createMode ? (
          <UnstyledButton
            onClick={() => setCreateMode(true)}
            p={12}
          >
            <Text
              size={14}
              color="primary"
              fw={600}
            >
              + Create New
            </Text>
          </UnstyledButton>
        ) : null}

        {/* save button */}
        {canCreate && createMode ? (
          <UnstyledButton
            onClick={(e) => {
              if (enableSave) {
                onItemSelect(searchValue)
                onChange(searchValue)
              } else e.preventDefault()
            }}
            p={12}
          >
            {enableSave ? (
              <Text
                size={14}
                color="primary"
                fw={600}
              >
                + Save
              </Text>
            ) : (
              <Text
                size={14}
                color="#999999"
                fw={400}
              >
                + Save
              </Text>
            )}
          </UnstyledButton>
        ) : null}

        {/* Scroll Area */}
        <ScrollArea viewportProps={{ tabIndex: -1 }}>{others.children}</ScrollArea>
      </Stack>
    )
  })

  return (
    <Select
      classNames={{
        itemsWrapper: classes.dropdownWrapper,
        rightSection: classes.dropdownRightSection,
        item: classes.dropdownitem,
      }}
      disabled={disabled}
      placeholder={canCreate && createMode ? 'Create new' : dropdownPlaceholder}
      data={dropdownList}
      value={dropdownValue}
      dropdownComponent={dropdownComponent}
      error={invalid}
      onChange={(e) => onChange(isNull(e) ? '' : e)}
      onDropdownOpen={() =>
        setDropdownPlaceholder(dropdownSource === 'api' ? 'Type here..' : placeholder)
      }
      onDropdownClose={() => {
        setDropdownPlaceholder(placeholder)
        setSearchValue('')
        setCreateMode(false)
      }}
      onKeyDown={() => setCreateMode(true)}
      onSearchChange={setSearchValue}
      allowDeselect
      searchable={dropdownSource === 'api'}
      withinPortal
      nothingFound="No results"
      rightSection={<ChevronDown />}
    />
  )
}

export default OtherDropdown
