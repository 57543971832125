import { CloseIcon } from 'assets/svg'

const { Modal, Group, Text, ActionIcon } = require('@mantine/core')

const CustomModalContainer = ({
  children,
  closeOnClickOutside = false,
  title,
  titleBottomPadding = 16,
  modalPadding,
  opened,
  onClose,
  size = 600,
  styles,
  hideCloseButton = false,
}) => {
  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size={size}
      padding={modalPadding}
      closeOnClickOutside={closeOnClickOutside}
      styles={styles}
    >
      <Group
        position="apart"
        pb={titleBottomPadding}
      >
        <Text
          fw={500}
          size={18}
        >
          {title}
        </Text>
        {hideCloseButton ? null : (
          <ActionIcon
            onClick={onClose}
            variant={'transparent'}
          >
            <CloseIcon />
          </ActionIcon>
        )}
      </Group>
      {children}
    </Modal>
  )
}

export default CustomModalContainer
