import { Flex, Text } from '@mantine/core'

const CustomBadge = ({
  label,
  bgColor = '#EDF1F8',
  fontColor = '#677C91',
  fontWeight = 500,
  badgeWidth = 74,
}) => {
  return (
    <Flex
      gap="5px"
      justify="center"
      align="center"
      sx={{ borderRadius: '4px', padding: '1px 8px', height: 24, width: badgeWidth }}
      bg={bgColor}
    >
      <Text
        size="sm"
        weight={fontWeight}
        color={fontColor}
      >
        {label}
      </Text>
    </Flex>
  )
}

export default CustomBadge
