import { Button, Group, Stack, Table, Text, Divider, Box, ScrollArea, List } from '@mantine/core'

import { ExchangeRateBanner } from 'view/components'
import { useStyle } from 'view/style'

import {
  MOVABLE_MODAL_TO_DECLARE,
  MOVABLE_MODAL_NOT_TO_DECLARE,
  MOVABLE_MODAL_OTHERS,
  MOVABLE_MODAL_INVESTMENT,
  OTHER_MOVABLE_MODAL_TO_DECLARE,
  OTHER_MOVABLE_MODAL_NOT_TO_DECLARE,
  ASSET_DECLARATION_TEXT,
  MOVABLE_MODAL_NOTE,
} from 'util/textMapping'

import CustomModalContainer from './CustomModalContainer'

export const AssetDeclarationText = () => {
  return (
    <Text>
      {ASSET_DECLARATION_TEXT[0]}
      <Text
        fw={700}
        component={'span'}
      >
        {ASSET_DECLARATION_TEXT[1]}
      </Text>
      {ASSET_DECLARATION_TEXT[2]}
    </Text>
  )
}

export default function LearnMoveableModal({ isOpen, handleClose }) {
  const { classes } = useStyle()

  return (
    <CustomModalContainer
      title="Movable Asset"
      opened={isOpen}
      onClose={handleClose}
      size={800}
      modalPadding={20}
    >
      <ScrollArea
        style={{ height: 660 }}
        offsetScrollbars
      >
        <ExchangeRateBanner />
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text
              size={14}
              fw={500}
            >
              Cash savings
            </Text>
            <Stack spacing={0}>
              <Text size={14}>
                Cash savings in all types of accounts, in financial institutions in Malaysia and
                abroad.
              </Text>
              <Text
                size={14}
                fs="italic"
              >
                E.g. savings, current, joint accounts, fixed deposits combined in each financial
                institution
              </Text>
              <Text size={14}>
                Savings abroad to be declared as
                <span style={{ size: '14px', fontWeight: 700 }}>
                  {' "Institution outside of Malaysia" '}
                </span>
                in the Name of Institution dropdown list. This category is used to declare
                accumulated cash deposits held in foreign countries.
              </Text>
            </Stack>
          </Stack>
          <Box className={classes.tableContainer}>
            <Table
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>Name of Institution</th>
                  <th>Type of Account</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td>E.g. CIMB</td>
                  <td>
                    <Stack spacing={12}>
                      <Text>Savings - MYR 10,000.00</Text>
                      <Text>Current - MYR 5,000.00</Text>
                    </Stack>
                  </td>
                  <td>MYR 15,000.00</td>
                </tr>
                <tr>
                  <td>E.g. Maybank</td>
                  <td>
                    <Stack spacing={12}>
                      <Text>Savings - MYR 5,000.00</Text>
                      <Text>Current - MYR 6,500.00</Text>
                      <Text>Joint - MYR 3,000.00</Text>
                    </Stack>
                  </td>
                  <td>MYR 14,500.00</td>
                </tr>
                <tr>
                  <td>E.g. Tabung Haji</td>
                  <td>
                    <Text>Savings - MYR 10,000.00</Text>
                  </td>
                  <td>MYR 10,000.00</td>
                </tr>
                <tr>
                  <td>Institution outside of Malaysia</td>
                  <td>
                    <Stack spacing={12}>
                      <Text>Savings - QAR10,000 (MYR12,128) (E.g. Doha Bank)</Text>
                      <Text>Current - USD10,000 (MYR44,148) (E.g. Sunflower Bank)</Text>
                    </Stack>
                  </td>
                  <td>MYR 56,276.00</td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Stack spacing={12}>
          <Stack spacing={4}>
            <Text
              size={14}
              fw={500}
            >
              Vehicle
            </Text>
            <Text size={14}>Vehicles purchased or received as gifts, inheritance or winnings.</Text>
          </Stack>
          <Box className={classes.tableContainer}>
            <Table
              sx={{ th: { width: 50 } }}
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>To Declare</th>
                  <th>Not to Declare</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td>
                    <Text>Motorised vehicles owned/partly owned by employee including:</Text>
                    <List
                      size={14}
                      withPadding
                    >
                      {MOVABLE_MODAL_TO_DECLARE.map((text) => (
                        <List.Item key={text}>
                          <Text size={14}>{text}</Text>
                        </List.Item>
                      ))}
                    </List>
                    <br />
                    <Text
                      size={14}
                      italic
                    >
                      {`Note: If employee owns a type of vehicle that is not included in the categories above, 
                      they may add them by clicking the 'Create New' button.`}
                    </Text>
                  </td>
                  <td>
                    <Text>Not To Declare</Text>
                    <List
                      size={14}
                      withPadding
                    >
                      <List.Item>Rent-to-own vehicle</List.Item>
                    </List>
                    <br />
                    <AssetDeclarationText />
                    <br />
                    <List
                      size={14}
                      withPadding
                    >
                      {MOVABLE_MODAL_NOT_TO_DECLARE.map((text) => (
                        <List.Item key={text}>
                          <Text size={14}>{text}</Text>
                        </List.Item>
                      ))}
                    </List>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Stack spacing={10}>
          <Text
            size={14}
            fw={500}
          >
            Investment in Shares, Bonds & Other Forms of Securities
          </Text>
          <Text size={14}>
            All types of securities used for investment purposes (including digital investments) to
            be declared as follow:
          </Text>
          <Box className={classes.tableContainer}>
            <Table
              sx={{ th: { width: 50 } }}
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>Type of Investment</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                {MOVABLE_MODAL_INVESTMENT.map((obj) => (
                  <tr key={obj.investmentType}>
                    <td>
                      <Text size={14}>{obj.investmentType}</Text>
                    </td>
                    <td>
                      <Text size={14}>{obj.remarks}</Text>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2}>
                    Note: Name of corporation refers to the company or trustee that holds the
                    capital/unit.
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Stack>
        <Divider my={19} />
        <Stack spacing={10}>
          <Text
            size={14}
            fw={500}
          >
            Other Movable Assets (each valued at MYR 15,000 and above)
          </Text>
          <Box className={classes.tableContainer}>
            <Table
              sx={{ th: { width: 50 } }}
              horizontalSpacing="sm"
              verticalSpacing="sm"
              withColumnBorders
            >
              <thead className={classes.tableHeader}>
                <tr>
                  <th>To Declare</th>
                  <th>Not to Declare</th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td>
                    <List
                      size={14}
                      withPadding
                    >
                      {OTHER_MOVABLE_MODAL_TO_DECLARE.map((text) => (
                        <List.Item key={text}>
                          <Text size={14}>{text}</Text>
                        </List.Item>
                      ))}
                    </List>
                  </td>
                  <td>
                    <List
                      size={14}
                      withPadding
                    >
                      {OTHER_MOVABLE_MODAL_NOT_TO_DECLARE.map((text) => (
                        <List.Item key={text}>
                          <Text size={14}>{text}</Text>
                        </List.Item>
                      ))}
                    </List>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
          <Text size={14}>
            Example of scenario:
            <br />
            <br />
            <span style={{ whiteSpace: 'pre-line' }}>
              <div dangerouslySetInnerHTML={{ __html: MOVABLE_MODAL_OTHERS }} />
            </span>
          </Text>
        </Stack>
        <Divider my={19} />
        <Stack>
          <Text
            size={14}
            italic
          >
            Note:
            <br />
            <List listStyleType="decimal">
              {MOVABLE_MODAL_NOTE.map((text) => (
                <List.Item key={text}>
                  <Text
                    size={14}
                    italic
                    style={{ maxWidth: '720px' }}
                  >
                    <span style={{ whiteSpace: 'pre-line' }}>
                      <div dangerouslySetInnerHTML={{ __html: text }} />
                    </span>
                    <br />
                  </Text>
                </List.Item>
              ))}
            </List>
          </Text>
        </Stack>
      </ScrollArea>
      <Group
        pt={20}
        position="right"
      >
        <Button
          variant="default"
          onClick={handleClose}
          aria-label="closeLearnMovable"
        >
          Close
        </Button>
      </Group>
    </CustomModalContainer>
  )
}
