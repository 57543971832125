import { Text } from '@mantine/core'

import { CustomAlertBanner } from 'view/components'

import dayjs from 'dayjs'

const DeclarationReminderBanner = ({
  bannerData,
  withCloseButton = false,
  withChevronRight = false,
  chevronRightText = '',
}) => {
  const { showBanner, closingDate } = bannerData
  const diffInMilliSeconds = new Date(closingDate).getTime() - new Date().getTime()
  const daysConverter = 1000 * 60 * 60 * 24
  const belowTwoWeeksRange = diffInMilliSeconds / daysConverter <= 15
  return (
    <>
      {showBanner && belowTwoWeeksRange ? (
        <CustomAlertBanner
          withChevronRight={withChevronRight}
          chevronRightText={chevronRightText}
          withCloseButton={withCloseButton}
          pathUrl="/assets"
          content={
            <Text>{`You have yet to submit your Asset Declaration for ${new Date().getFullYear()}. Please submit by ${dayjs(
              closingDate
            ).format('DD MMM YYYY')}`}</Text>
          }
        />
      ) : null}
    </>
  )
}
export default DeclarationReminderBanner
